/**
 * Created by Sergey Panpurin on 2/1/2017.
 */

// @ts-check
(function btSettingsServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    .constant('btAppFeatures', {
      Trading: 'trading',
      Referral: 'referral',
      InviteBanner: 'invite-banner',
    })
    .factory('btSettingsService', btSettingsService);

  btSettingsService.$inject = [
    '$rootScope',
    '$state',
    '$http',
    '$q',
    'btSettings',
    '$ionicPopup',
    'btErrorService',
    'btLocalSettingsService',
    'btToolsService',
    'btSymbolPrefix',
  ];

  /**
   * This factory helps to check app updates.
   *
   * @ngdoc service
   * @name btSettingsService
   * @memberOf ecapp
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ui.IStateService} $state
   * @param {angular.IHttpService} $http -
   * @param {angular.IQService} $q - promise interface
   * @param {object} btSettings
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {ecapp.IErrorService} btErrorService
   * @param {ecapp.ILocalSettingsService} btLocalSettingsService
   * @param {ecapp.IToolsService} btToolsService
   * @param {ecapp.ISymbolPrefix} btSymbolPrefix
   * @return {ecapp.ISettingsService}
   */
  function btSettingsService(
    $rootScope,
    $state,
    $http,
    $q,
    btSettings,
    $ionicPopup,
    btErrorService,
    btLocalSettingsService,
    btToolsService,
    btSymbolPrefix
  ) {
    console.log('Running btSettingsService');

    /* --- Force update section --- */
    // by default update is not needed
    var gIsUpdateNeeded = null;
    var gIsUpdateRecommended = null;
    var gRecommendedUpdateDelay = 12 * 60 * 60 * 1000;
    var gDomain = getDomain();

    var Domain = {
      SAMPLE: 'sample',
      BETTERTRADER: 'bettertrader',
      BIGBRAINBANK: 'bigbrainbank',
      LINKDATASERVICE: 'linkdataservice',
      OPTIMUSFUTURES: 'optimusfutures',
    };

    var gSettingsTypes = {
      mails: {
        all: ['morningMail', 'eveningMail', 'weekStartMail', 'weekEndMail'],
        daily: ['morningMail', 'eveningMail'],
        weekly: ['weekStartMail', 'weekEndMail'],
      },
      notifications: {
        all: [
          'beforeRelease',
          'afterRelease',
          'insights',
          'tradingInsights',
          'expectedTradingInsights',
          'euMarketOpen',
          'euMarketClose',
          'usMarketOpen',
          'usMarketClose',
          'marketSense',
          'marketWakeUp',
          'marketTradeIdeas',
          'newTradeIdeas',
        ],
        release: ['beforeRelease', 'afterRelease'],
        premium: ['insights', 'tradingInsights', 'expectedTradingInsights'],
        exchanges: ['euMarketOpen', 'euMarketClose', 'usMarketOpen', 'usMarketClose'],
        trading: ['marketSense', 'marketWakeUp'],
      },
    };

    var gAppCustomizations = {
      bettertrader: {
        'main-tabs': {
          'tab-risk-monitor': true,
          'tab-markets': true,
          // 'tab-freight': false,
          'tab-reviews': true,
          'tab-insights': false,
          'tab-trade-ideas': true,
          'tab-news': false,
          'tab-review-detail': false,
          'tab-barometer': true,
          'tab-news-stream': false,
        },
        'side-tabs': {
          'dashboard-charts': true,
          'dashboard-new-back-tester': true,
          'dashboard-trading-platform': true,
          'dashboard-trading-integration-finamark': false,
          'dashboard-trading-integration-ig': false,
          'dashboard-trading-integration-oanda': false,
          'dashboard-info-hub': true,
          'dashboard-back-tester': false,
          'dashboard-sentiment': false,
          'dashboard-correlation': false,
          'dashboard-news': false,
          'dashboard-twitter': true,
          'dashboard-forum': false,
          'dashboard-community': false,
          'dashboard-content-wall': false,
          'dashboard-pro-membership': true,
          'dashboard-reports': false,
          'dashboard-trade-view-reports': false,
        },
        features: {
          'white-label': false,
          prices: true,
          trading: true,
          referral: true,
          'dev-page': true,
          'social-login': true,
          'invite-banner': true,
        },
        assets: {
          'sign-up-text': 'Sign Up',
          'login-logo-src': 'images/logo/logo-and-tagline.png',
          'login-logo-alt': 'BetterTrader logo',
          'header-logo-src': 'images/logo/BetterTrader-logo-white.png',
          'header-logo-alt': 'BetterTrader Logo',
          'splash-logo-src': 'images/logo-splash.png',
          'splash-logo-alt': 'BetterTrader logo',
          'open-account-href': '',
          'register-account-href': '',
          'learn-more-href': '',
          'trading-integration-platform': '',
          'trading-integration-broker': '',
          'chart-symbol1': btSymbolPrefix.FOREX + ':SPX500_USD',
          'chart-symbol2': btSymbolPrefix.FOREX + ':WTICO_USD',
          'chart-symbol3': btSymbolPrefix.FOREX + ':EUR_USD',
          'chart-symbol4': btSymbolPrefix.FOREX + ':USD_JPY',
          // 'chart-timezone': 'Etc/UTC',
          'linkedin-pixel-default-src': 'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=660297&fmt=gif',
          'linkedin-pixel-optimus-funded-src':
            'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=670777&fmt=gif',
          'custom-splashscreen-src': 'images/logo-bt-black.gif',
          'voice-assistant-tutorial-src': 'images/voice-assistant-tutorial.png',
        },
      },
      bigbrainbank: {
        'main-tabs': {
          'tab-risk-monitor': true,
          'tab-markets': true,
          // 'tab-freight': false,
          'tab-reviews': true,
          'tab-insights': false,
          'tab-trade-ideas': true,
          'tab-news': false,
          'tab-review-detail': false,
          'tab-barometer': true,
          'tab-news-stream': false,
        },
        'side-tabs': {
          'dashboard-charts': true,
          'dashboard-new-back-tester': true,
          'dashboard-trading-platform': false,
          'dashboard-trading-integration-finamark': false,
          'dashboard-trading-integration-ig': false,
          'dashboard-trading-integration-oanda': false,
          'dashboard-info-hub': true,
          'dashboard-back-tester': false,
          'dashboard-sentiment': false,
          'dashboard-correlation': false,
          'dashboard-news': false,
          'dashboard-twitter': true,
          'dashboard-forum': false,
          'dashboard-community': false,
          'dashboard-content-wall': false,
          'dashboard-pro-membership': true,
          'dashboard-reports': false,
          'dashboard-trade-view-reports': false,
        },
        features: {
          'white-label': true,
          prices: true,
          trading: false,
          referral: true,
          'dev-page': false,
          'social-login': false,
          'invite-banner': true,
        },
        assets: {
          'sign-up-text': 'Get Access',
          'login-logo-src': 'images/logo/bigbrainbank/login.png',
          'login-logo-alt': 'BigBrainBank Logo',
          'header-logo-src': 'images/logo/bigbrainbank/header.png',
          'header-logo-alt': 'BigBrainBank Logo',
          'splash-logo-src': 'images/logo/bigbrainbank/splash.png',
          'splash-logo-alt': 'BigBrainBank Logo',
          'open-account-href': 'https://bigbrainbank.org',
          'register-account-href': 'https://bigbrainbank.org',
          'learn-more-href': 'https://bigbrainbank.org',
          'trading-integration-platform': 'bigbrainbank',
          'trading-integration-broker': 'bigbrainbank',
          'chart-symbol1': btSymbolPrefix.FOREX + ':SPX500_USD',
          'chart-symbol2': btSymbolPrefix.FOREX + ':WTICO_USD',
          'chart-symbol3': btSymbolPrefix.FOREX + ':XAU_USD',
          'chart-symbol4': btSymbolPrefix.FOREX + ':EUR_USD',
          // 'chart-timezone': 'Etc/UTC',
          'linkedin-pixel-default-src': 'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=660297&fmt=gif',
          'linkedin-pixel-optimus-funded-src':
            'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=670777&fmt=gif',
          'custom-splashscreen-src': 'images/logo/bigbrainbank/loader.gif',
          'voice-assistant-tutorial-src': 'images/logo/bigbrainbank/voice-assistant-tutorial.png',
        },
      },
      linkdataservice: {
        'main-tabs': {
          'tab-risk-monitor': false,
          'tab-markets': true,
          // 'tab-freight': true,
          'tab-reviews': true,
          'tab-insights': false,
          'tab-trade-ideas': false,
          'tab-news': false,
          'tab-review-detail': false,
          'tab-barometer': true,
          'tab-news-stream': true,
        },
        'side-tabs': {
          'dashboard-charts': true,
          'dashboard-new-back-tester': false,
          'dashboard-trading-platform': false,
          'dashboard-trading-integration-finamark': false,
          'dashboard-trading-integration-ig': false,
          'dashboard-trading-integration-oanda': false,
          'dashboard-info-hub': false,
          'dashboard-back-tester': false,
          'dashboard-sentiment': false,
          'dashboard-correlation': false,
          'dashboard-news': false,
          'dashboard-twitter': false,
          'dashboard-forum': false,
          'dashboard-community': false,
          'dashboard-content-wall': false,
          'dashboard-pro-membership': false,
          'dashboard-reports': true,
          'dashboard-trade-view-reports': true,
        },
        features: {
          'white-label': true,
          prices: true,
          trading: false,
          referral: false,
          'dev-page': false,
          'social-login': false,
          'invite-banner': false,
        },
        assets: {
          'sign-up-text': 'Sign Up',
          'login-logo-src': 'images/logo/linkdataservice/main-logo.svg',
          'login-logo-alt': 'LinkDataService logo',
          'header-logo-src': 'images/logo/linkdataservice/logo.png',
          'header-logo-alt': 'LinkDataService logo',
          'splash-logo-src': 'images/logo/linkdataservice/main-logo.svg',
          'splash-logo-alt': 'LinkDataService logo',
          'open-account-href': '',
          'register-account-href': '',
          'learn-more-href': '',
          'trading-integration-platform': '',
          'trading-integration-broker': '',
          'chart-symbol1': 'LDS:MEH:M1',
          'chart-symbol2': 'LDS:WTS:M1',
          'chart-symbol3': 'LDS:MIDSWT:M1',
          'chart-symbol4': 'LDS:LLS:M1',
          // 'chart-timezone': 'Etc/UTC',
          'linkedin-pixel-default-src': '',
          'linkedin-pixel-optimus-funded-src': '',
          'custom-splashscreen-src': 'images/logo/linkdataservice/loader.gif',
          'voice-assistant-tutorial-src': 'images/logo/linkdataservice/voice-assistant-tutorial.png',
        },
      },
      optimusfutures: {
        'main-tabs': {
          'tab-risk-monitor': true,
          'tab-markets': true,
          // 'tab-freight': false,
          'tab-reviews': true,
          'tab-insights': false,
          'tab-trade-ideas': true,
          'tab-news': false,
          'tab-review-detail': false,
          'tab-barometer': true,
          'tab-news-stream': false,
        },
        'side-tabs': {
          'dashboard-charts': true,
          'dashboard-new-back-tester': true,
          'dashboard-trading-platform': false,
          'dashboard-trading-integration-finamark': true,
          'dashboard-trading-integration-ig': true,
          'dashboard-trading-integration-oanda': false,
          'dashboard-info-hub': true,
          'dashboard-back-tester': false,
          'dashboard-sentiment': false,
          'dashboard-correlation': false,
          'dashboard-news': false,
          'dashboard-twitter': true,
          'dashboard-forum': true,
          'dashboard-community': false,
          'dashboard-content-wall': false,
          'dashboard-pro-membership': true,
          'dashboard-reports': false,
          'dashboard-trade-view-reports': false,
        },
        features: {
          'white-label': true,
          prices: true,
          trading: false,
          referral: false,
          'dev-page': false,
          'social-login': false,
          'invite-banner': true,
        },
        assets: {
          'sign-up-text': 'Get Access',
          'login-logo-src': 'images/logo/optimusfutures/header-logo-white.svg',
          'login-logo-alt': 'OptimusFutures logo',
          'header-logo-src': 'images/logo/optimusfutures/header-logo-white.svg',
          'header-logo-alt': 'OptimusFutures logo',
          'splash-logo-src': 'images/logo/optimusfutures/header-logo-white.svg',
          'splash-logo-alt': 'OptimusFutures logo',
          'open-account-href': 'https://optimusfutures.com/Futures-Commodities-Trading-Account.php',
          'register-account-href': 'https://www.optimusfutures.com/Optimus-News-Access.php',
          'learn-more-href': 'https://www.optimusfutures.com/Optimus-News.php',
          'trading-integration-platform': 'optimusfutures',
          'trading-integration-broker': 'optimusfutures',
          'chart-symbol1': btSymbolPrefix.FOREX + ':SPX500_USD',
          'chart-symbol2': btSymbolPrefix.FOREX + ':WTICO_USD',
          'chart-symbol3': btSymbolPrefix.FOREX + ':XAU_USD',
          'chart-symbol4': btSymbolPrefix.FOREX + ':EUR_USD',
          // 'chart-timezone': 'Etc/UTC',
          'linkedin-pixel-default-src': 'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=660297&fmt=gif',
          'linkedin-pixel-optimus-funded-src':
            'https://dc.ads.linkedin.com/collect/?pid=553193&conversionId=670777&fmt=gif',
          'custom-splashscreen-src': 'images/logo/optimusfutures/circle-logo-150x149.png',
          'voice-assistant-tutorial-src': 'images/voice-assistant-tutorial-optimus.png',
        },
      },
    };

    /**
     * @alias ecapp.btSettingsService#Banner
     * @type {{Invitation: string, Notification: string}}
     */
    var Banner = {
      Invitation: 'invitation',
      Notification: 'notifications',
    };

    var gAccessData = null;
    var gLocalSettings = btLocalSettingsService.getLocalSettings();

    loadAccessData();

    if (hasAccessData()) useAccessData();

    var gStockExchanges = [
      {
        id: 'NYSE',
        name: 'New York Stock Exchange',
      },
      {
        id: 'LSE',
        name: 'London Stock Exchange',
      },
      {
        id: 'JPX',
        name: 'Japan Exchange Group',
      },
      {
        id: 'SSE',
        name: 'Shanghai Stock Exchange',
      },
    ];

    var gGlobalBanner = {
      opened: false,
      config: {},
    };

    if (window.isDevMode) {
      gStockExchanges.push({
        id: 'TEST1',
        name: 'Test Exchange #1',
      });

      gStockExchanges.push({
        id: 'TEST2',
        name: 'Test Exchange #2',
      });
    }

    try {
      $rootScope.currentUser = JSON.parse(window.localStorage.getItem('currentUser'));
    } catch (error) {
      console.error(error);
    }

    return {
      Banner: Banner,
      domain: gDomain,
      version: window.mainVersion,
      platform: getPlatform(),
      validate: validateObject,
      hasNeededUpdate: hasNeededUpdate,
      manageNeededUpdate: manageNeededUpdate,
      hasRecommendedUpdate: hasRecommendedUpdate,
      manageRecommendedUpdate: manageRecommendedUpdate,
      compareServerVersion: compareServerVersion,
      accessData: gAccessData,
      hasAccessData: hasAccessData,
      loadAccessData: loadAccessData,
      saveAccessData: saveAccessData,
      useAccessData: useAccessData,
      // removeAccessData: removeAccessData,
      hasUserData: hasUserData,
      manageUserData: manageUserData,
      removeUserData: removeUserData,
      hasMainTab: hasMainTab,
      hasSideTab: hasSideTab,
      hasFeature: hasFeature,
      getAssets: getAssets,
      getDomain: getDomain,
      isBetterTrader: isBetterTrader,
      isBigBrainBank: isBigBrainBank,
      isLinkDataService: isLinkDataService,
      isOptimusFutures: isOptimusFutures,
      isSampleDomain: isSampleDomain,
      getHostName: getHostName,
      getAppName: getAppName,
      getCompanyName: getCompanyName,
      getStockExchanges: getStockExchanges,
      getLocalSettings: getLocalSettings,
      isMailSettings: isMailSettings,
      isDailyMail: isDailyMail,
      isWeeklyMail: isWeeklyMail,
      isNotificationSettings: isNotificationSettings,
      isPremiumNotification: isPremiumNotification,
      isTradingNotification: isTradingNotification,
      getGlobalBanner: getGlobalBanner,
      openGlobalBanner: openGlobalBanner,
      closeGlobalBanner: closeGlobalBanner,
      logout: logout,
      initializeClientId: initializeClientId,
      getClientInformation: getClientInformation,
      setReminder: setReminder,
      getReminder: getReminder,
      removeReminder: removeReminder,
    };

    /**
     * Validates object structure.
     *
     * @alias ecapp.btSettingsService#validate
     * @param {object} obj - object
     * @param {object} template - template
     * @return {object}
     */
    function validateObject(obj, template) {
      // console.log('Validating object');
      var result = {};
      Object.keys(template).map(function (key) {
        // console.log('Validating key', key);
        result[key] = validateValue((obj || {})[key], template[key]);
      });
      return result;
    }

    /**
     *
     * @param {*} value
     * @param {*} template
     * @return {any}
     */
    function validateValue(value, template) {
      var desc = parseTemplate(template);
      switch (desc.type) {
        case 'boolean':
          return typeof value === desc.type ? value : desc.default === 'true';
        case 'number':
          return typeof value === desc.type ? value : 0;
        case 'string':
          // console.log(' > ', desc.type, desc.default);
          return typeof value === desc.type ? value : desc.default;
        case 'object':
          return validateObject(value, template);
        default:
          return null;
      }
    }

    /**
     *
     * @param {*} definition
     * @return {any}
     */
    function parseTemplate(definition) {
      if (typeof definition === 'object') {
        return { type: 'object', default: {} };
      } else {
        var res = definition.split('|');
        return { type: res[0], default: res[1] };
      }
    }

    /**
     * @name ecapp.btSettingsService#platform
     * @type {string}
     */

    /**
     * This function returns platform identifier.
     *
     * @return {string} - platform identifier in format format-domain-version
     */
    function getPlatform() {
      return '1-' + gDomain + '-' + window.mainVersion;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isMailSettings(name) {
      return gSettingsTypes['mails']['all'].indexOf(name) !== -1;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isDailyMail(name) {
      return gSettingsTypes['mails']['daily'].indexOf(name) !== -1;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isWeeklyMail(name) {
      return gSettingsTypes['mails']['weekly'].indexOf(name) !== -1;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isNotificationSettings(name) {
      return gSettingsTypes['notifications']['all'].indexOf(name) !== -1;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isPremiumNotification(name) {
      return gSettingsTypes['notifications']['premium'].indexOf(name) !== -1;
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isTradingNotification(name) {
      return gSettingsTypes['notifications']['trading'].indexOf(name) !== -1;
    }

    /**
     *
     * @return {angular.IPromise<*>}
     */
    function checkVersion() {
      // check for update on server
      return $http
        .get(window.btSettings.BT_LOOPBACK_URL + '/update', { params: { version: window.mainVersion } })
        .then(successCallback)
        .catch(btErrorService.handleHTTPError)
        .catch(errorCallback);
    }

    /**
     *
     * @param {number} v1
     * @param {number} v2
     * @param {number} v3
     * @return {number|null}
     */
    function compareServerVersion(v1, v2, v3) {
      if (window.btSettings.BT_SERVER_VERSION) {
        var data = window.btSettings.BT_SERVER_VERSION.split('.');
        var serverVersion = parseInt(data[0]) * 1000 * 1000 + parseInt(data[1]) * 1000 + parseInt(data[2]);
        var selectedVersion = v1 * 1000 * 1000 + v2 * 1000 + v3;
        if (selectedVersion > serverVersion) {
          return 1;
        } else if (selectedVersion < serverVersion) {
          return -1;
        } else {
          return 0;
        }
      }
      return null;
    }

    /**
     *
     * @param {*} response
     */
    function successCallback(response) {
      console.log('btSettingsService: isUpdateNeeded', response);
      if (response && response.data) {
        if (response.data.needed) {
          gIsUpdateNeeded = response.data.needed !== undefined ? response.data.needed : false;
        } else {
          gIsUpdateNeeded = false;
        }

        if (response.data.recommended) {
          gIsUpdateRecommended = response.data.recommended && !gIsUpdateNeeded;
        } else {
          gIsUpdateRecommended = false;
        }

        if (response.data.versions && response.data.versions.last) {
          btSettings.BT_SERVER_VERSION = response.data.versions.last;
        }
      }
    }

    /**
     *
     * @param {*} error
     * @return {any}
     */
    function errorCallback(error) {
      console.error('btSettingsService:', error);
      gIsUpdateNeeded = false;
      gIsUpdateRecommended = false;
      return $q.reject(error);
    }

    /**
     * This function redirects user to update page if server requires update
     *
     * @param {Object} event - ionic state change event
     * @param {Object} next - next state
     */
    function manageNeededUpdate(event, next) {
      if (gIsUpdateNeeded && next.name !== 'ecapp.user.update') {
        event.preventDefault();

        console.log('btSettingsService: try to open ecapp.user.update');
        $state.go('ecapp.user.update');
      }
    }

    /**
     * Is update required?
     * @return {angular.IPromise<*>}
     */
    function hasNeededUpdate() {
      var deferred = $q.defer();
      if (gIsUpdateNeeded === null) {
        checkVersion()
          .then(function () {
            deferred.resolve(gIsUpdateNeeded);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
      } else {
        deferred.resolve(gIsUpdateNeeded);
      }
      return deferred.promise;
    }

    /**
     * This function check if update is recommended.
     *
     * @alias ecapp.btSettingsService#hasRecommendedUpdate
     * @return {angular.IPromise<*>}
     */
    function hasRecommendedUpdate() {
      var deferred = $q.defer();
      if (gIsUpdateRecommended === null) {
        checkVersion()
          .then(function () {
            deferred.resolve(gIsUpdateRecommended);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
      } else {
        deferred.resolve(gIsUpdateRecommended);
      }
      return deferred.promise;
    }

    /**
     * This function manages recommended update.
     *
     * @alias ecapp.btSettingsService#manageRecommendedUpdate
     */
    function manageRecommendedUpdate() {
      /**
       *
       * @param {*} buttonIndex
       */
      function onConfirm(buttonIndex) {
        void buttonIndex;
        if (window.isIOS) {
          window.open('https://itunes.apple.com/app/bettertrader/id1161235956', '_system');
        } else if (window.isAndroid) {
          window.open('https://play.google.com/store/apps/details?id=com.bettertrader.ecapp', '_system');
        } else {
          window.open('https://bettertrader.co', '_system');
        }
      }

      var wasAsked;
      try {
        wasAsked = window.localStorage.getItem('updateWasRecommended');
        wasAsked = new Date(wasAsked);
      } catch (e) {
        console.error(e);
        wasAsked = null;
      }
      if (
        gIsUpdateRecommended &&
        (wasAsked === null || Date.now() - new Date(wasAsked).getTime() > gRecommendedUpdateDelay)
      ) {
        if (window.navigator && navigator.notification && navigator.notification.confirm) {
          navigator.notification.confirm(
            "The new version is faster and more reliable than ever. Go to the App Store to get the update and find out what's new.", // message
            onConfirm, // callback to invoke with index of button pressed
            'Update Available', // title
            ['Open App Store', 'Cancel'] // buttonLabels
          );
          window.localStorage.setItem('updateWasRecommended', new Date().toISOString());
        } else {
          var confirmPopup = $ionicPopup.confirm({
            title: 'Update Available',
            okText: 'App Store',
            template:
              "The new version is faster and more reliable than ever. Go to the App Store to get the update and find out what's new.",
          });

          confirmPopup.then(function (res) {
            if (res) {
              // console.log(res);
              onConfirm();
            }
          });

          window.localStorage.setItem('updateWasRecommended', new Date().toISOString());
        }
      }
    }

    /* --- Access data section --- */

    /**
     * This function checks if access data is available.
     *
     * @return {boolean} - whether access data is available
     */
    function hasAccessData() {
      return gAccessData != null;
    }

    /**
     * This function loads access data.
     */
    function loadAccessData() {
      try {
        gAccessData = JSON.parse(window.localStorage.getItem('accessData'));
      } catch (error) {
        console.error(error);
      }
    }

    /**
     * This function saves access data in the local storage.
     *
     * @param {object} data - access data
     */
    function saveAccessData(data) {
      window.localStorage.setItem('accessData', JSON.stringify(data));
    }

    /**
     * This function updates btSettings using access data.
     */
    function useAccessData() {
      if (gAccessData) {
        // add access data to btSettings
        Object.keys(gAccessData).forEach(function (name) {
          btSettings[name] = gAccessData[name];
        });
      }
    }

    /**
     * This function removes access data.
     */
    function removeAccessData() {
      gAccessData = null;
      window.localStorage.removeItem('accessData');
      window.sessionStorage.removeItem('accessData');
    }

    /* --- User data section --- */

    /**
     *
     * @return {boolean}
     */
    function hasUserData() {
      return $rootScope.currentUser != null;
    }

    /**
     *
     * @param {*} event
     * @param {*} next
     */
    function manageUserData(event, next) {
      if (
        next.name === 'social-login' ||
        next.name === 'ecapp.user.social-login' ||
        next.name === 'ecapp.user.sign-up'
      ) {
        return;
      }

      if (!hasAccessData() && next.name !== 'ecapp.user.logout') {
        if (next.requireAuthentication) {
          event.preventDefault();
          console.log('btSettingsService: try to open ecapp.user.logout');
          $state.go('ecapp.user.logout');
        }
      } else if ($rootScope.currentUser == null) {
        if (next.requireAuthentication) {
          event.preventDefault();
          console.log('btSettingsService: try to open ecapp.user.login');
          $state.go('ecapp.user.login');
        }
      } else if (
        $rootScope.isTutorialPassed === false &&
        next.name !== 'ecapp.user.tutorial' &&
        next.name !== 'ecapp.user.error'
      ) {
        $state.go('ecapp.user.tutorial');
      }
    }

    /**
     *
     */
    function removeUserData() {
      window.localStorage.removeItem('currentUser');
      window.sessionStorage.removeItem('currentUser');
      $rootScope.currentUser = null;
    }

    /**
     *
     * @return {*}
     * @private
     */
    function _getCustomization() {
      return gAppCustomizations[gDomain];
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function hasMainTab(name) {
      var settings = _getCustomization();
      return !!settings['main-tabs'][name];
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function hasSideTab(name) {
      var settings = _getCustomization();
      return !!settings['side-tabs'][name];
    }

    /**
     *
     * @alias ecapp.btSettingsService#hasFeature
     * @param {string} name - name of feature
     * @return {boolean}
     */
    function hasFeature(name) {
      var settings = _getCustomization();
      return !!settings['features'][name];
    }

    /**
     *
     * @alias ecapp.btSettingsService#getAssets
     * @param {string} name
     * @return {string}
     */
    function getAssets(name) {
      var settings = _getCustomization();
      return settings['assets'][name];
    }

    /**
     * Returns domain name. If domain is undefined show sample version.
     *
     * @alias ecapp.btSettingsService#getDomain
     * @return {string}
     */
    function getDomain() {
      if (window.btSettings.BT_DOMAIN) {
        return window.btSettings.BT_DOMAIN;
      } else {
        console.error('Domain is undefined.');
        return Domain.SAMPLE;
      }
    }

    /**
     * Checks domain.
     *
     * @alias ecapp.btSettingsService#isBetterTrader
     * @return {boolean}
     */
    function isBetterTrader() {
      return getDomain() === Domain.BETTERTRADER;
    }

    /**
     * Checks domain.
     *
     * @alias ecapp.btSettingsService#isBigBrainBank
     * @return {boolean}
     */
    function isBigBrainBank() {
      return getDomain() === Domain.BIGBRAINBANK;
    }

    /**
     * Checks domain.
     *
     * @alias ecapp.btSettingsService#isLinkDataService
     * @return {boolean}
     */
    function isLinkDataService() {
      return getDomain() === Domain.LINKDATASERVICE;
    }

    /**
     * Checks domain.
     *
     * @alias ecapp.btSettingsService#isOptimusFutures
     * @return {boolean}
     */
    function isOptimusFutures() {
      return getDomain() === Domain.OPTIMUSFUTURES;
    }

    /**
     * Checks domain.
     *
     * @alias ecapp.btSettingsService#isSampleDomain
     * @return {boolean}
     */
    function isSampleDomain() {
      return getDomain() === Domain.SAMPLE;
    }

    /**
     *
     * @return {any}
     */
    function getHostName() {
      switch (getDomain()) {
        case Domain.BETTERTRADER:
          return 'app.bettertrader.co';
        case Domain.BIGBRAINBANK:
          return 'thebrain.bigbrainbank.org';
        case Domain.LINKDATASERVICE:
          return 'portal.linkdataservices.com';
        case Domain.OPTIMUSFUTURES:
          return 'news.optimusfutures.com';
        default:
          return 'app.bettertrader.co';
      }
    }

    /**
     * Returns application name according to domain.
     *
     * @alias ecapp.btSettingsService#getAppName
     * @return {string} - app name
     */
    function getAppName() {
      switch (getDomain()) {
        case Domain.BETTERTRADER:
          return 'BetterTrader';
        case Domain.BIGBRAINBANK:
          return 'TheBrain';
        case Domain.LINKDATASERVICE:
          return 'Portal';
        case Domain.OPTIMUSFUTURES:
          return 'BetterTrader';
        default:
          return 'AppName';
      }
    }

    /**
     * Returns company name according to domain.
     *
     * @alias ecapp.btSettingsService#getAppName
     * @return {string} - company name
     */
    function getCompanyName() {
      switch (getDomain()) {
        case Domain.BETTERTRADER:
          return 'BetterTrader';
        case Domain.BIGBRAINBANK:
          return 'BigBrainBank';
        case Domain.LINKDATASERVICE:
          return 'LinkDataService';
        case Domain.OPTIMUSFUTURES:
          return 'Optimus Futures';
        default:
          return 'AppName';
      }
    }

    /**
     * Returns local settings.
     *
     * @alias ecapp.btSettingsService#getLocalSettings
     * @return {ecapp.LocalSettings} - local settings instance
     */
    function getLocalSettings() {
      return gLocalSettings;
    }

    /**
     *
     */
    function logout() {
      removeUserData();
      removeAccessData();

      closeGlobalBanner();

      if (gDomain === 'optimusfutures') {
        window.localStorage.removeItem('btDashboardTab');

        gLocalSettings.delete('dashboard.tab');
      }
    }

    /**
     * This function returns list of stock exchanges.
     *
     * @return {Array.<{name: string, id: string}>}
     */
    function getStockExchanges() {
      return gStockExchanges;
    }

    /**
     * @alias ecapp.btSettingsService#getGlobalBanner
     * @return {object}
     */
    function getGlobalBanner() {
      return gGlobalBanner;
    }

    /**
     * @alias ecapp.btSettingsService#openGlobalBanner
     * @param {ecapp.IGlobalBannerConfig} config
     */
    function openGlobalBanner(config) {
      if (config) {
        gGlobalBanner.config = config;
        gGlobalBanner.opened = true;
      }
    }

    /**
     * @alias ecapp.btSettingsService#closeGlobalBanner
     */
    function closeGlobalBanner() {
      gGlobalBanner.opened = false;
    }

    /**
     * @typedef {object} IClientInformation
     * @property {string} id - client identifier
     * @property {string} launched - last time launched at
     * @property {string} agent - user agent
     */

    /**
     * This function initializes client identifier.
     *
     * @alias ecapp.btSettingsService#initializeClientId
     */
    function initializeClientId() {
      var clientId = gLocalSettings.get('client.id');
      if (clientId === undefined) gLocalSettings.set('client.id', btToolsService.generateUUID());
      gLocalSettings.set('client.launched', new Date());
      gLocalSettings.set('client.agent', navigator.userAgent || 'Unknown');
    }

    /**
     * This function return information about client.
     *
     * @alias ecapp.btSettingsService#getClientInformation
     * @return {IClientInformation}
     */
    function getClientInformation() {
      return gLocalSettings.get('client');
    }

    /**
     * This function disallows push notifications on this device.
     *
     * @alias ecapp.btSettingsService#setReminder
     * @param {string} name - name of reminder
     * @param {number} milliseconds - number of milliseconds till next reminder
     */
    function setReminder(name, milliseconds) {
      gLocalSettings.set(name, new Date(Date.now() + milliseconds));
    }

    /**
     * This function disallows push notifications on this device.
     * @alias ecapp.btSettingsService#removeReminder
     * @param {string} name - name of reminder
     */
    function removeReminder(name) {
      gLocalSettings.delete(name);
    }

    /**
     * This function disallows push notifications on this device.
     *
     * @alias ecapp.btSettingsService#getReminder
     * @param {string} name - name of reminder
     * @return {Date|undefined}
     */
    function getReminder(name) {
      try {
        var reminder = new Date(gLocalSettings.get(name));
        return isNaN(reminder.getTime()) ? undefined : reminder;
      } catch (e) {
        console.error(e);
      }

      return undefined;
    }
  }
})();
