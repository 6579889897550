/**
 * Created by xxx on xxx.
 */

//@ts-check
(function btInstrumentsClosure() {
  'use strict';

  // This file is called instruments instead of event-instruments because in the future it would be universal/independent
  angular
    .module('ecapp')
    .directive('btInstrument', btInstrument)
    .controller('btInstrumentController', btInstrumentController);

  btInstrument.$inject = ['$templateCache'];

  /**
   * This directive shows instrument card.
   *
   * Properties:
   *  - market
   *
   * @ngdoc directive
   * @name btInstrument
   * @memberOf ecapp
   * @param {angular.ITemplateCacheService} $templateCache
   * @return {angular.IDirective}
   */
  function btInstrument($templateCache) {
    return {
      restrict: 'E',
      scope: {
        market: '<',
        displayCandle: '<',
        displayDifference: '<',
        fullVersion: '<',
        banner: '<',
        // tabContainer: '<?'
      },
      template: $templateCache.get('directives/instruments/instrument.html'),
      controller: 'btInstrumentController',
    };
  }

  btInstrumentController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$timeout',
    'btTradingService',
    'btPriceService',
    'btSettingsService',
    'btToastrService',
    'btChartsService',
    'btShareScopeService',
    'LdsApiWrapper',
    'btLinkDataServiceApiService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {scopes.InstrumentCard.IScope} $scope
   * @param {angular.IQService} $q
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IPriceService} btPriceService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.IChartsService} btChartsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IGeneralLoopbackService} lbLdsApi
   * @param {ecapp.ILinkDataServiceApiService} btLinkDataServiceApiService
   */
  function btInstrumentController(
    $rootScope,
    $scope,
    $q,
    $timeout,
    btTradingService,
    btPriceService,
    btSettingsService,
    btToastrService,
    btChartsService,
    btShareScopeService,
    lbLdsApi,
    btLinkDataServiceApiService
  ) {
    var gLevelsSettings = {};

    $scope.options = {
      hasMarketSense: $scope.market.instrument.hasMarketSense,
      hasMarketWakeup: $scope.market.instrument.hasMarketWakeup,
      hasHistoricalChart: true,
      hasDesktopChart: true,
    };

    // $scope.isMobile = window.isMobile;
    $scope.showBrokerSymbol = false;
    $scope.isClosing = false;

    $scope.hasPrices = btSettingsService.hasFeature('prices') && $scope.market.instrument.hasPriceAccess();
    $scope.hasTrading = btSettingsService.hasFeature('trading');

    $scope.isLdsGrade = $scope.market.instrument.provider === 'lds';
    $scope.hasLdsPriceAccess = $scope.market.instrument.hasPriceAccess();
    $scope.hasLdsTradesAccess = $scope.market.instrument.hasTradesAccess();

    $scope.hasChart = $scope.hasPrices || $scope.isLdsGrade;

    $scope.forwards = {
      options: [
        { id: 'M1', name: 'Month 1' },
        { id: 'M2', name: 'Month 2' },
        { id: 'M3', name: 'Month 3' },
        { id: 'M4', name: 'Month 4' },
        { id: 'M5', name: 'Month 5' },
        { id: 'M6', name: 'Month 6' },
        { id: 'M7', name: 'Month 7' },
        { id: 'M8', name: 'Month 8' },
        { id: 'M9', name: 'Month 9' },
        { id: 'M10', name: 'Month 10' },
        { id: 'M11', name: 'Month 11' },
        { id: 'M12', name: 'Month 12' },
      ],
      selected: null,
    };

    $scope.forwards.selected =
      $scope.forwards.options.filter(function (value) {
        return value.id === $scope.market.instrument.month;
      })[0] || $scope.forwards.options[0];

    $scope.months = {
      options: [
        { id: '', name: 'Month' },
        { id: '01', name: 'Jan' },
        { id: '02', name: 'Feb' },
        { id: '03', name: 'Mar' },
        { id: '04', name: 'Apr' },
        { id: '05', name: 'May' },
        { id: '06', name: 'Jun' },
        { id: '07', name: 'Jul' },
        { id: '08', name: 'Aug' },
        { id: '09', name: 'Sep' },
        { id: '10', name: 'Oct' },
        { id: '11', name: 'Nov' },
        { id: '12', name: 'Dec' },
      ],
      selected: null,
    };
    $scope.months.selected = $scope.months.options[0];

    $scope.years = {
      options: [
        { id: '', name: 'Year' },
        { id: '2017', name: '2017' },
        { id: '2018', name: '2018' },
        { id: '2019', name: '2019' },
        { id: '2020', name: '2020' },
        { id: '2021', name: '2021' },
        { id: '2022', name: '2022' },
        { id: '2023', name: '2023' },
        { id: '2024', name: '2024' },
      ],
      selected: null,
    };
    $scope.years.selected = $scope.years.options[0];

    $scope.periods = [];

    $scope.selected = {
      month: null,
      year: null,
      period: null,
    };

    $scope.monthChartError = '';
    $scope.isMonthSelected = false;
    $scope.isMonthUpdated = false;
    $scope.isMonthChanged = false;
    $scope.isMonthChartLoaded = false;
    $scope.monthChartValues = {
      prices: [
        [1, [1, 1, 1, 1]],
        [2, [2, 2, 2, 2]],
        [3, [3, 3, 3, 3]],
      ],
    };
    $scope.monthLastPrice = 'N/A';

    $scope.periodsError = '';
    $scope.periodChartError = '';
    $scope.isPeriodsLoaded = false;
    $scope.isPeriodSelected = false;
    $scope.isPeriodChartLoaded = false;
    $scope.periodChartValues = {
      prices: [
        [1, [1, 1, 1, 1]],
        [2, [2, 2, 2, 2]],
        [3, [3, 3, 3, 3]],
      ],
    };

    $scope.closePosition = closePosition;
    $scope.cancelOrder = cancelOrder;
    $scope.showHistoricalChart = showHistoricalChart;
    $scope.showOrders = showOrders;
    $scope.showDesktopChart = showDesktopChart;

    $scope.getPeriodsLine1 = getPeriodsLine1;
    $scope.getPeriodsLine2 = getPeriodsLine2;
    $scope.getPeriodValues = getPeriodValues;

    $scope.onForwardOptionChange = onForwardOptionChange;
    $scope.onMonthOptionChange = onMonthOptionChange;
    $scope.onYearOptionChange = onYearOptionChange;
    $scope.onUpdateSpecificMonthChart = onUpdateSpecificMonthChart;
    $scope.onSelectPeriod = onSelectPeriod;
    $scope.onOpenPeriodChart = onOpenPeriodChart;
    $scope.onOpenMonthChart = onOpenMonthChart;
    $scope.onBannerClick = onBannerClick;

    $scope.showLdsTrades = showLdsTrades;
    $scope.requestLdsAccess = requestLdsAccess;

    /**
     * User clicks on banner button.
     */
    function onBannerClick() {
      window.open($scope.banner.url, '_system');
    }

    $scope.$watch('market.instrument.price.updated', onDataUpdate);

    $scope.$on($scope.market.instrument.brokerSymbol + ':order-executed', checkPosition);
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     *
     */
    function activate() {
      $scope.market.instrument.levelVoice = $scope.market.instrument.levelVoice || 'none';

      $scope.market.openOrders = false;
      if ($scope.market.openCharts === undefined) {
        $scope.market.openCharts = false;
      }

      btShareScopeService.wait().then(updateLevelsSettings);

      btTradingService
        .initialize()
        .then(btTradingService.connect)
        .then(function () {
          btTradingService.enablePriceUpdates($scope.market.instrument);
          $scope.showBrokerSymbol = showBrokerSymbol();
        });

      if ($scope.fullVersion && $scope.isLdsGrade) {
        loadPeriodData();
      }
    }

    /**
     *
     */
    function onDestroy() {
      btTradingService.disablePriceUpdates($scope.market.instrument);
    }

    /**
     *
     * @param {Date} newValue - new value
     * @param {Date} oldValue - old value
     */
    function onDataUpdate(newValue, oldValue) {
      void oldValue;

      if (newValue) {
        // $scope.oldValue = oldValue ? oldValue.now.last : null;
        // if ($scope.market.data) $scope.market.priceData.oldValue = $scope.oldValue;
        prepareInstrumentPrice($scope.market.instrument.price);
      }
    }

    /**
     * This function updates levels settings.
     */
    function updateLevelsSettings() {
      gLevelsSettings = btShareScopeService.getUserSettings('levels', {});
      $scope.market.instrument.levelVoice = gLevelsSettings[$scope.market.instrument.OandaSymbol] || 'none';
    }

    /**
     *
     * @param {ecapp.ITradingPosition} position - position to close
     */
    function closePosition(position) {
      if (!$scope.isClosing && $scope.market.instrument.hasPositions) {
        $scope.isClosing = true;
        // var position = $scope.market.instrument.positionData;

        btTradingService
          .closePosition(position)
          .then(function () {
            checkPosition();
          })
          .finally(function () {
            $scope.isClosing = false;
          });
      }
    }

    /**
     *
     * @param {ecapp.ITradingOrder} order - order to close
     */
    function cancelOrder(order) {
      if (!$scope.isClosing && order) {
        $scope.isClosing = true;
        btTradingService
          .cancelOrder(order.key)
          .then(function () {
            checkPosition();
          })
          .catch(function (error) {
            btToastrService.error('Error: ' + (error.Message || error.message), 'Cancel Order', { type: 'trade' });
          })
          .finally(function () {
            $scope.isClosing = false;
          });
      }
    }

    /**
     *
     */
    function checkPosition() {
      var positions = btTradingService.hasPositions($scope.market.instrument.brokerSymbol);
      var orders = btTradingService.hasOrders($scope.market.instrument.brokerSymbol);

      if (positions.length > 0) {
        $scope.market.instrument.hasPositions = true;
        $scope.market.instrument.positionData = positions;
      } else {
        $scope.market.instrument.hasPositions = false;
        $scope.market.instrument.positionData = null;
      }

      if (orders !== null || orders.length === 0) {
        $scope.market.instrument.numOrders = orders.length;
        $scope.market.instrument.ordersData = orders;
      } else {
        $scope.market.instrument.numOrders = 0;
        $scope.market.instrument.ordersData = null;
      }
    }

    /**
     *
     * @param {ecapp.IComplexPrice} price - new price object
     */
    function prepareInstrumentPrice(price) {
      $scope.showBrokerSymbol = showBrokerSymbol();

      checkPosition();

      if (!!price && !!price.today && !!price.now) {
        $scope.lowOfToday = price.today.low.value;
        $scope.highOfToday = price.today.high.value;
        $scope.todayOpen = price.today.open.value;
        $scope.bidValue = price.now.bid.value;
        $scope.askValue = price.now.ask.value;
        $scope.priceValue = price.now.last.value;
        $scope.priceValue1 = Math.floor(price.now.last.value);
        $scope.priceConstant = price.now.last.constant;
        $scope.priceVariable = price.now.last.variable;
        $scope.priceMovement = price.now.last.movement;
        $scope.yesterdayClose = price.yesterday.close.value;

        $scope.priceDiff = $scope.priceValue - $scope.yesterdayClose;
        if ($scope.yesterdayClose === 0) {
          if ($scope.priceDiff === 0) {
            $scope.priceDiffPrcnt = 0;
          } else if ($scope.priceDiff > 0) {
            $scope.priceDiffPrcnt = +100;
          } else {
            $scope.priceDiffPrcnt = -100;
          }
        } else {
          $scope.priceDiffPrcnt = ($scope.priceDiff / Math.abs($scope.yesterdayClose)) * 100;
        }

        $scope.priceSignificance = btPriceService.calculateSignificance(
          $scope.priceDiffPrcnt,
          btPriceService.parseThresholds($scope.market.instrument)
        );

        $scope.decimalRound = Math.max(
          btPriceService.getPricePrecision($scope.yesterdayClose),
          btPriceService.getPricePrecision($scope.bidValue),
          btPriceService.getPricePrecision($scope.askValue)
        );

        if ($scope.market.instrument.precision === undefined) {
          $scope.market.instrument.precision = $scope.decimalRound;
        }

        $scope.priceStatus = $scope.priceDiff > 0 ? 'positive' : $scope.priceDiff < 0 ? 'negative' : 'neutral';

        if ($scope.market.priceData) {
          // FIXME Try to fix "Cannot set property 'priceStatus' of null"
          // Change `data: null` to `data: {}` in bt-watchlist-service.js

          $scope.market.priceData.priceStatus = $scope.priceStatus;
          $scope.market.priceData.priceValue = $scope.priceValue;
          $scope.market.priceData.priceValue1 = $scope.priceValue1;
          $scope.market.priceData.priceDiff = $scope.priceDiff;
          $scope.market.priceData.priceDiffPrcnt = $scope.priceDiffPrcnt;
          $scope.market.priceData.decimalRound = $scope.decimalRound;
        }

        if (price.today.open.value) {
          $scope.meterData = {
            low: $scope.lowOfToday,
            high: $scope.highOfToday,
            open: $scope.todayOpen,
            priceValue: $scope.priceValue,
            decimalRound: $scope.decimalRound,
          };
        }

        $scope.priceValue = price.now.last.value;
      }
    }

    /**
     *
     * @return {boolean}
     */
    function showBrokerSymbol() {
      return (
        btTradingService.isConnected() &&
        !btTradingService.isDefaultBroker() &&
        btTradingService.getBrokerName() !== 'tradestation'
      );
    }

    /**
     *
     */
    function showHistoricalChart() {
      if ($scope.isLdsGrade && !$scope.hasLdsPriceAccess) {
        $scope.requestLdsAccess('price');
      } else {
        $scope.market.openCharts = !$scope.market.openCharts;
      }
    }

    /**
     *
     */
    function showLdsTrades() {
      if (!$scope.hasLdsTradesAccess) {
        $scope.requestLdsAccess('trades');
      } else {
        if (btSettingsService.isLinkDataService()) {
          $rootScope.$broadcast('lds:trades:table:open', $scope.market.instrument.raw);
        } else {
          $rootScope.$broadcast('lds:trades:open', $scope.market.instrument.raw);
        }
      }
    }

    /**
     *
     */
    function showOrders() {
      $scope.market.openOrders = !$scope.market.openOrders;
    }

    /**
     *
     */
    function showDesktopChart() {
      if ($scope.isLdsGrade && !$scope.hasLdsPriceAccess) {
        $scope.requestLdsAccess('price');
      } else {
        btChartsService.openInstrumentChart($scope.market.instrument);
      }
    }

    /**
     *
     */
    function onForwardOptionChange() {
      $scope.market.instrument.month = $scope.forwards.selected.id;
      btTradingService.changeMonthOption($scope.market.instrument);
    }

    /**
     *
     */
    function onMonthOptionChange() {
      $scope.isMonthSelected = !!$scope.months.selected.id && !!$scope.years.selected.id;

      $scope.isMonthChanged =
        $scope.isMonthSelected &&
        ($scope.selected.month !== $scope.months.selected || $scope.selected.year !== $scope.years.selected);
    }

    /**
     *
     */
    function onYearOptionChange() {
      $scope.isMonthSelected = !!$scope.months.selected.id && !!$scope.years.selected.id;

      $scope.isMonthChanged =
        $scope.isMonthSelected &&
        ($scope.selected.month !== $scope.months.selected || $scope.selected.year !== $scope.years.selected);
    }

    /**
     *
     */
    function onUpdateSpecificMonthChart() {
      $scope.selected.month = $scope.months.selected;
      $scope.selected.year = $scope.years.selected;
      $scope.isMonthUpdated = true;
      $scope.isMonthChanged = false;

      if ($scope.selected.year.id && $scope.selected.month.id) {
        $scope.isMonthChartLoaded = false;
        $scope.monthChartError = '';

        loadMonthData(parseInt($scope.selected.year.id), parseInt($scope.selected.month.id) - 1)
          .then(function (results) {
            if (results.length) {
              $scope.isMonthChartLoaded = true;
              $scope.monthLastPrice = results[results.length - 1][1][0];
              $scope.monthChartValues.prices = results.slice(-21);
            } else {
              $scope.isMonthChartLoaded = false;
              $scope.monthChartValues.prices = [];
              $scope.monthChartError = 'No Data';
            }
          })
          .catch(function (reason) {
            console.error(reason);
            $scope.isMonthChartLoaded = false;
            $scope.monthChartError = reason.message;
          });
      }
    }

    /**
     *
     * @param {scopes.InstrumentCard.IPeriod} period
     */
    function onSelectPeriod(period) {
      $scope.periods.forEach(function (i) {
        i.selected = false;
      });

      period.selected = true;
      $scope.selected.period = period;
      $scope.isPeriodSelected = true;

      $scope.isPeriodChartLoaded = false;
      $scope.periodChartError = '';

      $scope.periodChartValues = {
        prices: $scope.terms[$scope.selected.period.name].map(function (i) {
          return [i.date.getTime(), [i.value, i.value, i.value, i.value]];
        }),
      };

      $timeout(function () {
        $scope.isPeriodChartLoaded = true;
      }, 200);
    }

    /**
     * Requests LDS access.
     *
     * @param {string} level - level
     */
    function requestLdsAccess(level) {
      btLinkDataServiceApiService.requestLdsAccess({
        id: Number($scope.market.instrument.raw.id),
        name: $scope.market.instrument.raw.name,
      }, level);
    }

    /**
     *
     */
    function loadPeriodData() {
      $scope.terms = {};

      var now = Date.now();
      var start = new Date(now - 21 * 24 * 3600 * 1000).toISOString().slice(0, 19);
      var end = new Date(now).toISOString().slice(0, 19);

      $scope.isPeriodsLoaded = false;

      lbLdsApi
        .getPeriodData({ grades: [$scope.market.instrument.raw.id], start: start, end: end })
        .$promise.then(function (results) {
          results[0].periodPrices.forEach(function (value) {
            if (!$scope.terms[value.term]) $scope.terms[value.term] = [];
            $scope.terms[value.term].push({ date: new Date(value.reportDate + '.000Z'), value: value.price });
          });

          Object.keys($scope.terms).forEach(function (key) {
            $scope.terms[key].sort(function (a, b) {
              return a.date.getTime() - b.date.getTime();
            });
          });

          $scope.periods = Object.keys($scope.terms).map(function (key) {
            var n = $scope.terms[key].length;
            return { name: key, value: $scope.terms[key][n - 1].value, selected: false };
          });

          $scope.isPeriodsLoaded = true;
        })
        .catch(function (reason) {
          console.error(reason);
          $scope.isPeriodsLoaded = false;
          $scope.periodsError = reason.message;
        });
    }

    /**
     *
     * @param {number} year - ?
     * @param {number} month - ?
     * @return {*}
     */
    function loadMonthData(year, month) {
      var start, end;
      var mark = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0)).toISOString().slice(0, 19);
      var date = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0));
      var now = new Date();

      if (date > now) {
        start = new Date(now.getTime() - 31 * 24 * 3600 * 1000).toISOString().slice(0, 19);
        end = now.toISOString().slice(0, 19);
      } else {
        start = new Date(date.getTime() - 31 * 24 * 3600 * 1000).toISOString().slice(0, 19);
        end = date.toISOString().slice(0, 19);
      }

      return lbLdsApi
        .getForwardData({ grades: [$scope.market.instrument.raw.id], start: start, end: end })
        .$promise.then(function (results) {
          var prices = [];
          results.forEach(function (result) {
            var time = new Date(result.reportDate).getTime();
            result.endOfDayPrices.forEach(function (value) {
              if (value.month === mark) {
                prices.push([time, [value.price, value.price, value.price, value.price]]);
              }
            });
          });
          return prices;
        });
    }

    /**
     *
     * @return {*}
     */
    function getPeriodValues() {
      if ($scope.selected.period) {
        return $scope.terms[$scope.selected.period.name].map(function (item) {
          return item.value;
        });
      } else {
        return [];
      }
    }

    /**
     *
     * @return {*}
     */
    function getPeriodsLine1() {
      return $scope.periods.filter(function (value) {
        return value.name.indexOf('Q') !== -1;
      });
    }

    /**
     *
     * @return {*}
     */
    function getPeriodsLine2() {
      return $scope.periods.filter(function (value) {
        return value.name.indexOf('H') !== -1 || value.name.indexOf('C') !== -1;
      });
    }

    /**
     *
     */
    function onOpenPeriodChart() {
      btChartsService.openSymbolChart(
        'LDS:' + $scope.market.instrument.brokerSymbol + ':' + $scope.selected.period.name.toUpperCase()
      );
    }

    /**
     *
     */
    function onOpenMonthChart() {
      btChartsService.openSymbolChart(
        'LDS:' + $scope.market.instrument.brokerSymbol + ':' + $scope.selected.year.id + '-' + $scope.selected.month.id
      );
    }
  }
})();
