/**
 * Created by Sergey Panpurin on 5/25/22.
 */

(function btAssessmentsWidgetClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btAssessmentsWidget:';

  angular
    .module('ecapp')
    /**
     * Description
     *
     * @ngdoc component
     * @name btAssessmentsWidget
     * @memberOf ecapp
     * @todo
     */
    .component('btAssessmentsWidget', {
      templateUrl: 'directives/barometer/bt-assessments-widget.html',
      controller: btAssessmentsWidgetController,
      controllerAs: 'vm',
    });

  btAssessmentsWidgetController.$inject = [
    '$rootScope',
    '$filter',
    'btLinkDataService',
    'btTradingService',
    'btSettingsService',
    'btLinkDataServiceApiService',
  ];

  /**
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IFilterService} $filter
   * @param {ecapp.ILinkDataService} btLinkDataService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.ILinkDataServiceApiService} btLinkDataServiceApiService
   */
  function btAssessmentsWidgetController(
    $rootScope,
    $filter,
    btLinkDataService,
    btTradingService,
    btSettingsService,
    btLinkDataServiceApiService
  ) {
    if (gDebug) console.log(gPrefix, 'Controller');

    /*jshint validthis: true*/
    var vm = this;

    vm.isLoading = true;
    vm.error = '';
    vm.groups = [];
    vm.created = 'N/A';
    vm.start = 'N/A';
    vm.end = 'N/A';
    vm.today = 'N/A';

    /**
     *
     */
    vm.$onInit = function () {
      if (gDebug) console.log(gPrefix, 'Initializing...');

      getDailyTable();
    };

    /**
     *
     */
    vm.$onDestroy = function () {
      if (gDebug) console.log(gPrefix, 'Destroying...');
    };

    vm.openTrades = function (symbol) {
      if (!symbol.hasPrice) return btLinkDataServiceApiService.requestAccess(symbol, 'price');
      if (!symbol.hasTrades) return btLinkDataServiceApiService.requestAccess(symbol, 'trades');

      if (btSettingsService.isLinkDataService()) {
        $rootScope.$broadcast('lds:trades:table:open', symbol);
      } else {
        $rootScope.$broadcast('lds:trades:open', symbol);
      }
    };

    /**
     * Returns daily table.
     */
    function getDailyTable() {
      vm.isLoading = true;
      vm.error = '';

      btLinkDataService
        .getDailyTable()
        .then(function (table) {
          vm.created = table.created;
          vm.start = table.start;
          vm.end = table.end;
          vm.today = table.today;
          vm.isLoading = false;

          table.data.forEach(function (group) {
            group.symbols.forEach(function (symbol) {
              const grade = btLinkDataService.getGradeById(symbol.id);
              const instrument = btTradingService.getInstrumentByBrokerSymbol(grade.name);
              symbol.instrument = instrument;
              symbol.hasPrice = instrument.hasPriceAccess();
              symbol.hasTrades = instrument.hasTradesAccess();
              symbol.displayName = instrument.displayName;

              if (!symbol.hasPrice) {
                symbol.settle = '-';
                symbol.previous = '-';
                symbol.change = '-';
                symbol.mtd = '-';
              } else {
                symbol.settle = $filter('number')(symbol.settle, 2);
                symbol.previous = $filter('number')(symbol.previous, 2);
                symbol.change = $filter('number')(symbol.change, 2);
                symbol.mtd = $filter('number')(symbol.mtd, 2);
              }
            });
          });

          vm.groups = table.data;
        })
        .catch(function (err) {
          console.error(err);
          vm.error = err.message || 'Unable to load transaction due to unknown problem';
          vm.isLoading = false;
        });
    }
  }
})();
