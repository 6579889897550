/**
 * Created by Dev Team on 6/24/2024.
 */

(function btEulaClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This directive controls eula.
     *
     * @ngdoc directive
     * @name btEula
     * @memberOf ecapp
     */
    .directive('btEula', btEula)
    .controller('btEulaController', btEulaController);

  btEula.$inject = [];

  /**
   *
   * @return {angular.IDirective}
   */
  function btEula() {
    return {
      restrict: 'E',
      scope: {},
      controller: 'btEulaController',
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  btEulaController.$inject = [
    '$rootScope',
    '$sce',
    '$templateCache',
    '$ionicModal',
    '$ionicLoading',
    'btSettingsService',
    'btShareScopeService',
    'btToastrService',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ISCEService} $sce
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IModalService} $ionicModal
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IToastrService} btToastrService
   */
  function btEulaController(
    $rootScope,
    $sce,
    $templateCache,
    $ionicModal,
    $ionicLoading,
    btSettingsService,
    btShareScopeService,
    btToastrService
  ) {
    var vm = this;

    const eulaScope = $rootScope.$new(true);
    eulaScope.agree = false;
    eulaScope.isForcefully = false;
    eulaScope.hide = hide;
    eulaScope.submit = submit;

    eulaScope.modal = $ionicModal.fromTemplate($templateCache.get('components/mobile/bt-eula/bt-eula-modal.html'), {
      scope: eulaScope,
      animation: 'slide-in-up',
    });

    vm.account = btShareScopeService.getAccountSettings();
    vm.userEula = null;
    vm.showEula = false;
    vm.eulaVersion = null;

    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    /**
     *
     */
    function onInit() {
      if (btSettingsService.isLinkDataService()) {
        btShareScopeService.getEula().then(function (eula) {
          vm.userEula = btShareScopeService.getAccountInfoField('eula');
          const alreadySigned = vm.userEula ? vm.userEula.map((e) => e.version) : [];
          const notSigned = eula.find((e) => !alreadySigned.includes(e.version));
          if (notSigned) {
            vm.showEula = true;
            vm.eulaVersion = notSigned;
            eulaScope.isForcefully = notSigned.isForcefully;
            eulaScope.file = $sce.trustAsResourceUrl(notSigned.file);
            eulaScope.modal.show();
          }
        });
      }
    }

    /**
     *
     */
    function onDestroy() {}

    /**
     * This function hides eula modal.
     */
    function hide() {
      eulaScope.modal.hide();
    }

    /**
     * This function for submit eula.
     */
    function submit() {
      $ionicLoading.show({ template: '<ion-spinner icon="ios"></ion-spinner><p>Updating EULA agreement...</p>' });
      const eula = vm.userEula || [];
      eula.push({
        signed: new Date(),
        version: vm.eulaVersion.version,
      });
      btShareScopeService
        .updateEula({ eula })
        .then(function () {
          btShareScopeService.setEula(eula);
          btToastrService.success('EULA agreement signed', 'EULA agreement');
          $ionicLoading.hide();
          eulaScope.modal.hide();
        })
        .catch(function (reason) {
          console.error('btEulaController: submit error', reason);
          btToastrService.error('Can not sign EULA agreement', 'EULA agreement');
          $ionicLoading.hide();
        });
    }
  }
})();
