(function btMasterControllerClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btMasterController:';

  angular.module('ecapp').controller('btMasterController', btMasterController);

  btMasterController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    '$window',
    '$location',
    '$timeout',
    '$analytics',
    'btWindowsSizeService',
    'appVersions',
    'btSettingsService',
    'btAuthService',
    'btToastrService',
    'btToolsService',
    'btMarketing',
    'btErrorService',
    'btLinkService',
  ];

  /**
   * Controller of body element. This controller will be loaded first.
   *
   * @ngdoc controller
   * @name ecapp.btMasterController
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ICustomScope} $scope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.ui.IStateService} $state
   * @param {angular.IWindowService} $window - window object service
   * @param {angular.ILocationService} $location
   * @param {angular.ITimeoutService} $timeout
   * @param {ext.IAnalyticsService} $analytics
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService
   * @param {ecapp.IAppVersions} appVersions
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IAuthService} btAuthService
   * @param {ecapp.IToastrService} btToastrService
   * @param {btUtils.btToolsService} btToolsService
   * @param {ecapp.IMarketing} btMarketing
   * @param {btUtils.btErrorService} btErrorService
   * @param {btUtils.btErrorService} btLinkService
   */
  function btMasterController(
    $rootScope,
    $scope,
    $q,
    $state,
    $window,
    $location,
    $timeout,
    $analytics,
    btWindowsSizeService,
    appVersions,
    btSettingsService,
    btAuthService,
    btToastrService,
    btToolsService,
    btMarketing,
    btErrorService,
    btLinkService
  ) {
    console.log('Running btMasterController');

    // noinspection JSUnresolvedFunction
    var onWindowResizeDebounced = ionic.debounce(onWindowResize, 300, false);

    $rootScope.isBetterTrader = btSettingsService.isBetterTrader();
    $rootScope.isBigBrainBank = btSettingsService.isBigBrainBank();
    $rootScope.isLinkDataService = btSettingsService.isLinkDataService();
    $rootScope.isOptimus = btSettingsService.isOptimusFutures();
    $rootScope.isOptimusFutures = btSettingsService.isOptimusFutures();
    $rootScope.isSampleDomain = btSettingsService.isSampleDomain();
    $rootScope.isTradeStation = false;

    $rootScope.isBTD = btSettingsService.isBetterTrader();
    $rootScope.isBBB = btSettingsService.isBigBrainBank();
    $rootScope.isLDS = btSettingsService.isLinkDataService();
    $rootScope.isOFD = btSettingsService.isOptimusFutures();
    $rootScope.isSMP = btSettingsService.isSampleDomain();

    // Whether subscription upgrade is supported in application.
    $rootScope.hasUpgrade = !(($rootScope.isBBB && $window.isApp) || $rootScope.isLDS);

    $rootScope.hasTradeIdeas = !$rootScope.isLDS;
    $rootScope.hasBacktesting = !$rootScope.isLDS;

    // BigBrainBank
    if ($rootScope.isBBB) {
      $rootScope.hostName = 'thebrain.bigbrainbank.org';
      $rootScope.hostUrl = 'https://thebrain.bigbrainbank.org';

      if (window.isApp) {
        document.title = 'TheBrain - AI Strategies';
      }

      if (window.isDevelopment) {
        document.title = '[Develop] TheBrain - AI Strategies';
      }

      // $rootScope.webinarDemoLink = 'https://zoom.us/webinar/register/WN_7aNzyonLQRqHqRnZCCTKcQ';
      // $rootScope.webinarDemoLink = 'https://zoom.us/webinar/register/WN_x8zlqrRGSUCZc_ldTy3XyA';
      // $rootScope.webinarDemoLink = 'https://zoom.us/webinar/register/WN_gyi26hMFS7C0b3Wf2kJSiQ';
      $rootScope.webinarDemoLink = 'https://zoom.us/webinar/register/WN_FpXiiZV5QlyGCQUOBZVSlg';

      $rootScope.webinarBackTesterLink = $rootScope.webinarDemoLink;

      $scope.floatButtonStyle = { display: 'block' };
    }

    // BetterTrader or OptimusFutures
    if ($rootScope.isBTD || $rootScope.isOFD) {
      if ($rootScope.isBTD) {
        $rootScope.hostName = 'app.bettertrader.co';
        $rootScope.hostUrl = 'https://app.bettertrader.co';
      } else {
        $rootScope.hostName = 'news.optimusfutures.com';
        $rootScope.hostUrl = 'https://news.optimusfutures.com';
      }

      if (window.isApp) {
        document.title = 'BetterTrader';
      }
      if (window.isDevelopment) {
        document.title = '[Develop] BetterTrader';
      }

      $rootScope.webinarDemoLink = 'https://app.livestorm.co/bettertrader-co/bettertrader-live-demo';
      $rootScope.webinarBackTesterLink = 'https://app.livestorm.co/bettertrader-co-1/bettertrader-backtester-demo';
      $scope.floatButtonStyle = { display: 'none' };
    }

    // LinkDataService
    if ($rootScope.isLDS) {
      $rootScope.hostName = 'portal.linkdataservices.com';
      $rootScope.hostUrl = 'https://portal.linkdataservices.com';

      if (window.isApp) {
        document.title = 'Portal Link Data Services';
      }
      if (window.isDevelopment) {
        document.title = '[Develop] Portal Link Data Services';
      }

      $rootScope.webinarDemoLink = '';
      $rootScope.webinarBackTesterLink = '';
      $scope.floatButtonStyle = { display: 'none' };
    }

    $scope.hasError = false;
    $scope.errorMessage = '';
    $scope.isAppLoading = true;
    $scope.isTestApp = $window.isTestApp;
    $scope.linkedinPixelDefault = btSettingsService.getAssets('linkedin-pixel-default-src');
    $scope.linkedinPixelOptimusFunded = btSettingsService.getAssets('linkedin-pixel-optimus-funded-src');
    $scope.linkedinPixelBigBrainBankFunded = btSettingsService.getAssets('linkedin-pixel-bigbrainbank-funded-src');
    $scope.customSplashscreen = btSettingsService.getAssets('custom-splashscreen-src');
    $scope.cssDomainClass = btSettingsService.getDomain();
    $scope.windowsSizeService = btWindowsSizeService;
    $scope.globalBanner = btSettingsService.getGlobalBanner();

    $scope.isTabHidden = isTabHidden;
    $scope.onTabSelected = onTabSelected;
    $scope.onTabDeselected = onTabDeselected;
    $scope.openRiskMonitor = openRiskMonitor;
    $scope.openMarkets = openMarkets;
    // $scope.openFreight = openFreight;
    $scope.openCalendar = openCalendar;
    $scope.openInsights = openInsights;
    $scope.openTradeIdeas = openTradeIdeas;
    $scope.openNews = openNews;
    $scope.openBarometer = openBarometer;
    $scope.openNewsStream = openNewsStream;

    $rootScope.openProMemberTab = openProMemberTab;
    $rootScope.openInfoHubTab = openInfoHubTab;

    ionic.on('resize', onWindowResizeDebounced, $window);
    $scope.$on('$destroy', onDestroy);

    activate();

    /**
     * This function actives controller.
     */
    function activate() {
      $window.localStorage.setItem('showFullMsg', 'true');

      if (!$window.isMobile && !$window.isMobileWeb && !$window.isTradeStation) {
        // if (!(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor))) {
        if (!$window.isChrome) {
          btToastrService.warning(
            'Open with Google Chrome for maximum performance<br/>' +
              '<a href="https://www.google.com/chrome/browser/desktop/index.html" target="_system">Download Chrome</a>',
            null,
            { closeButton: true, timeOut: 0, extendedTimeOut: 0, allowHtml: true, type: 'settings' }
          );
        }
      }

      btWindowsSizeService.setSize();

      // load user and access data from cache
      console.log('btMasterController: Try to find user in cache...');
      console.log('btMasterController: Current user:', $rootScope.currentUser);

      btSettingsService
        .hasRecommendedUpdate()
        .then(function (isUpdateRecommended) {
          if (isUpdateRecommended) btSettingsService.manageRecommendedUpdate();
          return launchApplication();
        })
        .catch(btErrorService.skipTransitionSuperseded)
        .catch(function (reason) {
          console.error(reason);
          $scope.isAppLoading = false;
          $scope.hasError = true;
          $scope.errorMessage = reason.message || 'Unknown issue.';
        });
    }

    /**
     * This function reacts on scope destroying.
     */
    function onDestroy() {
      ionic.off('resize', onWindowResizeDebounced, $window);
    }

    /**
     * This function is ionic.debounce function with checkSize inside.
     *
     * @ngdoc method
     * @name onWindowResize
     * @memberOf ecapp.btMasterController
     * @function
     */
    function onWindowResize() {
      // console.log('btMasterController:', 'window size was changed');

      $timeout(function () {
        window.isLarge = window.matchMedia('(min-width:760px)').matches;
        $rootScope.isLarge = window.isLarge;
      });
    }

    /**
     * This function launches application.
     *
     * @return {angular.IPromise}
     */
    function launchApplication() {
      btAuthService.startExternalLogin();

      if (btToolsService.isURLBroken()) {
        // URL is broken parameters have wrong position. Fix URL and restart application.
        btToolsService.fixBrokenURL();
        $scope.isAppLoading = true;
        // waiting for redirect
      } else if ($location.$$path === '/social-login') {
        btMarketing.visit();
        // User use social login.
        $scope.isAppLoading = false;
        return $q.resolve();
      } else if (!btSettingsService.hasUserData() || !btSettingsService.hasAccessData()) {
        btMarketing.visit();
        if ($state.current.name === 'ecapp.user.login' || $state.current.name === 'ecapp.user.sign-up') {
          $scope.isAppLoading = false;
          return $q.resolve();
        } else {
          // btAuthService.startExternalLogin();
          // User don't have saved information to use auto-login feature.
          console.log('btMasterController: Redirecting to login page due to current user is undefined.');
          console.log('btMasterController: Current user on $rootScope:', $rootScope.currentUser);
          console.log('btMasterController: Try to open ecapp.user.logout');
          $scope.isAppLoading = false;
          return $state.go('ecapp.user.logout');
        }
      } else {
        // btAuthService.startExternalLogin();
        // User have saved information. Try to use auto-login feature.
        console.log('btMasterController: Auto login.');
        console.log('btMasterController: Current User on $rootScope:', $rootScope.currentUser);

        // init service
        return btAuthService
          .autoLogin()
          .then(function (tutorialStatus) {
            // new user go to tutorial
            if (tutorialStatus === true) {
              $rootScope.isTutorialPassed = true;
              if ($state.current.name === '' && $window.location.hash.search('/app/') === -1) {
                console.log('btMasterController: try to open ecapp.app.main.reviews');
                if ($window.isMobile) {
                  return $state.go('ecapp.app.main.barometer');
                } else {
                  return $state.go('ecapp.app.main.reviews');
                }
              } else {
                // ???
                return null;
              }
            } else {
              $rootScope.isTutorialPassed = false;
              btWindowsSizeService.setSize();
              console.log('btMasterController: try to open ecapp.user.tutorial');
              return $state.go('ecapp.user.tutorial');
            }
          })
          .catch(function (reason) {
            btMarketing.visit();
            console.error('btMasterController:', reason);

            var params = {
              timeOut: 30000,
              closeButton: true,
              type: 'settings',
            };

            btToastrService.error(reason.message, 'Fatal Error', params);

            // 404 or 401
            if (isLoginError(reason)) {
              return $state.go('ecapp.user.logout');
            } else {
              return $q.reject(reason);
            }
          })
          .finally(function () {
            $scope.isAppLoading = false;
          });
      }
    }

    /**
     *
     * @param {*} reason
     * @return {Boolean}
     */
    function isLoginError(reason) {
      return (
        reason.status === 401 ||
        (reason.code === 'OPTIMUS_USER_EXPIRED' && reason.status === 403) ||
        (reason.code === 'BIGBRAINBANK_USER_EXPIRED' && reason.status === 403)
      );
    }

    /**
     *
     */
    function openRiskMonitor() {
      $timeout(function () {
        $state.go('ecapp.app.main.risk-monitor');
      }, 0);
    }

    /**
     *
     */
    function openMarkets() {
      $timeout(function () {
        $state.go('ecapp.app.main.markets');
      }, 0);
    }

    // /**
    //  *
    //  */
    // function openFreight() {
    //   $timeout(function () {
    //     $state.go('ecapp.app.main.freight');
    //   }, 0);
    // }

    /**
     *
     */
    function openCalendar() {
      $timeout(function () {
        $state.go('ecapp.app.main.reviews');
      }, 0);
    }

    /**
     *
     */
    function openInsights() {
      $timeout(function () {
        $state.go('ecapp.app.main.insights');
      }, 0);
    }

    /**
     *
     */
    function openTradeIdeas() {
      $timeout(function () {
        $state.go('ecapp.app.main.trade-ideas');
      }, 0);
    }

    /**
     *
     */
    function openNews() {
      $timeout(function () {
        $state.go('ecapp.app.main.twitter');
      }, 0);
    }

    /**
     *
     */
    function openBarometer() {
      $timeout(function () {
        $state.go('ecapp.app.main.barometer');
      }, 0);
    }

    /**
     *
     */
    function openNewsStream() {
      $timeout(function () {
        $state.go('ecapp.app.main.news-stream');
      }, 0);
    }

    /**
     *
     * @param {*} data
     */
    function onTabSelected(data) {
      if (gDebug) console.log(gPrefix, 'onTabSelected', data);
    }

    /**
     *
     * @param {*} data
     */
    function onTabDeselected(data) {
      if (gDebug) console.log(gPrefix, 'onTabDeselected', data);
    }

    /**
     *
     * @param {string} name
     * @return {boolean}
     */
    function isTabHidden(name) {
      return !btSettingsService.hasMainTab(name);
    }

    /**
     * This function opens pro member benefits page.
     */
    function openProMemberTab() {
      btLinkService.openInternal('ecapp.app.pro-membership');
    }

    /**
     * This function opens info hub page.
     */
    function openInfoHubTab() {
      btLinkService.openInternal('desktop.info-hub');
    }
  }
})();
