/**
 * Created by Sergey Panpurin on 3/18/2021.
 */

// @ts-check
(function btMarketApiServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btMarketApiService', btMarketApiService);

  btMarketApiService.$inject = [
    '$q',
    '$http',
    '$interval',
    'btSettings',
    'btInstrumentsService',
    'btTemplateApiService',
    '$rootScope',
    'MarketData',
    'UDFMarketData',
  ];

  /**
   *  This factory works with BetterTrader Market API.
   *
   * Authorizing user
   * Loading user watchlist
   * Resolving instruments
   * Subscribing to quotes
   * Unsubscribing from quotes
   * Loading historical candles
   * Subscribing to candles
   * Unsubscribing from candles
   *
   * @ngdoc service
   * @name btMarketApiService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.IGeneralLoopbackService} lbMarketData
   * @param {ecapp.IGeneralLoopbackService} UDFMarketData
   * @return {ecapp.IMarketApiService}
   */
  function btMarketApiService(
    $q,
    $http,
    $interval,
    btSettings,
    btInstrumentsService,
    btTemplateApiService,
    $rootScope,
    lbMarketData,
    UDFMarketData
  ) {
    console.log('Running btMarketApiService');

    var DEBUG = false;
    var NOT_IMPLEMENTED = 'Not implemented';
    var PREFIX = 'btMarketApiService:';

    /**
     * User access data
     * @type {ecapp.IBrokerAuthData}
     */
    var gUserData = {};

    /**
     * Is logged in
     * @type {boolean}
     */
    var gIsLoggedIn = false;

    var gLastPrices = {};

    /** @type {Record<string,string>} */
    var RESOLUTIONS = {
      M1: '1',
      M5: '5',
      M15: '15',
      H1: '60',
      D: '1D',
    };

    /**
     *
     * @param {*} symbol
     * @param {*} granularity
     * @param {*} period
     * @return {*}
     */
    function getCandles(symbol, granularity, period) {
      console.log(symbol, granularity, period);
      return $q.reject(new Error('Not Supported'));
    }

    /**
     * Get one instrument last prices. Send request to oanda every 5 second for realtime Values
     * Used in Markets tab, Event Related instruments
     * @param {string} instrument - instrument name known by oanda API
     * @return {angular.IPromise<object>}
     */
    function getLiveCandleData(instrument) {
      if (DEBUG) console.log(PREFIX, 'getLiveCandleData', instrument);

      if (instrument.indexOf(':') !== -1) {
        return UDFMarketData.quotes({ symbols: instrument })
          .$promise.then(function (res) {
            /** @type {ecapp.UDFQuotesResponse} */
            var a = res;
            if (a.s === 'ok') {
              return $q.resolve(convertUDFQuote(a.d[0]));
            } else {
              return $q.reject(new Error(a.errmsg));
            }
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
      } else {
        var params = {
          provider: 'OANDA',
          instrument: instrument,
          granularity: 'D',
          start: undefined,
          end: undefined,
          price: 'MAB',
          count: 2,
        };

        return lbMarketData
          .getInstrumentCandles(params)
          .$promise.then(function (data) {
            // reject if there is not enough data
            if (data === undefined || data.candles.length < 2) {
              return $q.reject(new Error("Bad response for getLiveCandleData! Can't found enough candles"));
            }

            var today = data.candles[data.candles.length - 1];
            var yesterday = data.candles[data.candles.length - 2];

            saveLastPrice(instrument, today.mid.c);

            var test = {
              symbol: instrument,
              time: today.time,
              yesterday: {
                close: parseFloat(yesterday.mid.c),
                closeText: yesterday.mid.c,
              },
              today: {
                low: parseFloat(today.bid.l),
                lowText: today.bid.l,
                high: parseFloat(today.ask.h),
                highText: today.ask.h,
                open: parseFloat(today.mid.o),
                openText: today.mid.o,
              },
              now: {
                bid: parseFloat(today.bid.c),
                bidText: today.bid.c,
                ask: parseFloat(today.ask.c),
                askText: today.ask.c,
                last: parseFloat(today.mid.c),
                lastText: today.mid.c,
              },
            };

            return $q.resolve(test);
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
      }
    }

    /**
     * @param {string[]} symbols - symbols
     * @return {angular.IPromise<ecapp.ITradingLiveCandle[]>}
     */
    function getLiveCandlesData(symbols) {
      /** @type {angular.IPromise<ecapp.ITradingLiveCandle[]>[]} */
      var promises = [];
      var udfSymbols = symbols
        .filter(function (symbol) {
          return symbol.indexOf(':') !== -1;
        })
        .join(',');

      if (udfSymbols) {
        // console.log('>>> UDF Symbols:', udfSymbols);

        var promise1 = UDFMarketData.quotes({ symbols: udfSymbols })
          .$promise.then(function (res) {
            /** @type {ecapp.UDFQuotesResponse} */
            var a = res;
            if (a.s === 'ok') {
              return $q.resolve(a.d.map(convertUDFQuote));
            } else {
              return $q.reject(new Error(a.errmsg));
            }
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
        promises.push(promise1);
      }

      var btSymbols = symbols
        .filter(function (symbol) {
          return symbol.indexOf(':') === -1;
        })
        .join(',');

      if (btSymbols) {
        // console.log('>>> OANDA Symbols:', btSymbols);

        var params = {
          provider: 'OANDA',
          instruments: btSymbols,
          granularity: 'D',
          price: 'MAB',
        };

        var promise2 = lbMarketData
          .getLatestCandles(params)
          .$promise.then(function (res) {
            if (!res || !res.instruments) {
              return $q.reject(new Error("Bad response for getLiveCandleData! Can't found enough candles"));
            }

            return $q.resolve(res.instruments.map(prepareCandles));
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });

        promises.push(promise2);
      }

      // console.log('>>> Symbols', symbols, btSymbols, udfSymbols, promises.length);

      if (promises.length) {
        return $q.all(promises).then(function (results) {
          return symbols.map(function (symbol) {
            var a;
            results.forEach(function (result) {
              result.forEach(function (item) {
                if (item.symbol === symbol) {
                  a = item;
                }
              });
            });
            return a;
          });
        });
      } else {
        return $q.reject(new Error('TEST'));
      }
    }

    /**
     *
     * @param {ecapp.UDFQuote} data
     * @return {ecapp.ITradingLiveCandle}
     */
    function convertUDFQuote(data) {
      // var today = data.candles[data.candles.length - 1];
      // var yesterday = data.candles[data.candles.length - 2];

      // saveLastPrice(data.instrument, today.mid.c);

      return {
        symbol: data.n,
        time: data.v.time,
        yesterday: {
          close: parseFloat(data.v.prev_close_price),
          closeText: data.v.prev_close_price,
        },
        today: {
          low: parseFloat(data.v.low_price),
          lowText: data.v.low_price,
          high: parseFloat(data.v.high_price),
          highText: data.v.high_price,
          open: parseFloat(data.v.open_price),
          openText: data.v.open_price,
        },
        now: {
          bid: parseFloat(data.v.bid),
          bidText: data.v.bid,
          ask: parseFloat(data.v.ask),
          askText: data.v.ask,
          last: parseFloat(data.v.lp),
          lastText: data.v.lp,
        },
      };
    }

    /**
     *
     * @param {*} data
     * @return {*}
     */
    function prepareCandles(data) {
      var today = data.candles[data.candles.length - 1];
      var yesterday = data.candles[data.candles.length - 2];

      saveLastPrice(data.instrument, today.mid.c);

      return {
        symbol: data.instrument,
        time: today.time,
        yesterday: {
          close: parseFloat(yesterday.mid.c),
          closeText: yesterday.mid.c,
        },
        today: {
          low: parseFloat(today.bid.l),
          lowText: today.bid.l,
          high: parseFloat(today.ask.h),
          highText: today.ask.h,
          open: parseFloat(today.mid.o),
          openText: today.mid.o,
        },
        now: {
          bid: parseFloat(today.bid.c),
          bidText: today.bid.c,
          ask: parseFloat(today.ask.c),
          askText: today.ask.c,
          last: parseFloat(today.mid.c),
          lastText: today.mid.c,
        },
      };
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Send request to oanda
     * @param {string} instrument - instrument name known by oanda API
     * @param {string} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<{instrument: string, granularity: string, candles: ecapp.ITradingLastCandle[]}>}
     */
    function getLastCandlesData(instrument, granularity, count) {
      if (DEBUG) console.log(PREFIX, 'getLastCandlesData', instrument, granularity, count);

      if (instrument.indexOf(':') !== -1) {
        /** @constant */
        var to = Math.floor(Date.now() / 1000);
        return getHistory(instrument, granularity, to, count)
          .then(function (res) {
            /** @type {ecapp.UDFHistoryResponse} */
            var a = res;
            if (a.s === 'ok') {
              const result = prepareHistoryResponse(a, instrument, granularity);
              return $q.resolve(result);
            } else {
              return $q.reject(new Error(a.errmsg || 'No Data'));
            }
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
      } else {
        var params = {
          provider: 'OANDA',
          instrument: instrument,
          granularity: granularity,
          start: undefined,
          end: undefined,
          price: 'M',
          count: count,
        };

        return lbMarketData
          .getInstrumentCandles(params)
          .$promise.then(function (response) {
            return $q.resolve(response);
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
      }
    }

    /**
     *
     * @param {string} instrument
     * @param {string} granularity
     * @param {number} to
     * @param {number} count
     * @return {angular.IPromise<ecapp.UDFHistoryResponse>}
     */
    function getHistory(instrument, granularity, to, count) {
      return UDFMarketData.history({
        symbol: instrument,
        resolution: RESOLUTIONS[granularity],
        to: to,
        countback: count,
      }).$promise.then(function (res) {
        /** @type {ecapp.UDFHistoryResponse} */
        var a = res;
        if (a.s === 'no_data' && a.nextTime) {
          return getHistory(instrument, granularity, a.nextTime, count);
        } else {
          return a;
        }
      });
    }

    /**
     *
     * @param {ecapp.UDFHistoryResponse} a
     * @param {string} instrument
     * @param {string} granularity
     * @return {{instrument: string, granularity: string, candles: ecapp.ITradingLastCandle[]}}
     */
    function prepareHistoryResponse(a, instrument, granularity) {
      return {
        instrument: instrument,
        granularity: granularity,
        candles: a.t.map(function (t, i) {
          return {
            complete: true,
            mid: {
              o: a.o[i],
              h: a.h[i],
              l: a.l[i],
              c: a.c[i],
            },
            time: t,
            volume: a.v[i],
          };
        }),
      };
    }
    /**
     * Get entry price for trade card
     * @param {string} instrument - instrument name known by oanda API
     * @param {number} time - timestamp in seconds
     * @param {number} minAfter - number of minutes after selected moment
     * @return {angular.IPromise<object>}
     */
    function getEntryPrice(instrument, time, minAfter) {
      if (DEBUG) console.log(PREFIX, 'getEntryPrice', instrument, time, minAfter);

      minAfter = minAfter || 2;

      var params = {
        provider: 'OANDA',
        instrument: instrument,
        granularity: 'M1',
        start: time,
        end: undefined,
        price: 'M',
        count: minAfter + 1,
      };

      return lbMarketData
        .getInstrumentCandles(params)
        .$promise.then(function (response) {
          return $q.resolve(response);
        })
        .catch(function (error) {
          return $q.reject(_getError(error));
        });
    }

    /* --- Public functions --- */

    /**
     * Initialize service
     * @param {object} data - access data
     * @return {angular.IPromise<object>}
     */
    function initialize(data) {
      if (DEBUG) console.log(PREFIX, 'initialize', data);

      if (data === undefined || data === null) {
        return $q.reject(new Error('Bad access data'));
      } else {
        gUserData = data;

        return $q.resolve({});
      }
    }

    /**
     * Connect Broker
     * @return {angular.IPromise<object>}
     */
    function connect() {
      if (DEBUG) console.log(PREFIX, 'connect');

      // ???
      if (gUserData.token !== undefined) {
        if (gIsLoggedIn) {
          return $q.resolve({});
        } else {
          gIsLoggedIn = true;
          return $q.resolve({});
        }
      } else {
        gIsLoggedIn = false;
        return $q.reject(new Error('Is not initialized'));
      }
    }

    /**
     * Disconnect broker
     * @return {angular.IPromise<object>}
     */
    function disconnect() {
      if (DEBUG) console.log(PREFIX, 'disconnect');
      return logout();
    }

    /**
     * Login Broker
     * @param {string} mode - trading mode: real ot demo
     * @param {boolean} isForceLogin - force login or not
     * @return {angular.IPromise<object>}
     */
    function login(mode, isForceLogin) {
      if (DEBUG) console.log(PREFIX, 'login', mode, isForceLogin);
      return $q.resolve({});
    }

    /**
     * Fast Login Broker
     * @param {string} mode - trading mode: real ot demo
     * @param {{sso_token: string}} data - access data
     * @return {angular.IPromise<object>}
     */
    function fastLogin(mode, data) {
      if (DEBUG) console.log(PREFIX, 'fastLogin', mode, data);

      gUserData = { token: data.sso_token, mode: mode, defaultAccount: '' };
      setTradingMode(mode);

      return $q.resolve(gUserData);
    }

    /**
     * Logout Broker
     *
     * @return {angular.IPromise<object>}
     */
    function logout() {
      if (DEBUG) console.log(PREFIX, 'logout');

      gUserData = {};
      gIsLoggedIn = false;

      return $q.resolve({});
    }

    /**
     * Check user data (now just username)
     *
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      if (DEBUG) console.log(PREFIX, 'checkUser', userData);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Create username from email
     * @param {string} email - user email as feed to create username
     * @return {angular.IPromise<*>}
     */
    function getUsername(email) {
      if (DEBUG) console.log(PREFIX, 'getUsername', email);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Create new OANDA account via API
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function signUp(userData) {
      if (DEBUG) console.log(PREFIX, 'signUp', userData);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Is user logged in to TradeStation API
     * @return {boolean}
     */
    function isLoggedIn() {
      return gUserData.token !== undefined;
    }

    /**
     * Get trading mode: live or practice
     * @return {string} - trading mode: "practice" or "trade"
     */
    function getTradingMode() {
      return 'demo';
    }

    /**
     * Set trading mode. Return true on success.
     *
     * @param {*} mode
     * @return {boolean} - trading mode was changed
     */
    function setTradingMode(mode) {
      if (DEBUG) console.log(PREFIX, 'setTradingMode', mode);
      return false;
    }

    /**
     * Get error from OANDA API response
     * @param {object} response - response object
     * @return {object|Error} - error object
     * @private
     */
    function _getError(response) {
      if (response.body && response.body.errorMessage) {
        return new Error(response.body.errorMessage);
      }

      if (response.data && response.data.errorMessage) {
        return new Error(response.data.errorMessage);
      }
      if (response.rawBody) {
        try {
          var body = JSON.parse(response.rawBody);
          return new Error(body.errorMessage);
        } catch (e) {
          return new Error('Unknown error code 0001.');
        }
      }

      return response;
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     *
     * @return {angular.IPromise<Array>} - list of accounts
     */
    function getAccounts() {
      if (DEBUG) console.log(PREFIX, 'getAccounts');
      return $q.resolve([]);
    }

    /**
     * Get balance for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getBalances', accountIds);
      return $q.resolve([]);
    }

    /**
     * Get all positions for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getPositions(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getPositions', accountIds);
      return $q.resolve([]);
    }

    /**
     * This function saves last price of the instrument to special cache.
     *
     * It helps to set position last price.
     *
     * @param {string} symbol - instrument symbol
     * @param {string} price - last price as a text
     */
    function saveLastPrice(symbol, price) {
      gLastPrices[symbol] = price;
    }

    /**
     * Get all orders for accounts
     *
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getOrders(accountIds) {
      if (DEBUG) console.log(PREFIX, 'getOrders', accountIds);
      return $q.resolve([]);
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {string} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      if (DEBUG) console.log(PREFIX, 'getSymbolInfo', symbol);

      if (!btInstrumentsService.isTicker(symbol)) {
        console.error(new Error('Not a ticker ' + symbol));
      }

      return UDFMarketData.symbols({ symbol: symbol }).$promise.then(function (res) {
        /** @type {ecapp.UDFSymbol | ecapp.UDFErrorResponse} */
        var a = res;
        if ('s' in a) {
          return $q.reject(new Error(a.errmsg));
        } else {
          if (DEBUG) console.log(PREFIX, 'getSymbolInfo', a);
          return btInstrumentsService.createFromUDFSymbol(a);
        }
      });
    }

    /**
     * Search symbol using query string
     *
     * @param {string} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      if (DEBUG) console.log(PREFIX, 'searchSymbol', query);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     *
     * @param {string} text
     * @return {string}
     */
    function parseType(text) {
      var types = {
        cfd: 'cfd',
        frx: 'forex',
        stk: 'stock',
        cpt: 'crypto',
      };
      return types[text] || 'unknown';
    }

    /**
     * Search symbol using query string
     *
     * @param {string} text - search text
     * @param {number} limit - limit number of results
     * @param {object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      void params;

      return UDFMarketData.search({ query: text, limit: limit }).$promise.then(function (res) {
        /** @type {ecapp.UDFSymbolSearch[] | ecapp.UDFErrorResponse} */
        var a = res;
        if ('s' in a) {
          return $q.reject(new Error(res.errmsg));
        } else {
          return a.map(function (item) {
            return {
              ticker: item.ticker,
              name: item.full_name,
              type: parseType(item.type),
              desc: item.description,
              rawData: item,
            };
          });
        }
      });

      // var query = {
      //   provider: 'OANDA',
      //   text: text,
      //   skip: 0,
      //   limit: limit
      // };

      // return lbMarketData.getInstruments(query).$promise
      //   .then(function (res) {
      //     return res.instruments.map(function (item) {
      //       return {
      //         name: item.name,
      //         desc: item.type + ' ' + item.displayName,
      //         rawData: item
      //       }
      //     });
      //   });
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {string[]} symbols -
     * @return {angular.IPromise<{ prices: ecapp.ITradingQuote[]; time: number }>}
     */
    function getQuotes(symbols) {
      /** @type {angular.IPromise<ecapp.ITradingQuote[]>[]} */
      var promises = [];
      var udfSymbols = symbols
        .filter(function (symbol) {
          return symbol.indexOf(':') !== -1;
        })
        .join(',');

      if (udfSymbols) {
        // console.log('>>> Quotes UDF Symbols:', udfSymbols);

        var promise1 = UDFMarketData.quotes({ symbols: udfSymbols })
          .$promise.then(function (res) {
            /** @type {ecapp.UDFQuotesResponse} */
            var a = res;
            if (a.s === 'ok') {
              return $q.resolve(
                a.d.map(function (item) {
                  return {
                    instrument: item.n,
                    time: item.v.time,
                    tradeable: true,
                    last: item.v.lp,
                    bids: [{ price: item.v.bid, liquidity: 1 }],
                    asks: [{ price: item.v.ask, liquidity: 1 }],
                  };
                })
              );
            } else {
              return $q.reject(new Error(a.errmsg));
            }
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });
        promises.push(promise1);
      }

      var btSymbols = symbols
        .filter(function (symbol) {
          return symbol.indexOf(':') === -1;
        })
        .join(',');

      if (btSymbols) {
        // console.log('>>> Quotes OANDA Symbols:', btSymbols);

        var params = {
          provider: 'OANDA',
          instruments: btSymbols,
        };

        var promise2 = lbMarketData
          .getQuotes(params)
          .$promise.then(function (response) {
            return $q.resolve(response.quotes);
          })
          .catch(function (error) {
            return $q.reject(_getError(error));
          });

        promises.push(promise2);
      }

      // console.log('>>> Quotes Symbols', symbols, btSymbols, udfSymbols, promises.length);

      if (promises.length) {
        return $q.all(promises).then(function (results) {
          var q = symbols.map(function (symbol) {
            var a;
            results.forEach(function (result) {
              result.forEach(function (item) {
                if (item.instrument === symbol) {
                  a = item;
                }
              });
            });
            return a;
          });

          return { prices: q, time: Math.floor(Date.now() / 1000) };
        });
      } else {
        return $q.reject(new Error('TEST'));
      }
      // return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      if (DEBUG) console.log(PREFIX, 'streamQuote', symbol, onProgress);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {string[]} symbols
     * @param {object} range
     * @param {number} interval -
     * @param {string} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      if (DEBUG) console.log(PREFIX, 'streamQuote', symbols, range, interval, unit);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      if (DEBUG) console.log(PREFIX, 'streamSnapshot', ticker, granularity, back, onUpdate);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      if (DEBUG) console.log(PREFIX, 'confirmOrder', order);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      if (DEBUG) console.log(PREFIX, 'submitOrder', order);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Update order
     *
     * @param {string} orderId - order id
     * @param {object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      if (DEBUG) console.log(PREFIX, 'updateOrder', orderId, orderChanges);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Cancel order
     *
     * @param {string} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      if (DEBUG) console.log(PREFIX, 'cancelOrder', orderId);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /**
     * Select account
     *
     * @param {string} id - account id
     * @return {?string} id of selected account or null
     */
    function selectAccount(id) {
      if (DEBUG) console.log(PREFIX, 'selectAccount', id);
      return null;
    }

    /**
     *
     * @return {*}
     */
    function getSelectedAccountId() {
      return null;
    }

    /**
     *
     * @param {*} id
     * @return {*}
     */
    function isAccountSelected(id) {
      if (DEBUG) console.log(PREFIX, 'isAccountSelected', id);
      return true;
    }

    /**
     *
     * @return {*}
     */
    function getAccessData() {
      if (DEBUG) console.log(PREFIX, 'getAccessData');
      return $q.resolve(gUserData);
    }

    return {
      getCandles: getCandles,
      // getAllInstruments: getAllInstruments,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,
      getLastCandlesData: getLastCandlesData,
      getEntryPrice: getEntryPrice,

      // trading api
      initialize: initialize,

      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getAccessData: getAccessData,
    };
  }
})();
