/**
 * Created by Sergey Panpurin on 04/01/18.
 */

(function btInstrumentPageControllerClosure() {
  'use strict';

  /** @constant */
  var TEXT =
    'We provide two types of Trade Ideas. News-Driven Trade Ideas that are triggered by economic events and Price-Driven Trade Ideas that are triggered by market movements.';

  angular.module('ecapp').controller('btInstrumentPageController', btInstrumentPageController);

  btInstrumentPageController.$inject = [
    '$q',
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    '$timeout',
    '$interval',
    'btShareScopeService',
    'btTradingService',
    'btEventsService',
    'btInstrumentsService',
    'btBackService',
    'btRequestService',
    'btRestrictionService',
    'btSettingsService',
    'btAdvertisingService',
  ];

  /**
   * This is a controller for instrument page.
   *
   * @ngdoc controller
   * @name ecapp.btInstrumentPageController
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomScope} $scope - ?
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {angular.ui.IStateService} $state - ?
   * @param {angular.ui.IStateService} $stateParams - ?
   * @param {angular.ITimeoutService} $timeout - ?
   * @param {angular.IIntervalService} $interval - ?
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ecapp.ITradingService} btTradingService - ?
   * @param {ecapp.IEventsService} btEventsService - ?
   * @param {ecapp.IInstrumentsService} btInstrumentsService - ?
   * @param {ecapp.IBackService} btBackService - ?
   * @param {ecapp.IRequestService} btRequestService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   * @param {ecapp.IAdvertisingService} btAdvertisingService - ?
   */
  function btInstrumentPageController(
    $q,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $interval,
    btShareScopeService,
    btTradingService,
    btEventsService,
    btInstrumentsService,
    btBackService,
    btRequestService,
    btRestrictionService,
    btSettingsService,
    btAdvertisingService
  ) {
    var gDebug = false;

    if (gDebug) console.log('Running btInstrumentPageController');

    /*jshint validthis: true*/
    var vm = this;

    var errors = {
      broker: {
        unsupported: function (broker) {
          return 'Broker "' + broker + '" is don\'t supported';
        },
        notSelected: function (requested, connected) {
          if (connected === 'default') {
            connected = 'default';
          }
          return 'You try to open symbol of "' + requested + '" broker, but "' + connected + '" broker is connected.';
        },
      },
      symbol: {
        unsupported: function (symbol) {
          return 'Symbol "' + symbol + '" is don\'t supported';
        },
      },
    };

    vm.isLoading = true;

    vm.hasError = false;
    vm.errorMessage = '';
    vm.brokerObject = {};
    vm.broker = '';
    vm.symbol = '';
    vm.complexSymbol = '';
    vm.hasComplexSymbol = false;
    vm.instrument = {
      openCharts: true,
      instrument: undefined,
      priceData: {},
      hasMarketData: false,
      loadingMarketData: true,
      indicators: [],
    };

    vm.hasDetails = true;
    vm.hasCharacteristics = true;
    vm.hasTradeIdeas = true;
    vm.hasCalendar = true;
    vm.hasCalendarFeature = btRestrictionService.hasFeature('instrument-calendar');
    vm.banner = undefined;
    vm.prevBanner = undefined;

    vm.isOpenAccountEnabled = false;
    vm.hasOpenAccountBanner = function () {
      const type = (vm.instrument.instrument || {}).type || '';
      return vm.isOpenAccountEnabled && (type === 'CRYPTO' || type === 'crypto');
    };
    vm.openAccountLink = '';
    vm.openAccountImage = '';
    vm.openAccountAlt = '';
    vm.openAccountTitle = '';
    vm.combinedBanner = null;

    /**
     * Description for Trade Ideas section.
     * @type {string}
     */
    vm.shortDescription = TEXT;

    vm.doRefresh = refresh;
    vm.showInstrumentCalendarUpgrade = showInstrumentCalendarUpgrade;

    vm.hasMarketCharacteristics = hasMarketCharacteristics;
    vm.unlockMarketCharacteristics = unlockMarketCharacteristics;

    vm.$onDestroy = onDestroy;

    var gIntervalPromise = null;

    activate();

    $scope.$watch(
      function () {
        return vm.hasOpenAccountBanner();
      },
      function (newValue) {
        if (newValue) {
          vm.prevBanner = vm.banner;
          vm.banner = {
            title: vm.openAccountTitle,
            url: vm.openAccountLink,
          };
        } else if (vm.banner && vm.banner.primary) {
          vm.banner = vm.prevBanner;
          vm.prevBanner = undefined;
        }
      }
    );

    $scope.$watch('vm.instrument.instrument.price.precision', function (newValue) {
      if (newValue !== undefined && !vm.instrument.hasMarketData) {
        vm.instrument.hasMarketData = true;
        vm.instrument.loadingMarketData = false;
      }
    });

    $scope.$on('$ionicView.loaded', onViewLoaded);
    $scope.$on('$ionicView.enter', onViewEnter);
    $scope.$on('subscription:updated', onSubscriptionUpgradeSuccess);

    var currentState = 'ecapp.app.main.instrument-page';
    var parentState = 'ecapp.app.main.markets';
    var backStates = [
      'ecapp.app.main.detail',
      'ecapp.app.main.insights',
      'ecapp.app.main.trade-ideas',
      'ecapp.app.main.barometer',
      'ecapp.app.main.risk-monitor',
    ];

    vm.showMoreLess = showMoreLess;
    vm.getDigit = getDigit;
    /**
     * Indicates whether trade ideas description in collapsed or not.
     * @type {boolean}
     */
    vm.collapsedDescription = true;
    btBackService.addBackButton($scope, vm, currentState, parentState, backStates);

    /**
     * This function reacts on subscription update.
     */
    function onSubscriptionUpgradeSuccess() {
      vm.hasCalendar = btRestrictionService.hasFeature('instrument-calendar');
    }

    /**
     * Activate component
     * @return {angular.IPromise<*>}
     */
    function activate() {
      vm.isLoading = true;

      vm.brokerObject = btTradingService.getBrokerObject();

      vm.broker = decodeURIComponent($stateParams.broker).toLowerCase();
      if (vm.broker === 'default') {
        vm.broker = 'default';
      }

      vm.symbol = decodeURIComponent($stateParams.symbol);

      if (gDebug) console.log('(Test) Broker: "' + vm.broker + '", Symbol: "' + vm.symbol + '"');

      vm.getPrecision = btInstrumentsService.getPrecision;
      vm.getPriceUnitValue = btInstrumentsService.getPriceUnitValue;
      vm.getPriceUnitName = btInstrumentsService.getPriceUnitName;

      btAdvertisingService.initialize().then(function () {
        if (vm.hasOpenAccountBanner()) {
          vm.banner = {
            title: vm.openAccountTitle,
            url: vm.openAccountLink,
            primary: true,
          };
          return;
        }

        var settings = btAdvertisingService.getBannerSettings('open-account');
        
        const banner = settings.enabled
          ? {
              title: 'Open Account',
              url: settings.url,
            }
          : null;

        if (vm.banner && vm.banner.primary) {
          vm.prevBanner = banner;
        } else {
          vm.banner = banner;
        }
      });

      return btShareScopeService
        .wait()
        .then(btTradingService.initialize)
        .then(btTradingService.connect)
        .then(btEventsService.init)
        .then(btInstrumentsService.init)
        .then(updateOpenAccountSettings)
        .then(initializeInstrumentPage);
    }

    /**
     *
     */
    function onViewLoaded() {
      $timeout(function () {
        $('#bt-instrument-page-content').focus();
      }, 1000);
    }

    /**
     *
     */
    function onViewEnter() {
      $timeout(function () {
        $('#bt-instrument-page-content').focus();
      }, 1000);
    }

    /**
     * Refresh instrument page
     */
    function refresh() {
      activate().finally(function () {
        $scope.$broadcast('scroll.refreshComplete');
      });
    }

    /**
     *
     */
    function onDestroy() {
      stopAutoUpdate();
    }

    /**
     *
     * @param {*} callback
     * @param {*} interval
     */
    // eslint-disable-next-line no-unused-vars
    function startAutoUpdate(callback, interval) {
      if (gIntervalPromise === null) {
        gIntervalPromise = $interval(callback, interval);
      }
    }

    /**
     *
     */
    function stopAutoUpdate() {
      if (gIntervalPromise !== null) {
        if ($interval.cancel(gIntervalPromise)) {
          gIntervalPromise = null;
        }
      }
    }

    /**
     * Initialize instrument page: check state parameters, load instrument information, load market data.
     */
    function initializeInstrumentPage() {
      // broker is not supported
      if (!btTradingService.isBrokerSupported(vm.broker)) {
        vm.hasError = true;
        vm.isLoading = false;
        vm.errorMessage = errors.broker.unsupported(vm.broker);
        return;
      }

      if (btTradingService.isDefaultBroker()) {
        // broker supported but another broker selected
        if (vm.broker === 'oanda') {
          vm.hasError = false;
          vm.isLoading = true;
          $state.go('ecapp.app.main.instrument-page', { broker: 'default', symbol: vm.symbol });
          return;
        }

        if (vm.broker === 'lds') {
          vm.hasError = false;
          vm.isLoading = true;
          $state.go('ecapp.app.main.instrument-page', { broker: 'default', symbol: vm.symbol });
          return;
        }
      } else {
        // broker supported but another broker selected
        if (btTradingService.getBrokerName() !== vm.broker) {
          vm.hasError = true;
          vm.isLoading = false;
          vm.errorMessage = errors.broker.notSelected(vm.broker, btTradingService.getBrokerName());
          return;
        }
      }

      // get common information about instrument
      if (vm.broker === 'default') {
        vm.instrument.instrument = btTradingService.getInstrumentByBrokerSymbol(vm.symbol);
      } else {
        vm.instrument.instrument = btTradingService.getInstrumentByBrokerSymbol(vm.symbol);
      }

      // instrument is not supported
      if (vm.instrument.instrument === undefined) {
        vm.hasError = true;
        vm.isLoading = false;
        vm.errorMessage = errors.symbol.unsupported(vm.symbol);
        return;
      }

      vm.isLDS = vm.instrument.instrument.provider === 'lds';

      vm.hasDetails = !btSettingsService.isLinkDataService();
      vm.hasCharacteristics = !btSettingsService.isLinkDataService();
      vm.hasTradeIdeas = !btSettingsService.isLinkDataService();
      vm.hasCalendar = !btSettingsService.isLinkDataService();

      vm.shortDescription =
        TEXT +
        ' This feed displays Trade Ideas that are relevant to the ' +
        vm.instrument.instrument.displayName +
        ' from both categories.';

      // show results
      vm.hasError = false;
      vm.isLoading = false;

      vm.complexSymbol = vm.instrument.instrument.complexSymbol;
      vm.hasComplexSymbol = !!vm.complexSymbol;
      // vm.complexSymbol = btInstrumentsService.createComplexSymbol(vm.symbol, vm.broker);

      // load market data
      // vm.instrument.loadingMarketData = true;
      // loadMarketData()
      //   .finally(function () {
      //     vm.instrument.loadingMarketData = false;
      //   });
      //
      // startAutoUpdate(loadMarketData, 5000);

      if (window.isDevMode && vm.instrument.instrument && vm.instrument.instrument.OandaSymbol) {
        var time = parseInt(Date.now() / 1000);
        var intervals = [1, 5, 60];

        var promises = intervals.map(function (value) {
          return btRequestService.getATR(vm.instrument.instrument.OandaSymbol + ':OANDA', value, time);
        });

        $q.all(promises).then(function (results) {
          vm.instrument.indicators = results.map(function (value, i) {
            value.interval = intervals[i];
            return value;
          });
        });
      }
    }

    /**
     * Update banner settings
     *
     * @return {Promise<null>}
     */
    function updateOpenAccountSettings() {
      if (btSettingsService.isLinkDataService()) {
        return $q.resolve();
      }
      return btShareScopeService
        .getAppSettings('trading')
        .then(function (result) {
          if (!$rootScope.isBTD) return null;
          
          var config = result.openAccountCard || {};
          vm.isOpenAccountEnabled = config.enabled;
          vm.openAccountLink = config.linkHref;
          vm.openAccountImage = config.imageSrc;
          vm.openAccountAlt = config.imageAlt;
          vm.openAccountTitle = config.title;

          return null;
        })
        .catch(function (reason) {
          console.error(reason);
          return null;
        });
    }

    // /**
    //  * This function loads market data for the instrument.
    //  *
    //  * @return {angular.IPromise<any>}
    //  */
    // function loadMarketData() {
    //   return btTradingService.getLiveCandleData(vm.instrument.instrument.brokerSymbol)
    //     .then(function (data) {
    //       vm.instrument.data = data;
    //       vm.instrument.hasMarketData = (data !== undefined);
    //     })
    //     .catch(function (reason) {
    //       vm.instrument.data = undefined;
    //       vm.instrument.hasMarketData = false;
    //       console.log('btInstrumentsContainer: error', reason);
    //     });
    // }

    /**
     * This function switches between full and collapsed views of trade ideas description.
     */
    function showMoreLess() {
      vm.collapsedDescription = !vm.collapsedDescription;
    }

    /**
     * This function shows upgrade popup for instrument calendar.
     */
    function showInstrumentCalendarUpgrade() {
      btRestrictionService.showUpgradePopup('instrument-calendar').then(function (status) {
        if (status === 'updated') {
          vm.hasCalendar = btRestrictionService.hasFeature('instrument-calendar');
        }
      });
    }

    /**
     * ???
     *
     * @param {number} tick
     * @return {number}
     */
    function getDigit(tick) {
      var i = 0;
      var t = tick;
      while (t < 0.1) {
        t *= 10;
        i++;
      }
      return i;
    }

    /**
     *
     * @return {boolean}
     */
    function hasMarketCharacteristics() {
      return btRestrictionService.hasFeature('market-characteristics');
    }

    /**
     *
     */
    function unlockMarketCharacteristics() {
      btRestrictionService.showUpgradePopup('market-characteristics');
    }
  }
})();
