/**
 * Created by Orly on 19/03/2019.
 */

/* global Datafeeds, TradeStationDataFeed */

// @ts-check
(function btChartsServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btChartsService', btChartsService);

  btChartsService.$inject = [
    '$q',
    '$rootScope',
    '$timeout',
    '$templateCache',
    '$ionicPopup',
    'btSettingsService',
    'btRestrictionService',
    'btSharedData',
    'btShareScopeService',
    'btTradingService',
  ];

  /**
   * This factory is to manage user's saved charts
   *
   * @ngdoc service
   * @name btChartsService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ITimeoutService} $timeout
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.ISharedData} btSharedData
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ITradingService} btTradingService
   * @return {ecapp.IChartsService}
   */
  function btChartsService(
    $q,
    $rootScope,
    $timeout,
    $templateCache,
    $ionicPopup,
    btSettingsService,
    btRestrictionService,
    btSharedData,
    btShareScopeService,
    btTradingService
  ) {
    console.log('Running btChartsService');

    var gDebug = false;
    var gChartPrefix = 'trading-view-chart-';

    /** @type {string[]} */
    var gSavedCharts = [];

    /** @type {string[]} */
    var gCurrentCharts = [];

    var gActiveChart = { index: 0 };

    // var gTimezone = getChartTimeZone();
    /** @type {TradingView.IChartingLibraryWidget[]} */
    var gChartWidgets = [];

    /** @type {ecapp.IChartRepresentation[]} */
    var gRepresentations = [];

    var gPopUp = null;

    /**
     * @deprecated
     * @typedef {object} TradingViewWidget
     * @property {object} options - widget options
     * @property {string} options.symbol - symbol
     * @property {string} options.timezone - timezone
     * @property {function} reload - reload widget
     */

    return {
      getSavedCharts: getSavedCharts,
      getCurrentCharts: getCurrentCharts,
      updateChart: updateChart,
      setCurrentCharts: setCurrentCharts,
      saveCharts: saveCharts,
      restoreCharts: restoreCharts,
      confirmRestoreCharts: confirmRestoreCharts,
      // getChartTimeZone: getChartTimeZone,
      // setChartTimeZone: setChartTimeZone,
      getChartWidgets: getChartWidgets,
      chartActiveReset: chartActiveReset,
      getActiveChart: getActiveChart,
      showPopUp: showPopUp,
      displayCharts: displayCharts,
      removeCharts: removeCharts,
      glowChart: glowChart,
      openInstrumentChart: openInstrumentChart,
      openSymbolChart: openSymbolChart,
      getChartSymbol: getChartSymbol,
    };

    // function loadLocalCharts() {
    //   gSavedCharts[0] = localStorage.getItem('btChartSymbol1') || btSettingsService.getAssets('chart-symbol1');
    //   gSavedCharts[1] = localStorage.getItem('btChartSymbol2') || btSettingsService.getAssets('chart-symbol2');
    //   gSavedCharts[2] = localStorage.getItem('btChartSymbol3') || btSettingsService.getAssets('chart-symbol3');
    //   gSavedCharts[3] = localStorage.getItem('btChartSymbol4') || btSettingsService.getAssets('chart-symbol4');
    //   gCurrentCharts = JSON.parse(JSON.stringify(gSavedCharts));
    // }

    /**
     * Returns symbol for charting library.
     *
     * @param {ecapp.ITradingInstrument} instrument  - instrument
     * @return {string | null} symbol for charting library
     */
    function getChartSymbol(instrument) {
      if (instrument.ticker) {
        if (btSettingsService.isLinkDataService()) {
          return instrument.ticker + ':' + instrument.month;
        } else {
          // !!! quick fix replace default to oanda
          return instrument.ticker;
          // return instrument.ticker.replace('TRADIER:', '').replace('_', '').replace('DEFAULT', 'OANDA');
        }
      } else if (instrument.OandaSymbol) {
        return 'OANDA:' + instrument.OandaSymbol;
        // return 'OANDA:' + instrument.OandaSymbol.replace('_', '');
      } else {
        return null;
      }
    }

    /**
     *
     * @alias ecapp.btChartsService#openInstrumentChart
     * @param {ecapp.ITradingInstrument} instrument
     */
    function openInstrumentChart(instrument) {
      openSymbolChart(getChartSymbol(instrument));
    }

    /**
     *
     * @alias ecapp.btChartsService#openSymbolChart
     * @param {string} symbol
     */
    function openSymbolChart(symbol) {
      if (btRestrictionService.hasFeature('custom-charts')) {
        updateChart(symbol);

        btSharedData.openDashboardTabByViewName('dashboard-charts').then(function () {
          glowChart(getActiveChart().index);
        });
      } else {
        btRestrictionService.showUpgradePopup('custom-charts');
      }
    }

    /**
     * Displays charts.
     */
    function displayCharts() {
      const charts = btTradingService.getCharts();
      gCurrentCharts = [...charts];
      gSavedCharts = [...charts];

      gChartWidgets = [
        createNewChart(0, gCurrentCharts[0], gChartPrefix + '1'),
        createNewChart(1, gCurrentCharts[1], gChartPrefix + '2'),
        createNewChart(2, gCurrentCharts[2], gChartPrefix + '3'),
        createNewChart(3, gCurrentCharts[3], gChartPrefix + '4'),
      ];

      gRepresentations = generateRepresentations();
    }

    /**
     *
     */
    function removeCharts() {
      gChartWidgets.forEach(function (widget) {
        widget.remove();
      });
    }

    /**
     *
     * @return {string[]}
     */
    function getSavedCharts() {
      if (gDebug) console.log('btChartsService: gSavedCharts', gSavedCharts);
      return gSavedCharts;
    }

    /**
     * This function returns copy of current charts.
     *
     * @return {string[]}
     */
    function getCurrentCharts() {
      if (gDebug) console.log('btChartsService: gCurrentCharts', gCurrentCharts);
      return JSON.parse(JSON.stringify(gCurrentCharts));
    }

    /**
     * This function saves default symbol for active chart.
     *
     * @param {string | null} symbol - symbol name
     */
    function updateChart(symbol) {
      if (symbol) {
        gCurrentCharts[gActiveChart.index] = symbol;
        if (gChartWidgets.length > 0) {
          // @ts-ignore
          gChartWidgets[gActiveChart.index].setSymbol(symbol, '1H', function () {
            console.log('Chart was reloaded!');
          });

          // if (btSettingsService.isLinkDataService()) {
          //   gChartWidgets[gActiveChart.index].setSymbol(symbol, '1D', function () {
          //     console.log('Chart was reloaded!');
          //   });
          // } else {
          //   gChartWidgets[gActiveChart.index].options.symbol = symbol;
          //   gChartWidgets[gActiveChart.index].reload();
          // }
        }
      } else {
        console.log('Charting is not supported for this instrument');
      }
    }

    /**
     *
     * @param {string[]} charts
     */
    function setCurrentCharts(charts) {
      gCurrentCharts = charts;
    }

    /**
     * This function saves default symbols for all charts
     */
    function saveCharts() {
      for (var i = 0; i < 4; i++) {
        localStorage.setItem('btChartSymbol' + (i + 1), gCurrentCharts[i]);
      }
      gSavedCharts = [...gCurrentCharts];
      btTradingService.setCharts(gSavedCharts).then(() => {
        console.log('Charts saved!!');
      })
    }

    // function getChartTimeZone() {
    //   return localStorage.getItem('btChartTimeZone') || btSettingsService.getAssets('chart-timezone');
    // }

    // function setChartTimeZone(value) {
    //   gTimezone = value;
    //   localStorage.setItem('btChartTimeZone', value);
    // }

    /**
     * This function asks user to confirm restoring of all charts.
     * It returns true if user clicked okay button.
     *
     * @return {angular.IPromise<boolean>}
     */
    function confirmRestoreCharts() {
      return $ionicPopup.confirm({
        title: 'Restore Charts',
        template:
          'All changes that have been made on charts will be lost.<br><br>' +
          'Are you sure you want to restore all chart symbols?.',
      });
    }

    /**
     * This function restore all charts
     */
    function restoreCharts() {
      gChartWidgets.forEach(function (widget, i) {
        restoreChart(widget, i);
      });
    }

    /**
     * This function restore chart from las save value.
     *
     * @param {TradingView.IChartingLibraryWidget} widget - TradingView widget object
     * @param {number} chartIndex - index of chart in storage
     */
    function restoreChart(widget, chartIndex) {
      gCurrentCharts[chartIndex] = gSavedCharts[chartIndex];
      // @ts-ignore
      widget.setSymbol(gSavedCharts[chartIndex], '1H', function () {
        console.log('Chart was reloaded!');
      });

      // widget.options.symbol = gSavedCharts[chartIndex];
      // widget.options.timezone = btShareScopeService.accountInfo.timeZone;
      // widget.reload();
    }

    /**
     *
     * @return {TradingView.IChartingLibraryWidget[]}
     */
    function getChartWidgets() {
      return gChartWidgets;
    }

    /**
     *
     * @return {{index: number}}
     */
    function getActiveChart() {
      return gActiveChart;
    }

    /**
     * This function returns new chart widget.
     * {TradingViewWidget|TradingView.IChartingLibraryWidget}
     *
     * @param {number} index - index
     * @param {string} symbol - symbol
     * @param {string} containerId - html identifier of widget container
     * @return {TradingView.IChartingLibraryWidget}
     */
    function createNewChart(index, symbol, containerId) {
      var widget;

      /** @type {TradingView.ResolutionString} */
      // @ts-ignore
      // var resolution = '1D';
      var resolution = '1';

      /** @type {TradingView.ThemeName} */
      // @ts-ignore
      var theme = 'dark';

      /** @type {TradingView.Timezone} */
      // @ts-ignore
      var timezone = btShareScopeService.accountInfo.timeZone;

      if (btSettingsService.isLinkDataService()) {
        widget = new window.TradingView.widget({
          debug: false,
          datafeed: new Datafeeds.UDFCompatibleDatafeed(
            window.location.origin + '/api/udf-symbols',
            $rootScope.currentUser.tokenId,
            60000
          ),
          // datafeed: new Datafeeds.UDFCompatibleDatafeed('https://bt-client-lds.herokuapp.com/lds-udf-api', token, 60000),
          library_path: '/charting_library/',
          autosize: true,
          symbol: symbol,
          interval: resolution,
          timezone: timezone,
          theme: theme,
          locale: 'en',
          disabled_features: ['header_saveload'],
          enabled_features: [],
          charts_storage_url: 'https://saveload.tradingview.com',
          charts_storage_api_version: '1.1',
          client_id: 'tradingview.com',
          user_id: 'public_user_id',
          container: containerId,
          overrides: {},
          favorites: {
            intervals: [resolution],
            chartTypes: ['Line'],
          },
          studies_overrides: {},
        });
        widget.onChartReady(function () {
          widget.applyOverrides({
            'mainSeriesProperties.style': 2,
          });

          widget.subscribe('add_compare', function () {
            widget.chart().getPanes()[0].getRightPriceScales()[0].setMode(0);
          });

          widget
            .activeChart()
            .onSymbolChanged()
            .subscribe(null, function () {
              gCurrentCharts[index] = widget.activeChart().symbol()
              console.log('Chart', index, widget.activeChart().symbol());
            });
        });

        return widget;
      } else {
        var datafeed;
        var INTERVAL = 10000;
        // var token = $rootScope.currentUser.tokenId;
        // var datafeed = new Datafeeds.UDFCompatibleDatafeed('https://bt-api-server-staging.herokuapp.com/api/udf', $rootScope.currentUser.tokenId, 60000);
        // var datafeed = new Datafeeds.UDFCompatibleDatafeed('http://localhost:3005/api/udf', $rootScope.currentUser.tokenId, 60000);
        // var datafeed = new Datafeeds.UDFCompatibleDatafeed('https://bt-client-lds.herokuapp.com/lds-udf-api', token, 60000);

        var broker = btTradingService.getBrokerName();
        if (broker === 'tradestation') {
          datafeed = new TradeStationDataFeed(btTradingService);
        } else {
          var url = 'https://bt-api-server-staging.herokuapp.com/api/udf';
          // if (window.isDevelopment) url = 'http://localhost:3005/api/udf';
          var API_KEY = 'ZXdnam9WcGNnZkVnTTg3ZFl2dUhKUG5xTjJxZzZlT09mNGxmTVJubg';
          datafeed = new Datafeeds.UDFCompatibleDatafeed(url, atob(API_KEY + '=='), INTERVAL);
        }

        widget = new window.TradingView.widget({
          debug: false,
          datafeed: datafeed,
          library_path: '/charting_library/',
          autosize: true,
          symbol: symbol,
          interval: resolution,
          timezone: timezone,
          theme: theme,
          locale: 'en',
          disabled_features: ['header_saveload'],
          enabled_features: [],
          charts_storage_url: 'https://saveload.tradingview.com',
          charts_storage_api_version: '1.1',
          client_id: 'tradingview.com',
          user_id: 'public_user_id',
          container: containerId,
          overrides: {},
          // favorites: {
          //   intervals: ['1D'],
          //   chartTypes: ['Line']
          // },
          studies_overrides: {},
        });

        widget.onChartReady(function () {
          // widget.applyOverrides({
          //   'mainSeriesProperties.style': 2
          // });

          widget
            .activeChart()
            .onSymbolChanged()
            .subscribe(null, function () {
              console.log('Chart', index, widget.activeChart().symbol());
            });

          // widget.subscribe('add_compare', function () {
          //   widget.chart().getPanes()[0].getRightPriceScales()[0].setMode(0);
          // });
        });

        return widget;

        // return new window.TradingView.widget({
        //   'autosize': true,
        //   'symbol': symbol,
        //   'interval': '1',
        //   'timezone': btShareScopeService.accountInfo.timeZone,
        //   'theme': 'Dark',
        //   'style': '1',
        //   'locale': 'en',
        //   'toolbar_bg': '#f1f3f6',
        //   'enable_publishing': false,
        //   'allow_symbol_change': true,
        //   'hideideas': true,
        //   'hide_side_toolbar': false,
        //   'show_popup_button': true,
        //   'popup_width': '1000',
        //   'popup_height': '650',
        //   'container_id': containerId
        // });
      }
    }

    /**
     * Initializes scope for "Chart Selector" popup.
     * @return {scopes.ChartSelectorPopup.IScope}
     */
    function initPopUpScope() {
      var scope = /**@type {scopes.ChartSelectorPopup.IScope}*/ ($rootScope.$new(true));
      scope.activeChartIndex = gActiveChart.index;
      scope.representations = gRepresentations;
      scope.chartActiveReset = chartActiveReset;
      return scope;
    }

    /**
     * This function shows popup to set active chart
     */
    function showPopUp() {
      updateRepresentations();

      gPopUp = $ionicPopup.show({
        template: $templateCache.get('dashboard/select-chart-popup.html'),
        title: 'Chart Selector',
        cssClass: 'popup-bar-dark chart-popup',
        scope: initPopUpScope(),
        buttons: [{ text: 'Cancel' }],
      });
    }

    /**
     * This function initializes charts representations
     *
     * @return {ecapp.IChartRepresentation[]}
     */
    function generateRepresentations() {
      var representations = [];
      for (var i = 1; i <= 4; i++) {
        representations.push({
          element: $('#' + gChartPrefix + i),
          symbol: null,
          disabled: null,
        });
      }
      return representations;
    }

    /**
     * This function updates chart representations in popup according to displayed charts and their default symbols
     */
    function updateRepresentations() {
      gRepresentations.forEach(function (representation, i) {
        representation.symbol = gSavedCharts[i];
        representation.disabled = representation.element.is(':hidden');
      });
    }

    /**
     * This function sets selected chart as active
     * @param {number} i - chart index
     */
    function chartActiveReset(i) {
      if (gRepresentations[i] && !gRepresentations[i].disabled) {
        setChartActive(i);
        gPopUp.close();
        glowChart(i);
      }
    }

    /**
     *
     * @param {number} chartIndex
     */
    function setChartActive(chartIndex) {
      gActiveChart.index = chartIndex;
    }

    /**
     * Glows chart by index
     * @param {number} i - chart index
     */
    function glowChart(i) {
      if (!gRepresentations[i]) return;

      gRepresentations[i].element.addClass('animated-chart');
      $timeout(function () {
        gRepresentations[i].element.removeClass('animated-chart');
      }, 2000);
    }
  }
})();
