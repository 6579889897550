/**
 * Created by Sergey Panpurin on 09/04/2017.
 */

// @ts-check
(function btTradierApiServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btTradierApiService', btTradierApiService);

  btTradierApiService.$inject = [
    '$q',
    '$http',
    '$rootScope',
    '$ionicPopup',
    'btOauthService',
    'btDateService',
    'btTemplateApiService',
  ];

  /**
   * This factory works with Tradier API.
   *
   * @ngdoc service
   * @name btTradierApiService
   * @memberOf ecapp
   * @param {angular.IQService} $q
   * @param {angular.IHttpService} $http
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @return {ecapp.ITradierApiService}
   */
  function btTradierApiService(
    $q,
    $http,
    $rootScope,
    $ionicPopup,
    btOauthService,
    btDateService,
    btTemplateApiService
  ) {
    console.log('Running btTradierApiService');

    var gUserData = null;
    var gIsLoggedIn = false;
    var gAccounts = [];
    var gSelectedAccountId = null;

    // var gBaseUrl = 'https://sandbox.tradier.com';
    var gBaseUrl = 'https://api.tradier.com';

    var gApiKey;
    var gAuthorization;
    var gDefaultHeaders;
    /* cspell:disable-next-line */
    // _setAccessToken('G7yowey3jkfZ1ySBUqRxW5dUEQcI');

    // var gInstruments = null;
    var gHistoryCache = {};

    /**
     * Get one instrument last prices. Send request to oanda every 5 second for realtime Values
     * Used in Markets tab, Event Related instruments
     * @param {string} instrument - Instrument name known by oanda API
     * @return {*}
     */
    function getLiveCandleData(instrument) {
      var defer = $q.defer();

      $http
        .get(gBaseUrl + '/v1/markets/quotes', {
          headers: gDefaultHeaders,
          params: {
            symbols: instrument,
          },
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          if (response.data.quotes && response.data.quotes.unmatched_symbols) {
            return $q.reject(new Error('Unmatched symbols' + JSON.stringify(response.data.quotes.unmatched_symbols)));
          }

          var quote = response.data.quotes && response.data.quotes.quote ? response.data.quotes.quote : {};
          if (quote.open === null || quote.low === null || quote.high === null) {
            if (gHistoryCache[instrument] === undefined) {
              $http
                .get(gBaseUrl + '/v1/markets/history', {
                  headers: gDefaultHeaders,
                  params: {
                    symbol: instrument,
                    interval: 'daily',
                    end: btDateService.getYesterday('YYYY-MM-DD'),
                  },
                })
                .catch(_handleHTTPError)
                .then(_parseAPIError)
                .then(function (response) {
                  var last;
                  if (response.data.history) {
                    var history = response.data.history.day;
                    last = history[history.length - 1];
                  } else {
                    last = {
                      open: 0,
                      high: 0,
                      low: 0,
                      close: 0,
                    };
                  }

                  gHistoryCache[instrument] = last;

                  quote.open = last.open;
                  quote.low = last.low;
                  quote.high = last.high;
                  var candle = _convertLiveCandle(instrument, quote);
                  defer.resolve(candle);
                })
                .catch(function (error) {
                  defer.reject(error);
                });
            } else {
              var last = gHistoryCache[instrument];

              quote.open = last.open;
              quote.low = last.low;
              quote.high = last.high;
              defer.resolve(_convertLiveCandle(instrument, quote));
            }
          } else {
            delete gHistoryCache[instrument];
            defer.resolve(_convertLiveCandle(instrument, quote));
          }
        })
        .catch(function (error) {
          defer.reject(error);
        });
      return defer.promise;
    }

    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Send request to oanda
     * @param {*} instrument - Instrument name known by oanda API
     * @param {*} granularity - Set the candle time representation
     * @param {number} [count] - number of candles
     * @return {*}
     */
    function getLastCandlesData(instrument, granularity, count) {
      var options = {
        M1: { interval: '1min', m: 60 * 1000, count: count },
        M5: { interval: '5min', m: 5 * 60 * 1000, count: count },
        M15: { interval: '15min', m: 15 * 60 * 1000, count: count },
        H1: { interval: '15min', m: 60 * 60 * 1000, count: count },
      };
      var defer = $q.defer();
      $http
        .get(gBaseUrl + '/v1/markets/timesales', {
          headers: gDefaultHeaders,
          params: {
            symbol: instrument,
            interval: options[granularity].interval,
            start: btDateService.format(btDateService.nDaysAgo(new Date(), 7), 'YYYY-MM-DD HH:MM'),
            // end: btDateService.getNowDate('YYYY-MM-DD HH:MM'),
            session_filter: 'all',
          },
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          var history = response.data.series.data;
          var candles;
          if (history.length > options[granularity].count) {
            candles = history.slice(history.length - options[granularity].count);
          } else {
            candles = history;
          }

          if (granularity === 'H1') {
            candles = btDateService.convertInterval(candles, 'time', granularity, function (t, group) {
              var newData = { high: [], low: [], open: [], close: [], volume: [] };
              group.items.forEach(function (t2) {
                newData.high.push(t2.high);
                newData.low.push(t2.low);
                newData.open.push(t2.open);
                newData.close.push(t2.close);
                newData.volume.push(t2.volume);
              });

              return {
                time: parseInt(t),
                high: Math.max.apply(null, newData.high),
                low: Math.min.apply(null, newData.low),
                open: newData.open[0],
                close: newData.close[newData.close.length - 1],
                volume: newData.volume.reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue;
                }),
              };
            });
          }

          candles = candles.filter(function (t) {
            return (
              new Date(candles[candles.length - 1].time).getTime() - new Date(t.time).getTime() <
              options[granularity].m * options[granularity].count
            );
          });
          var convertedCandles = [];

          candles.forEach(function (candle) {
            convertedCandles.push(_convertLastCandle(candle));
          });

          defer.resolve({ candles: convertedCandles });
        })
        .catch(function (error) {
          defer.reject(error);
        });
      return defer.promise;
    }

    /**
     * Get entry price for trade card
     * @param {*} instrument - Instrument name known by oanda API
     * @param {Number} time - timestamp in seconds
     * @return {angular.IPromise<*>}
     */
    function getEntryPrice(instrument, time) {
      var defer = $q.defer();
      $http
        .get(gBaseUrl + '/v1/markets/timesales', {
          headers: gDefaultHeaders,
          params: {
            symbol: instrument,
            interval: '1min',
            start: btDateService.format(btDateService.nDaysAgo(new Date(), 1), 'YYYY-MM-DD HH:MM'),
            end: btDateService.format(new Date(time * 1000), 'YYYY-MM-DD HH:MM'),
            session_filter: 'all',
          },
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          var history = response.data.series.data;
          var candles = history.slice(history.length - 2 - 1, history.length - 1);
          var convertedCandles = [];

          candles.forEach(function (candle) {
            convertedCandles.push(_convertLastCandle(candle));
          });

          defer.resolve({ candles: convertedCandles });
        })
        .catch(function (error) {
          defer.reject(error);
        });
      return defer.promise;
    }

    /* --- Public functions --- */

    /**
     * Initialize service
     *
     * @param {Object} data - access data
     * @return {angular.IPromise<*>}
     */
    function initialize(data) {
      var deferred = $q.defer();

      if (data === undefined || data === null) {
        deferred.reject(new Error('Bad access data'));
      } else {
        gUserData = data;
        _setAccessToken(data.token);
        if (gUserData.defaultAccount) {
          gSelectedAccountId = gUserData.defaultAccount;
        }
        deferred.resolve({});
      }
      return deferred.promise;
    }

    /**
     * Connect Broker
     *
     * @return {angular.IPromise<*>}
     */
    function connect() {
      var deferred = $q.defer();
      console.log('btTradierApiService: connect');
      if (gUserData !== undefined && gUserData !== null) {
        if (gIsLoggedIn) {
          deferred.resolve({});
        } else {
          setTradingMode(gUserData.mode);
          gIsLoggedIn = true;
          deferred.resolve({});
        }
      } else {
        gIsLoggedIn = false;
        deferred.reject(new Error('Error'));
      }
      return deferred.promise;
    }

    /**
     * Disconnect broker
     *
     * @return {angular.IPromise<*>}
     */
    function disconnect() {
      console.log('btTradierApiService: disconnect');
      return logout();
    }

    /**
     * Login Broker
     *
     * @param {String} mode - trading mode: real ot demo
     * @param {Boolean} isForceLogin - force login or not
     * @return {angular.IPromise<*>}
     */
    function login(mode, isForceLogin) {
      void isForceLogin;

      return btOauthService.login('tradier-' + mode).then(function (data) {
        console.log('btTradierApiService: login');
        // reset selected account after default broker
        gSelectedAccountId = null;
        gUserData = { token: data.accessToken, mode: mode };
        _setAccessToken(data.accessToken);
        // window.localStorage.setItem('OANDAToken', JSON.stringify(userData));
        setTradingMode(mode);

        return gUserData;
      });
    }

    /**
     * Fast Login Broker
     *
     * @param {String} mode - trading mode: real ot demo
     * @param {Object} data - access data
     * @return {angular.IPromise<*>}
     */
    function fastLogin(mode, data) {
      console.log('btTradierApiService: login');
      // reset selected account after default broker
      gSelectedAccountId = null;
      gUserData = { token: data.sso_token, mode: mode };
      setTradingMode(mode);

      return $q.resolve(gUserData);
    }

    /**
     * Logout Broker
     *
     * @return {angular.IPromise<*>}
     */
    function logout() {
      var deferred = $q.defer();
      console.log('btTradierApiService: logout');
      gUserData = null;
      gIsLoggedIn = false;
      gAccounts = [];
      gHistoryCache = {};
      gSelectedAccountId = null;
      // window.localStorage.setItem('OANDAToken', userData);
      deferred.resolve({});
      return deferred.promise;
    }

    /**
     * Check user data (now just username)
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      void userData;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Create username from email
     * @param {String} email - user email as feed to create username
     * @return {angular.IPromise<*>}
     */
    function getUsername(email) {
      void email;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Create new OANDA account via API
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>}
     */
    function signUp(userData) {
      void userData;
      return $q.reject(new Error('Wait'));
    }

    /**
     * Is user logged in to TradeStation API
     *
     * @return {*}
     */
    function isLoggedIn() {
      return gUserData !== undefined && gUserData !== null;
    }

    /**
     * Get trading mode: live or practice
     *
     * @return {String} - trading mode: "practice" or "trade"
     */
    function getTradingMode() {
      return gUserData.mode;
    }

    /**
     * Set trading mode. Return true on success.
     *
     * @param {*} mode
     * @return {boolean} trading mode was changed
     */
    function setTradingMode(mode) {
      gUserData.mode = mode;
      return true;
    }

    /**
     * Handle Tradier API error
     * @param {{status, data: {fault: {faultstring}, message}}} response - response object
     * @return {angular.IPromise<Object | Error>} - response contain error
     * @private
     */
    function _handleHTTPError(response) {
      if (parseInt(response.status) !== 200 && parseInt(response.status) !== 201) {
        if (response.data && response.data.message) return $q.reject(new Error(response.data.message));

        if (response.data && response.data.fault) {
          if (_isTokenExpired(response.data.fault)) {
            return $q.reject(new Error('Access token expired - try to login again'));
          } else {
            return $q.reject(new Error(response.data.fault.faultstring));
          }
        }

        return $q.reject(new Error('Unknown Error.'));
      } else {
        return $q.reject(response);
      }
    }

    /**
     *
     * @param {*} data
     * @return {boolean}
     * @private
     */
    function _isTokenExpired(data) {
      return !!(data.detail && data.detail.errorcode === 'keymanagement.service.access_token_expired');
    }

    /**
     *
     * @param {*} response
     * @return {*}
     * @private
     */
    function _parseAPIError(response) {
      if (response.data && response.data.errors && response.data.errors.error) {
        return $q.reject(new Error(response.data.errors.error.toString()));
      } else {
        return response;
      }
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     *
     * @return {angular.IPromise<Array>} - list of accounts
     */
    function getAccounts() {
      var deferred = $q.defer();
      $http
        .get(gBaseUrl + '/v1/user/profile', {
          headers: gDefaultHeaders,
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          if (response.data.profile) {
            gAccounts = _getArray(response.data.profile, 'account').map(_convertAccount);
          } else {
            gAccounts = [];
          }

          // reset selected account
          gSelectedAccountId = btTemplateApiService.resetSelectedAccount(gAccounts, gSelectedAccountId);

          deferred.resolve(gAccounts);
        })
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * Get balance for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      var deferred = $q.defer();

      var promises = [];
      accountIds.forEach(function (accountId) {
        var promise = $http
          .get(gBaseUrl + '/v1/accounts/' + accountId + '/balances', {
            headers: gDefaultHeaders,
          })
          .catch(_handleHTTPError)
          .then(_parseAPIError);
        promises.push(promise);
      });

      $q.all(promises)
        .then(function (responses) {
          var convertedBalances = [];
          responses.forEach(function (response) {
            var account = response.data.balances;
            convertedBalances.push(_convertBalance(account));
          });

          deferred.resolve(convertedBalances);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Get all positions for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getPositions(accountIds) {
      var deferred = $q.defer();

      var promises = [];
      accountIds.forEach(function (accountId) {
        var promise = $http
          .get(gBaseUrl + '/v1/accounts/' + accountId + '/positions', {
            headers: gDefaultHeaders,
          })
          .catch(_handleHTTPError)
          .then(_parseAPIError);
        promises.push(promise);
      });

      $q.all(promises)
        .then(function (responses) {
          // console.log(responses);

          var convertedPositions = [];

          responses.forEach(function (response, i) {
            var positions = _getArray(response.data.positions, 'position');
            positions.forEach(function (position) {
              convertedPositions.push(_convertPosition(position, accountIds[i]));
            });
          });

          deferred.resolve(convertedPositions);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Get all orders for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getOrders(accountIds) {
      var deferred = $q.defer();

      var promises = [];
      accountIds.forEach(function (accountId) {
        var promise = $http
          .get(gBaseUrl + '/v1/accounts/' + accountId + '/orders', {
            headers: gDefaultHeaders,
          })
          .catch(_handleHTTPError)
          .then(_parseAPIError);
        promises.push(promise);
      });

      $q.all(promises)
        .then(function (responses) {
          var convertedOrders = [];

          responses.forEach(function (response, i) {
            var orders = _getArray(response.data.orders, 'order');
            orders.forEach(function (order) {
              convertedOrders.push(_convertOrder(order, accountIds[i]));
            });
          });

          deferred.resolve(convertedOrders);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {String} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      void symbol;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      void query;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Search symbol using query string
     *
     * @param {String} text - search text
     * @param {Number} limit - limit number of results
     * @param {Object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      void params;
      var deferred = $q.defer();
      $http
        .get(gBaseUrl + '/v1/markets/lookup', {
          headers: gDefaultHeaders,
          params: {
            q: text,
          },
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          var symbols = _getArray(response.data.securities, 'security').slice(0, limit);

          var convertedSymbol = [];
          symbols.forEach(function (symbol) {
            convertedSymbol.push(_convertSymbol(symbol));
          });

          deferred.resolve(convertedSymbol);
        })
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {String[]} symbols
     * @return {angular.IPromise<Array>}
     */
    function getQuotes(symbols) {
      var defer = $q.defer();

      $http
        .get(gBaseUrl + '/v1/markets/quotes', {
          headers: gDefaultHeaders,
          params: {
            symbols: symbols.join(','),
          },
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          if (response.data.quotes && response.data.quotes.unmatched_symbols) {
            return $q.reject(new Error('Unmatched symbols' + JSON.stringify(response.data.quotes.unmatched_symbols)));
          }

          var quotes = _getArray(response.data.quotes, 'quote');

          defer.resolve({
            time: Math.floor(Date.now() / 1000),
            prices: quotes.map(function (value) {
              return {
                instrument: value.symbol,
                time: Math.floor(Date.now() / 1000),
                tradeable: true,
                bids: [{ price: value.bid, liquidity: value.bidsize }],
                asks: [{ price: value.ask, liquidity: value.asksize }],
              };
            }),
          });
        })
        .catch(function (error) {
          defer.reject(error);
        });
      return defer.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {String} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      void symbol;
      void onProgress;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {String[]} symbols
     * @param {Object} range
     * @param {Number} interval -
     * @param {String} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      void symbols;
      void range;
      void interval;
      void unit;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      console.log('streamSnapshot', ticker, granularity, back, onUpdate);
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      void order;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    // function appendTransform(defaults, transform) {
    //
    //   // We can't guarantee that the default transformation is an array
    //   defaults = angular.isArray(defaults) ? defaults : [defaults];
    //
    //   // Append the new transformation to the defaults
    //   return defaults.concat(transform);
    // }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      var deferred = $q.defer();

      if (getSelectedAccountId() === null) {
        deferred.reject(new Error('Select account at first'));
      } else {
        var newOrder = _prepareOrder(order);

        $http
          .post(gBaseUrl + '/v1/accounts/' + getSelectedAccountId() + '/orders', newOrder, {
            headers: gDefaultHeaders,
            transformRequest: function (obj) {
              var str = [];
              for (var p in obj) {
                // noinspection JSUnfilteredForInLoop
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
              }
              return str.join('&');
            },
          })
          .catch(_handleHTTPError)
          .then(_parseAPIError)
          .then(function (response) {
            deferred.resolve(response);
          })
          .catch(function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    /**
     *
     * @param {ecapp.ITradingOrderRequest} order
     * @return {Object}
     * @private
     */
    function _prepareOrder(order) {
      // Equity order options: buy, buy_to_cover, sell, sell_short
      // Option order options: buy_to_open, buy_to_close, sell_to_open, sell_to_close
      var convertedActions = {
        SELL: 'sell',
        SELL_SHORT: 'sell_short',
        BUY: 'buy',
        BUY_COVER: 'buy_to_cover',
      };

      //market, limit, stop, stop_limit
      //Market, Limit, Stop
      var convertedTypes = {
        Market: 'market',
        Limit: 'limit',
        Stop: 'stop',
        // StopLimit: 'stop_limit'
      };

      var newOrder = {
        class: 'equity',
        symbol: order.symbol,
        duration: order.type === 'Market' ? 'day' : 'gtc',
        side: convertedActions[order.action],
        quantity: order.quantity,
        type: convertedTypes[order.type],
      };

      if (order.type === 'Limit') newOrder.price = order.limitPrice.toString();

      if (order.type === 'Stop') newOrder.stop = order.limitPrice.toString();

      return newOrder;
    }

    /**
     * Update order
     *
     * @param {String} orderId - order id
     * @param {Object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      void orderId;
      void orderChanges;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Cancel order
     *
     * @param {String} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      var deferred = $q.defer();
      $http
        .delete(gBaseUrl + '/v1/accounts/' + getSelectedAccountId() + '/orders/' + orderId, {
          headers: gDefaultHeaders,
        })
        .catch(_handleHTTPError)
        .then(_parseAPIError)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * Select account
     *
     * @param {String} id - account id
     * @return {?String} id of selected account or null
     */
    function selectAccount(id) {
      var res = gAccounts.filter(function (item) {
        return item.key === id;
      });

      gSelectedAccountId = res ? res[0].key : null;

      return gSelectedAccountId;
    }

    /**
     *
     * @return {any}
     */
    function getSelectedAccountId() {
      return gSelectedAccountId;
    }

    /**
     *
     * @param {*} id - ?
     * @return {any}
     */
    function isAccountSelected(id) {
      return gSelectedAccountId ? gSelectedAccountId === id : false;
    }

    /**
     *
     * @return {any}
     */
    function getAccessData() {
      var deferred = $q.defer();

      deferred.resolve(gUserData);

      return deferred.promise;
    }

    /**
     *
     * @param {tradierAccount} account
     * @return {ecapp.ITradingAccount}
     * @private
     */
    function _convertAccount(account) {
      return {
        acc: account.account_number,
        key: account.account_number,
        name: account.account_number,
        rawData: account,

        // alias: account.account_number,
        // altId: account.account_number,
        // displayName: account.account_number,
        // isStockLocateEligible: false,
        // type: account.type,
        // typeDescription: '',
      };
    }

    /**
     *
     * @param {tradierBalance} balance
     * @return {ecapp.ITradingBalance}
     * @private
     */
    function _convertBalance(balance) {
      var temp = {
        acc: balance.account_number,
        key: balance.account_number,
        name: balance.account_number,
        type: balance.account_type,
        NAV: balance.total_equity,
        UPL: balance.open_pl,
        Balance: balance.total_cash,
        RPL: balance.close_pl,
        MarginUsed: balance.pending_cash,
        MarginAvailable: balance.total_cash,
        rawData: balance,

        // alias: balance.accountId,
        // displayName: balance.accountId,
        // typeDescription: '',
      };

      if (balance.account_type === 'margin') {
        temp.MarginAvailable = balance.margin.stock_buying_power;
      }

      if (balance.account_type === 'cash') {
        temp.MarginAvailable = balance.cash.cash_available;
      }

      if (balance.account_type === 'pdt') {
        temp.MarginAvailable = balance.pdt.stock_buying_power;
      }

      return temp;
    }

    /**
     *
     * @param {tradierPosition} position
     * @param {String} accountId
     * @return {ecapp.ITradingPosition}
     * @private
     */
    function _convertPosition(position, accountId) {
      return {
        key: position.id.toString(),
        description: position.symbol,
        acc: accountId,
        symbol: position.symbol,
        position: position.quantity > 0 ? 'Long' : 'Short',
        quantity: position.quantity,
        OPL: position.cost_basis,
        acct: 0,
        total: 0,
        margin: 0,
        avg: 0,
        mrkValue: 0,
        lastPrice: 0,
        rawData: position,

        // alias: accountId,
      };
    }

    /**
     *
     * @param {tradierOrder} order
     * @param {String} accountId
     * @return {btOrderObject}
     * @private
     */
    function _convertOrder(order, accountId) {
      // Received, Filled, PartialFill, Rejected
      // filled, canceled, open, expired, rejected, pending, partially_filled, submitted

      var convertedStatus = {
        filled: 'Filled',
        canceled: 'Rejected',
        open: 'Received',
        expired: 'Rejected',
        rejected: 'Rejected',
        pending: 'Received',
        partially_filled: 'PartialFill',
        submitted: 'Received',
      };

      var convertedTypes = {
        market: 'Market',
        limit: 'Limit',
        stop: 'Stop',
        stop_limit: 'Stop/Limit',
      };

      var convertedSides = {
        sell: 'Short',
        sell_short: 'Short',
        buy: 'Long',
        buy_to_cover: 'Long',
      };

      var convertedActions = {
        sell: 'Sell',
        sell_short: 'Sell Short',
        buy: 'Buy',
        buy_to_cover: 'Buy To Cover',
      };

      return {
        key: order.id.toString(),
        acc: accountId,
        symbol: order.symbol,
        quantity: Math.abs(order.quantity),
        side: convertedSides[order.side],
        action: convertedActions[order.side],
        limit: order.type === 'limit' ? order.price : '-',
        stop: order.type === 'stop' ? order.price : '-',
        price: order.price,
        type: convertedTypes[order.type],
        status: convertedStatus[order.status],
        fillPrice: 0,
        filled: 0,
        placeTime: order.create_date,
        executeTime: order.transaction_date,
        rawData: order,

        // alias: accountId,
        // displayName: order.symbol,
      };
    }

    /**
     *
     * @param {tradierSymbol} symbol
     * @return {ecapp.ITradingSymbol}
     * @private
     */
    function _convertSymbol(symbol) {
      return {
        ticker: symbol.symbol,
        name: symbol.symbol,
        desc: symbol.description ? symbol.description : symbol.symbol,
        rawData: symbol,
      };
    }

    /**
     *
     * @param {string} symbol - ? 
     * @param {Object} quote - ?
     * @return {ecapp.ITradingLiveCandle}
     * @private
     */
    function _convertLiveCandle(symbol, quote) {
      ['prevclose', 'low', 'high', 'open', 'bid', 'ask', 'last'].forEach(function (t) {
        if (quote[t]) {
          if (typeof quote[t] === 'number') {
            quote[t + 'Text'] = quote[t].toFixed(2);
          } else {
            quote[t + 'Text'] = quote[t];
          }
        } else {
          quote[t + 'Text'] = 'N/A';
        }
      });

      return {
        symbol: symbol,
        yesterday: {
          close: quote.prevclose,
          closeText: quote.prevcloseText,
        },
        today: {
          low: quote.low,
          lowText: quote.lowText,
          high: quote.high,
          highText: quote.highText,
          open: quote.open,
          openText: quote.openText,
        },
        now: {
          bid: quote.bid,
          bidText: quote.bidText,
          ask: quote.ask,
          askText: quote.askText,
          last: quote.last,
          lastText: quote.lastText,
        },
      };
    }

    /**
     * Convert last candle
     * @param {Object} candle
     * @return {ecapp.ITradingLastCandle} - BetterTrader last candle object
     * @private
     */
    function _convertLastCandle(candle) {
      return {
        complete: true,
        mid: {
          c: candle.close,
          h: candle.high,
          l: candle.low,
          o: candle.open,
        },
        time: new Date(candle.time).getTime() / 1000,
        volume: candle.volume,
      };
    }

    /**
     * Convert data to array.
     * Array -> Array
     * Object -> [Object]
     * null -> []
     *
     * @param {Object | null | String} data - data object
     * @param {String} name - property name
     * @return {Array}
     * @private
     */
    function _getArray(data, name) {
      if (data === undefined || data === null || data === 'null') {
        return [];
      }

      if (data[name] === undefined || data[name] === null || data[name] === 'null') {
        return [];
      }

      if (!Array.isArray(data[name])) return [data[name]];
      else return data[name];
    }

    /**
     *
     * @param {String} token
     * @private
     */
    function _setAccessToken(token) {
      gApiKey = token;
      gAuthorization = 'Bearer ' + gApiKey;
      gDefaultHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Authorization: gAuthorization,
      };
    }

    return {
      getLiveCandleData: getLiveCandleData,
      getLastCandlesData: getLastCandlesData,
      getEntryPrice: getEntryPrice,

      initialize: initialize,

      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getAccessData: getAccessData,
    };
  }
})();
