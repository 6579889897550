/**
 * Created by Sergey Panpurin on 12/17/2020.
 */

// @ts-check
(function btLinkDataServiceApiServiceClosure() {
  'use strict';

  angular
    .module('ecapp')
    /**
     * This factory works with LinkDataService API.
     *
     * @ngdoc service
     * @name btLinkDataServiceApiService
     * @memberOf ecapp
     * @param {angular.IQService} $q - promise interface
     * @param {angular.IHttpService} $http - service that facilitates communication with the remote HTTP servers
     */
    .factory('btLinkDataServiceApiService', btLinkDataServiceApiService);

  btLinkDataServiceApiService.$inject = [
    '$q',
    '$http',
    '$interval',
    'btSettings',
    'btInstrumentsService',
    '$ionicPopup',
    'btOauthService',
    'btTemplateApiService',
    'btOandaApiService',
    'btMarketApiService',
    'UDFSymbol',
    'btShareScopeService',
    'btLinkDataService',
    '$ionicLoading',
    'LdsApiWrapper',
    'btToastrService',
  ];

  /**
   *
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IHttpService} $http
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.ISettingsService} btSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {ecapp.IOauthService} btOauthService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @param {ecapp.IOandaApiService} btOandaApiService
   * @param {ecapp.IMarketApiService} btMarketApiService
   * @param {ecapp.IGeneralLoopbackService} lbUDFSymbol
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.ILinkDataService} btLinkDataService
   * @param {ionic.ILoadingService} $ionicLoading
   * @param {ecapp.IGeneralLoopbackService} lbLdsApi
   * @param {ecapp.IToastrService} btToastrService
   * @return {ecapp.ILinkDataServiceApiService}
   */
  function btLinkDataServiceApiService(
    $q,
    $http,
    $interval,
    btSettings,
    btInstrumentsService,
    $ionicPopup,
    btOauthService,
    btTemplateApiService,
    btOandaApiService,
    btMarketApiService,
    lbUDFSymbol,
    btShareScopeService,
    btLinkDataService,
    $ionicLoading,
    lbLdsApi,
    btToastrService
  ) {
    console.log('Running btLinkDataServiceApiService');

    var DEBUG = false;
    var NOT_IMPLEMENTED = 'Not implemented';
    var PREFIX = 'btLinkDataServiceApiService:';

    /**
     * User access data
     * @type {{defaultAccount?: string, mode?: string, token?: string}}
     */
    var gUserData = {};

    /**
     * Is logged in
     * @type {boolean}
     */
    var gIsLoggedIn = false;

    var gLdsSymbols = btLinkDataService.Grades.map(function (value) {
      return value.name;
    });

    var gLdsLiveCandleCache = {};
    var LDS_TTL = 60 * 1000;
    var gInitPromise = null;
    var PRECISION = 2;

    return {
      // getAllInstruments: getAllInstruments,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,
      getLastCandlesData: getLastCandlesData,
      getEntryPrice: getEntryPrice,
      getCandles: getCandles,

      // trading api
      initialize: initialize,

      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,

      getAccessData: getAccessData,

      createInstrument: createInstrument,
      requestLdsAccess: requestLdsAccess,
    };

    /**
     *
     * @return {*}
     */
    function preloadGrades() {
      if (!gInitPromise) {
        // gInitPromise = lbLdsApi.getGrades().$promise.then(updatePreloadedGrades);
        gInitPromise = $q.resolve({});
      }

      return gInitPromise;
    }

    /**
     *
     * @param {*} ticker -
     * @return {boolean}
     */
    function isLdsSymbol(ticker) {
      var symbol = ticker.split(':')[0];
      var isLDS = gLdsSymbols.indexOf(symbol) > -1;
      return isLDS;
    }

    /**
     *
     * @param {*} symbol
     * @param {*} granularity
     * @param {*} period
     * @return {*}
     */
    function getCandles(symbol, granularity, period) {
      console.log(symbol, granularity, period);
      return $q.reject(new Error('Not Supported'));
    }

    /**
     * Get one instrument last prices. Wrapper.
     *
     * @param {string} symbol - instrument name known by OANDA API
     * @return {angular.IPromise<Object>}
     */
    function getLiveCandleData(symbol) {
      if (isLdsSymbol(symbol)) {
        return getLiveCandleDataLDS(symbol);
      } else {
        return btMarketApiService.getLiveCandleData(symbol);
      }
    }

    /**
     *
     * @param {*} symbols
     * @return {*}
     */
    function getLiveCandlesData(symbols) {
      var s1 = [];
      var s2 = [];

      symbols.forEach(function (symbol) {
        if (isLdsSymbol(symbol)) {
          s1.push(symbol);
        } else {
          s2.push(symbol);
        }
      });

      var promise1 = /** @type {angular.IPromise} */ (
        s1.length ? $q.all(s1.map(getLiveCandleDataLDS)) : $q.resolve([])
      );
      var promise2 = /** @type {angular.IPromise} */ (
        s2.length ? btMarketApiService.getLiveCandlesData(s2) : $q.resolve([])
      );

      return $q.all([promise1, promise2]).then(function (results) {
        return symbols.map(function (symbol) {
          return (isLdsSymbol(symbol) ? results[0] : results[1]).filter(function (result) {
            return result.symbol === symbol;
          })[0];
        });
      });
    }

    /**
     * ???
     *
     * @param {string} symbol - instrument name known by LDS API
     * @return {angular.IPromise<Object>}
     */
    function getLiveCandleDataLDS(symbol) {
      var to = Math.floor(Date.now() / 1000);
      var from = to - 7 * 24 * 3600;

      var result = _getCachedLiveCandleDataLDS(symbol, to);

      if (result) {
        return result;
      } else {
        var options = { symbol: 'LDS:' + symbol, from: from, to: to, resolution: '1D' };
        var promise = lbUDFSymbol.history(options).$promise.then(function (response) {
          var result = _convertLDSResponse2LiveCandle(response, symbol);
          return result;
        });

        _saveCachedLiveCandleDataLDS(symbol, to, promise);
        return promise;
      }
    }

    /**
     *
     * @param {*} symbol
     * @param {*} date
     * @return {*}
     */
    function _getCachedLiveCandleDataLDS(symbol, date) {
      if (gLdsLiveCandleCache[symbol]) {
        if (date < gLdsLiveCandleCache[symbol].expired) {
          return gLdsLiveCandleCache[symbol].value;
        } else {
          delete gLdsLiveCandleCache[symbol];
          return null;
        }
      } else {
        return null;
      }
    }

    /**
     *
     * @param {*} symbol
     * @param {*} date
     * @param {*} value
     */
    function _saveCachedLiveCandleDataLDS(symbol, date, value) {
      gLdsLiveCandleCache[symbol] = {
        value: value,
        expired: date + LDS_TTL,
      };
    }

    /**
     *
     * @param {*} res
     * @param {*} symbol
     * @return {*}
     */
    function _convertLDSResponse2LiveCandle(res, symbol) {
      if (res.s === 'ok') {
        var last = res.c.length - 1;
        var prev = last > 0 ? last - 1 : last;

        return {
          symbol: symbol,
          time: Date.now(),
          yesterday: {
            close: res.c[prev],
            closeText: price(res.c[prev]),
          },
          today: {
            low: res.c[last],
            lowText: price(res.c[last]),
            high: res.c[last],
            highText: price(res.c[last]),
            open: res.c[last],
            openText: price(res.c[last]),
          },
          now: {
            bid: res.c[last],
            bidText: price(res.c[last]),
            ask: res.c[last],
            askText: price(res.c[last]),
            last: res.c[last],
            lastText: price(res.c[last]),
          },
        };
      } else {
        return {
          symbol: symbol,
          time: Date.now(),
          yesterday: {
            close: 0,
            closeText: 'N/A',
          },
          today: {
            low: 0,
            lowText: 'N/A',
            high: 0,
            highText: 'N/A',
            open: 0,
            openText: 'N/A',
          },
          now: {
            bid: 0,
            bidText: 'N/A',
            ask: 0,
            askText: 'N/A',
            last: 0,
            lastText: 'N/A',
          },
        };
      }
    }

    /**
     *
     * @param {number | undefined} price - ?
     * @return {string | undefined}
     */
    function price(price) {
      if (price === undefined) {
        return undefined;
      }

      return price.toFixed(PRECISION);
    }
    /**
     * Get one instrument n Candles with frequency defined by granularity parameter. Wrapper.
     *
     * @param {string} symbol - instrument name known by oanda API
     * @param {string} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<Object>}
     */
    function getLastCandlesData(symbol, granularity, count) {
      if (isLdsSymbol(symbol)) {
        return getLastCandlesDataLDS(symbol, granularity, count);
      } else {
        return btMarketApiService.getLastCandlesData(symbol, granularity, count);
      }
    }

    /**
     * ???
     *
     * @param {string} instrument - instrument name known by oanda API
     * @param {string} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<Object>}
     */
    function getLastCandlesDataLDS(instrument, granularity, count) {
      void granularity;
      void count;
      var to = Math.floor(Date.now() / 1000);
      var from = to - 21 * 24 * 3600;

      return lbUDFSymbol
        .history({ symbol: 'LDS:' + instrument, from: from, to: to, resolution: '1D' })
        .$promise.then(function (value) {
          // console.log('(Test)', instrument, value);
          if (value.s === 'ok') {
            var candles = [];

            for (var i = 0; i < value.c.length; i++) {
              candles.push({
                complete: true,
                mid: {
                  c: value.c[i],
                  h: value.c[i],
                  l: value.c[i],
                  o: value.c[i],
                },
                time: value.t[i],
                volume: 1,
              });
            }

            return { candles: candles };
          } else {
            return { candles: [] };
          }
        });
    }

    /**
     * Get entry price for trade card. Wrapper.
     *
     * @param {string} instrument - instrument name known by oanda API
     * @param {number} time - timestamp in seconds
     * @param {number} minAfter - number of minutes after selected moment
     * @return {angular.IPromise<Object>}
     */
    function getEntryPrice(instrument, time, minAfter) {
      if (isLdsSymbol(instrument)) {
        return $q.resolve({ candles: [] });
      } else {
        minAfter = minAfter || 2;
        return btMarketApiService.getEntryPrice(instrument, time, minAfter);
      }
    }

    /* --- Public functions --- */

    /**
     * Initializes service.
     *
     * @param {object} data - access data
     * @return {angular.IPromise<Object>}
     */
    function initialize(data) {
      if (data === undefined || data === null) {
        return $q.reject(new Error('Bad access data'));
      } else {
        gUserData = data;
        return preloadGrades();
      }
    }

    /**
     * Connects broker.
     *
     * @return {angular.IPromise<Object>}
     */
    function connect() {
      var deferred = $q.defer();
      console.log('btOandaApiService: connect');

      if (gUserData.token !== undefined) {
        if (gIsLoggedIn) {
          deferred.resolve({});
        } else {
          setTradingMode(gUserData.mode);
          gIsLoggedIn = true;
          deferred.resolve({});
        }
      } else {
        gIsLoggedIn = false;
        deferred.reject(new Error('Is not initialized'));
      }
      return deferred.promise;
    }

    /**
     * Disconnects broker.
     *
     * @return {angular.IPromise<Object>}
     */
    function disconnect() {
      console.log('btOandaApiService: disconnect');
      return logout();
    }

    /**
     * Login Broker
     *
     * @param {string} mode - trading mode: real ot demo
     * @param {boolean} isForceLogin - force login or not
     * @return {angular.IPromise<Object>}
     */
    function login(mode, isForceLogin) {
      void isForceLogin;

      return $q.reject(new Error('Disabled'));
    }

    /**
     * Fast Login Broker
     *
     * @param {string} mode - trading mode: real ot demo
     * @param {{sso_token: String}} data - access data
     * @return {angular.IPromise<Object>}
     */
    function fastLogin(mode, data) {
      void mode;
      void data;
      return $q.reject(new Error('Disabled'));
    }

    /**
     * Logout Broker
     *
     * @return {angular.IPromise<Object>}
     */
    function logout() {
      var deferred = $q.defer();
      console.log('btOandaApiService: logout');
      gUserData = {};
      gIsLoggedIn = false;
      deferred.resolve({});
      return deferred.promise;
    }

    /**
     * Check user data (now just username)
     *
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>}
     */
    function checkUser(userData) {
      void userData;
      return $q.reject(new Error('Disabled'));
    }

    /**
     * Create username from email
     *
     * @param {string} email - user email as feed to create username
     * @return {angular.IPromise<*>}
     */
    function getUsername(email) {
      void email;
      return $q.reject(new Error('Disabled'));
    }

    /**
     * Create new OANDA account via API
     *
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<*>|angular.IPromise<*>}
     */
    function signUp(userData) {
      void userData;
      return $q.reject(new Error('Disabled'));
    }

    /**
     * Is user logged in to TradeStation API
     *
     * @return {boolean}
     */
    function isLoggedIn() {
      return gUserData.token !== undefined;
    }

    /**
     * Get trading mode: live or practice
     *
     * @return {string} - trading mode: "demo" or "real"
     */
    function getTradingMode() {
      return gUserData.mode;
    }

    /**
     * Set trading mode. Return true on success.
     *
     * @param {string} mode
     * @return {boolean} - trading mode was changed
     */
    function setTradingMode(mode) {
      gUserData.mode = mode;
      return true;
    }

    /* --- User data --- */
    /**
     * Get list of user accounts for selected broker
     *
     * @return {angular.IPromise<Array>} - list of accounts
     */
    function getAccounts() {
      return $q.resolve([]);
    }

    /**
     * Get balance for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getBalances(accountIds) {
      void accountIds;
      return $q.resolve([]);
    }

    /**
     * Get all positions for accounts
     *
     * @param {Array} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getPositions(accountIds) {
      void accountIds;
      return $q.resolve([]);
    }

    /**
     * Get all orders for accounts
     *
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<Array>}
     */
    function getOrders(accountIds) {
      void accountIds;
      return $q.resolve([]);
    }

    /* --- Market Data --- */
    /**
     * Get information about selected symbol
     *
     * @param {string} symbol - symbol name
     * @return {angular.IPromise<ecapp.ITradingInstrument>}
     */
    function getSymbolInfo(symbol) {
      const instrument = createInstrument(symbol);
      return $q.resolve(instrument);
    }

    /**
     * Search symbol using query string
     *
     * @param {string} query - symbol name
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function searchSymbol(query) {
      void query;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Searches symbols using query string
     *
     * @param {string} query - search text
     * @param {number} limit - limit number of results
     * @param {object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestSymbols(query, limit, params) {
      var promises = [suggestLdsSymbols(query, params), btMarketApiService.suggestSymbols(query, limit, params)];

      return $q.all(promises).then(function (results) {
        return [].concat(results[0], results[1]).slice(0, limit);
      });
    }

    /**
     * Searches symbols using query string
     *
     * @param {string} query - search text
     * @param {object} params - additional parameters
     * @return {angular.IPromise<Array>} - list of symbol names
     */
    function suggestLdsSymbols(query, params) {
      void params;
      var results = btLinkDataService.Grades
        .filter(function (value) {
          return value.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        .map(function (item) {
          return {
            name: item.name,
            desc: item.desc,
            rawData: item,
          };
        });

      return $q.resolve(results);
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {string[]} symbols -
     * @return {angular.IPromise<ecapp.ITradingQuoteResponse>}
     */
    function getQuotes(symbols) {
      void symbols;
      return $q.resolve({
        prices: [],
        time: Math.floor(Date.now() / 1000),
      });
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      void symbol;
      void onProgress;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {string[]} symbols
     * @param {object} range
     * @param {number} interval -
     * @param {string} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      void symbols;
      void range;
      void interval;
      void unit;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      if (DEBUG) console.log(PREFIX, 'streamSnapshot', ticker, granularity, back, onUpdate);
      return $q.reject(new Error(NOT_IMPLEMENTED));
    }

    /* --- Order execution --- */
    /**
     * Confirm order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      void order;
      var deferred = $q.defer();
      deferred.reject(new Error('Coming soon!'));
      return deferred.promise;
    }

    /**
     * Submit order
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      void order;
      return $q.reject(new Error('Coming soon!'));
    }

    /**
     * Update order
     *
     * @param {string} orderId - order id
     * @param {object} orderChanges - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderChanges) {
      void orderId;
      void orderChanges;
      return $q.reject(new Error('Coming soon!'));
    }

    /**
     * Cancel order
     *
     * @param {string} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      void orderId;
      return $q.reject(new Error('Coming soon!'));
    }

    /**
     * Select account
     *
     * @param {string} id - account id
     * @return {?String} id of selected account or null
     */
    function selectAccount(id) {
      void id;
      return null;
    }

    /**
     *
     * @return {*}
     */
    function getSelectedAccountId() {
      return undefined;
    }

    /**
     *
     * @param {*} id
     * @return {*}
     */
    function isAccountSelected(id) {
      void id;
      return false;
    }

    /**
     *
     * @return {*}
     */
    function getAccessData() {
      var deferred = $q.defer();

      deferred.resolve(gUserData);

      return deferred.promise;
    }

    /**
     *
     * @param {*} symbol
     * @return {*}
     */
    function createInstrument(symbol) {
      if (isLdsSymbol(symbol)) {
        var grade = btLinkDataService.getGradeByName(symbol);
        var accessLevel = btShareScopeService.getAccessLevel('grade-' + grade.id);
        return btInstrumentsService.createBrokerInstrument(
          'default',
          symbol,
          'lds',
          'Description',
          false,
          grade,
          accessLevel
        );
      } else {
        return btInstrumentsService.createBrokerInstrument('default', symbol, 'oanda', 'Description', false);
      }
    }

    /**
     * Requests LDS access.
     *
     * @param {{id: number, name: string}} symbol - symbol
     * @param {string} level - level
     */
    function requestLdsAccess(symbol, level) {
      const levelLabel = level === 'price' ? 'Price data' : 'Transactional data';
      const confirmPopup = $ionicPopup.confirm({
        title: 'Request Access',
        template: `Do you like to request access for ${symbol.name} - ${levelLabel}?`,
      });
      confirmPopup.then(function (res) {
        if (res) {
          $ionicLoading.show();
          lbLdsApi
            .requestAccess({ grade: symbol.id, symbol: symbol.name, level })
            .$promise.then(function () {
              btToastrService.info('Access Requested', symbol.name, { type: 'market' });
              $ionicLoading.hide();
            })
            .catch(function (reason) {
              console.error(reason);
              btToastrService.error('Can not request access', symbol.name, { type: 'market' });
              $ionicLoading.hide();
            });
        }
      });
    }
  }
})();
