/**
 * Created by Itay on 6/2/2016.
 */

// @ts-check
(function btDateServiceClosure() {
  'use strict';

  angular.module('btUtils').factory('btDateService', btDateService);

  btDateService.$inject = ['moment', 'btErrorService'];

  /**
   * This factory helps to works with dates.
   *
   * @ngdoc service
   * @name btDateService
   * @memberOf btUtils
   * @param {ext.IMomentService} moment - ?
   * @param {ecapp.IErrorService} $btError - ?
   * @return {ecapp.IDateService}
   */
  function btDateService(moment, $btError) {
    var BT_ONE_DAY_MS = 86400000;
    // var BT_ONE_HOUR_MS = 3600000;
    var BT_ONE_MIN_MS = 60000;

    // var BT_ONE_DAY_S = 86400;

    // var BT_FREE_DELAY = 3 * 24 * 60 * 60 * 1000; // testing
    var BT_FREE_DELAY = 120 * 60 * 1000; // 2 hours

    var gTimeShifting = false;
    var gFirstCallDate = null;
    var gTestingDate = new Date('2018-06-04T13:54:00.000Z');

    moment.locale('bt-time-lang', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a sec',
        ss: '%d sec',
        m: '1 min',
        mm: '%d min',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    });

    moment.relativeTimeThreshold('s', 59);
    moment.relativeTimeThreshold('ss', 10);
    moment.relativeTimeThreshold('m', 45);
    moment.relativeTimeThreshold('h', 22);
    moment.relativeTimeThreshold('d', 26);
    moment.relativeTimeThreshold('M', 11);

    var gUserTZ = 'America/New_York';

    var gDefaultTimezone = 'America/New_York';

    /** @type {btTimeZoneOption[]} */
    var gMomentTimeZones = [];

    /**
     * This is a list of time zones supported by TradingView.
     * @type {{name:String, text: String}[]}
     */
    var gTradingViewTimeZoneTemplates = [
      // TradingView support exchange option to use time zone of exchange. We skip this option due to we want
      // to use it like a user profile time zone.
      // {name: 'exchange', text: 'Exchange'},
      { name: 'Etc/UTC', text: 'UTC' },
      { name: 'Pacific/Honolulu', text: 'Honolulu' },
      { name: 'America/Juneau', text: 'Juneau' },
      { name: 'America/Los_Angeles', text: 'Los Angeles' },
      { name: 'America/Phoenix', text: 'Phoenix' },
      { name: 'America/Vancouver', text: 'Vancouver' },
      { name: 'America/El_Salvador', text: 'San Salvador' },
      { name: 'America/Bogota', text: 'Bogota' },
      { name: 'America/Chicago', text: 'Chicago' },
      { name: 'America/Lima', text: 'Lima' },
      { name: 'America/Mexico_City', text: 'Mexico City' },
      { name: 'America/Caracas', text: 'Caracas' },
      { name: 'America/New_York', text: 'New York' },
      { name: 'America/Santiago', text: 'Santiago' },
      { name: 'America/Toronto', text: 'Toronto' },
      { name: 'America/Argentina/Buenos_Aires', text: 'Buenos Aires' },
      { name: 'America/Sao_Paulo', text: 'Sao Paulo' },
      { name: 'Atlantic/Reykjavik', text: 'Reykjavik' },
      { name: 'Africa/Lagos', text: 'Lagos' },
      { name: 'Europe/London', text: 'London' },
      { name: 'Europe/Belgrade', text: 'Belgrade' },
      { name: 'Europe/Berlin', text: 'Berlin' },
      { name: 'Africa/Cairo', text: 'Cairo' },
      { name: 'Europe/Copenhagen', text: 'Copenhagen' },
      { name: 'Africa/Johannesburg', text: 'Johannesburg' },
      { name: 'Europe/Luxembourg', text: 'Luxembourg' },
      { name: 'Europe/Madrid', text: 'Madrid' },
      { name: 'Europe/Paris', text: 'Paris' },
      { name: 'Europe/Rome', text: 'Rome' },
      { name: 'Europe/Stockholm', text: 'Stockholm' },
      { name: 'Europe/Warsaw', text: 'Warsaw' },
      { name: 'Europe/Zurich', text: 'Zurich' },
      { name: 'Europe/Athens', text: 'Athens' },
      { name: 'Asia/Bahrain', text: 'Bahrain' },
      { name: 'Europe/Helsinki', text: 'Helsinki' },
      { name: 'Europe/Istanbul', text: 'Istanbul' },
      { name: 'Asia/Jerusalem', text: 'Jerusalem' },
      { name: 'Asia/Kuwait', text: 'Kuwait' },
      { name: 'Europe/Moscow', text: 'Moscow' },
      { name: 'Asia/Qatar', text: 'Qatar' },
      { name: 'Europe/Riga', text: 'Riga' },
      { name: 'Asia/Riyadh', text: 'Riyadh' },
      { name: 'Europe/Tallinn', text: 'Tallinn' },
      { name: 'Europe/Vilnius', text: 'Vilnius' },
      { name: 'Asia/Dubai', text: 'Dubai' },
      { name: 'Asia/Muscat', text: 'Muscat' },
      { name: 'Asia/Tehran', text: 'Tehran' },
      { name: 'Asia/Ashkhabad', text: 'Ashkhabad' },
      { name: 'Asia/Kolkata', text: 'Kolkata' },
      { name: 'Asia/Almaty', text: 'Almaty' },
      { name: 'Asia/Bangkok', text: 'Bangkok' },
      { name: 'Asia/Ho_Chi_Minh', text: 'Ho Chi Minh' },
      { name: 'Asia/Jakarta', text: 'Jakarta' },
      { name: 'Asia/Chongqing', text: 'Chongqing' },
      { name: 'Asia/Hong_Kong', text: 'Hong Kong' },
      { name: 'Asia/Shanghai', text: 'Shanghai' },
      { name: 'Asia/Singapore', text: 'Singapore' },
      { name: 'Asia/Taipei', text: 'Taipei' },
      { name: 'Asia/Seoul', text: 'Seoul' },
      { name: 'Asia/Tokyo', text: 'Tokyo' },
      { name: 'Australia/Adelaide', text: 'Adelaide' },
      { name: 'Australia/Brisbane', text: 'Brisbane' },
      { name: 'Australia/Sydney', text: 'Sydney' },
      { name: 'Pacific/Norfolk', text: 'Norfolk Island' },
      { name: 'Pacific/Auckland', text: 'New Zealand' },
      { name: 'Pacific/Chatham', text: 'Chatham Islands' },
      { name: 'Pacific/Fakaofo', text: 'Tokelau' },
    ];

    /**
     * This is a list of time zones using in our system
     * @type {btTimeZoneOption[]}
     */
    var gTimeZones = [];

    /**
     * This function initializes user time zone.
     *
     * @param {string} tz - name of user time zone
     */
    function setUserTimeZone(tz) {
      gUserTZ = tz;
    }

    /**
     * This function returns user time zone.
     *
     * @return {string} - user time zone
     */
    function getUserTimeZone() {
      return gUserTZ;
    }

    /**
     * This function return default time zone.
     *
     * @return {string} - default time zone
     */
    function getDefaultTimeZone() {
      return gDefaultTimezone;
    }

    /**
     * This function returns offset of user time zone as a string in format '+0430'.
     *
     * @return {string} - offset of user time zone as a string
     */
    function getUserTimeZoneOffsetString() {
      try {
        var offset = getTimezoneOffset(gUserTZ);
        var hours = Math.abs(Math.floor(offset / 60));
        var minutes = offset % 60;

        var text =
          (offset > 0 ? '+' : '-') +
          (hours >= 10 ? hours : '0' + hours) +
          ':' +
          (minutes >= 10 ? minutes : '0' + minutes);

        return typeof text === 'string' ? text : '+00:00';
      } catch (e) {
        console.error(e);
        return '+00:00';
      }
    }

    /**
     * This function returns offset of user time zone in minutes.
     *
     * @return {number} - offset of user time zone in minutes
     */
    function getUserTimeZoneOffset() {
      return getTimezoneOffset(gUserTZ);
    }

    /**
     * This function returns name of user time zone.
     *
     * @return {string} - name of time zone
     */
    function getUserTimerZoneName() {
      return moment().tz(gUserTZ).zoneName();
    }

    /**
     * This function gets a row and return date of the time this row occur
     *
     * @param {object} row - ?
     * @return {Date}
     */
    function getDateFromRow(row) {
      return new Date(row.time * 1000.0);
    }

    /**
     * This function gets a row and return date of the time this row occur
     *
     * @param {object} row
     * @return {Date}
     */
    function getDateFromRowMinusDay(row) {
      return new Date(row.time * 1000.0 - BT_ONE_DAY_MS);
    }

    /**
     * This function gets a time in seconds and return date object
     *
     * @param {number} time -?
     * @return {Date}
     */
    function getDateFromSec(time) {
      return new Date(time * 1000.0);
    }

    /**
     *
     * @param {number} minutes
     * @return {Date}
     */
    function getMinutesFromNowDate(minutes) {
      //assign date to now
      var date = getNowDate();

      date.setMinutes(date.getMinutes() + minutes);
      return date;
    }

    /**
     * Add minutes to date
     * @param {Date} date - date object
     * @param {number} minutes - number of minutes
     * @return {Date}
     */
    function addMinutesFromDate(date, minutes) {
      var newDate = new Date(date.valueOf());
      newDate.setMinutes(newDate.getMinutes() + minutes);
      return newDate;
    }

    /**
     * This function returns current date.
     *
     * @return {Date}
     */
    function getNowDate() {
      if (!gTimeShifting) {
        return new Date();
      } else {
        // Time shifting
        if (gFirstCallDate === null) {
          gFirstCallDate = new Date();
        }

        return new Date(gTestingDate.getTime() + (new Date().getTime() - gFirstCallDate));
      }
    }

    /**
     * This function compares two dates.
     *
     * @param {Date} d1 - first date
     * @param {Date} d2 - seconds date
     * @return {boolean} - whether two Dates have the same date info
     */
    function sameDay(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() && d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth();
    }

    /**
     * For 29 February will return 1 March
     *
     *        var test = new Date(2016, 1, 29, 15, 35);
     *        console.log("Test nYearsAgo", test, nYearsAgo(test, 4));
     *
     * @param {Date|Number} date
     * @param {number} n
     * @return {Date}
     */
    function nYearsAgo(date, n) {
      var newDate = new Date(date);
      newDate.setFullYear(newDate.getFullYear() - n);
      return newDate;
    }

    // /**
    //  *
    //  * @param {Date} date
    //  * @param {number} n
    //  * @return {Date}
    //  */
    // function nMonthsAgo(date, n) {
    //   var newDate = new Date(date);
    //   newDate.setMonth(newDate.getMonth() - n);
    //   return newDate;
    // }

    /**
     *
     * @param {Date} date
     * @param {number} n
     * @return {Date}
     */
    function nDaysAgo(date, n) {
      var newDate = new Date(date.getTime());
      newDate.setDate(newDate.getDate() - n);
      return newDate;
    }

    /**
     * This function tells how many days there are between two dates, in returns an rounded integer of the amount of
     * the day (2.7 -> 3, 2.3 -> 2)
     * @param {Date} date1
     * @param {Date} date2
     * @return {number}
     */
    function daysBetween(date1, date2) {
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      var difference_ms = Math.abs(date1_ms - date2_ms);
      return Math.round(difference_ms / BT_ONE_DAY_MS);
    }

    /**
     *
     * @param {Date} date1
     * @param {Date} date2
     * @return {number}
     */
    function getDifferenceInMinutes(date1, date2) {
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      var difference_ms = Math.abs(date1_ms - date2_ms);

      return Math.round(difference_ms / BT_ONE_MIN_MS);
    }

    // /**
    //  *
    //  * @param {object} row
    //  * @return {string}
    //  */
    // function getMonthYearFromRow(row) {
    //   return moment(getDateFromRow(row)).format('MMM YY');
    // }

    /**
     *
     * @param {btRelease} row - (const) release data
     * @return {string[]}
     */
    function getUTCArrayFromRow(row) {
      return moment.utc(getDateFromRow(row)).format('YYYY MM DD HH mm').split(' ');
    }

    /**
     *
     * @param {String[]} UTCArray - (const) [year, month, day, hours, minutes]
     * @return {number}
     */
    function getUnixFromUTCArray(UTCArray) {
      return moment.utc(UTCArray.join(' '), 'YYYY MM DD HH mm').unix();
    }

    /**
     *
     * @param {*} row - ?
     * @return {string}
     */
    function getFormattedDateFromRow(row) {
      return moment(getDateFromRow(row)).tz(gUserTZ).format('D MMM YY');
    }

    // function getFullFormattedDateFromRow(row) {
    //   getFullFormattedDate(getDateFromRow(row))
    // }

    /**
     *
     * @param {*} date - ?
     * @return {*}
     */
    function getFullFormattedDate(date) {
      return moment(date).tz(gUserTZ).format('DD MMM YYYY');
    }

    // function isRowFuture(row) {
    //   var rowTime = getDateFromRow(row).getTime();
    //   var nowTime = getNowDate().getTime();
    //
    //   return rowTime > nowTime;
    // }

    /**
     *
     * @param {*} row - ?
     * @return {*}
     */
    function isRowInNextHot(row) {
      var rowDate = getDateFromRow(row);
      var rowTime = rowDate.getTime();
      var nowTime = getNowDate().getTime();

      var futureHotLimitTime = addMinutesFromDate(rowDate, -5).getTime();

      console.log('rowTime', rowTime);
      console.log('nowTime', nowTime);
      console.log('futureHotLimitTime', futureHotLimitTime);

      console.log(
        'nowTime <= rowTime && nowTime > futureHotLimitTime',
        nowTime <= rowTime && nowTime > futureHotLimitTime
      );

      return nowTime <= rowTime && nowTime > futureHotLimitTime;
    }

    // function isRowInNext(row) {
    //   return row;
    // }

    /**
     *
     * @param {*} row - ?
     * @return {*}
     */
    function isRowInPast(row) {
      var rowTime = getDateFromRow(row).getTime();
      var futureHotLimitTime = getMinutesFromNowDate(-5).getTime();

      return rowTime < futureHotLimitTime;
    }

    /**
     *
     * @param {*} row - ?
     * @return {*}
     */
    function isRowInJustReleased(row) {
      var rowDate = getDateFromRow(row);
      var rowTime = rowDate.getTime();
      var justReleasedLimitTime = addMinutesFromDate(rowDate, 5).getTime();
      var nowTime = getNowDate().getTime();

      return nowTime <= justReleasedLimitTime && nowTime > rowTime;
    }

    /**
     *  This function gets date and returns the minutes and seconds from now in that moment format
     *
     * @param {*} date - ?
     * @param {object} params - skipZero and skipConvert
     * @return {*} - moment.js object
     */
    function getMinutesSecondFromNow(date, params) {
      params = params || {};
      var now = getNowDate();

      var difference = moment.duration(moment(date).diff(now));
      if (0 < difference.asMilliseconds() || params.skipZero) {
        if (params.skipConvert) return difference;
        else return moment.utc(difference.asMilliseconds());
      } else {
        if (params.skipConvert) return moment.duration(0);
        else return moment.utc(0);
      }
    }

    /**
     *
     * @alias btUtils.btDateService#getHumanisedTimeFromNow
     * @param {Date|Number|String} date - moment compatible date
     * @param {boolean} [withSuffix] - with suffix
     * @return {string}
     */
    function getHumanisedTimeFromNow(date, withSuffix) {
      withSuffix = withSuffix || false;
      var now = getNowDate();

      var difference = moment.duration(moment(date).diff(now));

      return difference.locale('bt-time-lang').humanize(withSuffix);
    }

    /**
     *
     * @param {*} date - ?
     * @return {boolean}
     */
    function isInFuture(date) {
      var now = getNowDate();

      var difference = moment.duration(moment(date).diff(now));
      return difference.asMilliseconds() > 0;
    }

    /**
     *
     * @param {Number|Date} date - moment compatible date
     * @return {string}
     */
    function getClockText(date) {
      return moment(date).tz(gUserTZ).format('HH:mm');
    }

    /**
     *
     * @param {*} date - ?
     * @return {*}
     */
    function getDateText(date) {
      return moment(date).tz(gUserTZ).format('MMM DD');
    }

    /**
     *
     * @param {*} date - ?
     * @return {*}
     */
    function getTimerText(date) {
      return moment(date).tz(gUserTZ).format('HH:mm');
    }

    /**
     *
     * @param {*} c - ?
     * @return {*}
     */
    function deltaMinMS(c) {
      return c * BT_ONE_MIN_MS;
    }

    // function deltaDayS(c) {
    //   return c * BT_ONE_DAY_S;
    // }

    /**
     * This function return time range in days. Set number of days before today and after today. Parameters (0,1) return
     * only today time range.
     *
     * @param {number} before - number of hours before today
     * @param {number} after - number of hours after today
     * @return {Array}
     */
    function getDayRange(before, after) {
      // offset date for work at sunday (debug feature)
      var offsetDate = parseInt(window.localStorage.getItem('offsetDate') || '0') || 0;

      var from, till;
      // var now = getNowDate();
      // var year = getCurrentYear();
      // var month = getCurrentMonth();
      // var day = getCurrentDate();
      // date range like js objects
      var today = moment(getNowDate()).tz(gUserTZ).startOf('date');
      from = today.clone().subtract({ days: offsetDate }).subtract({ hours: before }).toDate();
      till = today.clone().subtract({ days: offsetDate }).add({ hours: after }).subtract({ seconds: 1 }).toDate();
      // if (offsetDate !== 0) {
      //   // if (after === 0) {
      //   //   till = new Date(getNowDate() - offsetDate * 86400 * 1000);
      //   // } else {
      //   //   till = new Date(year, month, day + after - offsetDate, 0, 0, 0, 0);
      //   // }
      // } else {
      //   from = new Date(year, month, day - before, 0, 0, 0, 0);
      //   if (after === 0) {
      //     till = getNowDate();
      //   } else {
      //     till = new Date(year, month, day + after, 0, 0, 0, 0);
      //   }
      // }

      // console.log('TEST: ', today.toDate(), ' - ', from, ' - ', till);

      // date range in epoch time format
      var fromFormat = Math.round(from.getTime() / 1000);
      var tillFormat = Math.round(till.getTime() / 1000);

      // console.log('TEST: ', fromFormat, tillFormat);

      return [from, till, fromFormat, tillFormat, offsetDate];
    }

    /**
     * This function return name of day yesterday, today, tomorrow or DD MMM YYYY
     *
     * @param {*} date - ?
     * @return {*}
     */
    function getDayName(date) {
      var now = getNowDate();
      var today = getFullFormattedDate(now);
      var yesterday = getFullFormattedDate(now.getTime() - BT_ONE_DAY_MS);
      var tomorrow = getFullFormattedDate(now.getTime() + BT_ONE_DAY_MS);
      // yesterday.setDate(yesterday.getDate() - 1);
      // var tomorrow = getNowDate();
      // tomorrow.setDate(tomorrow.getDate() + 1);

      var dateText = getFullFormattedDate(date);

      if (dateText === yesterday) {
        return 'Yesterday';
      } else if (dateText === today) {
        return 'Today';
      } else if (dateText === tomorrow) {
        return 'Tomorrow';
      } else {
        return dateText;
      }
    }

    /**
     * This function return calendar text range relative to time now
     *
     * @param {*} timeClass - Time class received from review processor service
     * @param {*} date - Date of the release
     * @return {string}
     */
    function convertTextTime(timeClass, date) {
      var str;
      var dayName = getDayName(date);
      if (dayName.toLowerCase() === 'today') {
        switch (timeClass) {
          case 'eventCardPast':
            str = 'Earlier<br> today <i class="ion-ios-checkmark-empty bt-icon-earlier-today"></i>';
            break;
          case 'eventCardJustAsExpected':
            str = 'Just released <i class="ion-ios-bolt"></i>';
            break;
          case 'eventCardJustPositive':
            str = 'Just released <i class="ion-ios-bolt"></i>';
            break;
          case 'eventCardJustNegative':
            str = 'Just released <i class="ion-ios-bolt"></i>';
            break;
          case 'eventCardNextHot':
            str = 'Coming up <i class="ion-ios-loop"></i>';
            break;
          case 'eventCardNext':
            str = 'Coming up <i class="ion-ios-loop"></i>';
            break;
          case 'eventCardFuture':
            str = 'Later<br> today';
            break;
        }
      } else {
        str = dayName;
      }
      return str;
    }

    /**
     * Calculate how much time data will be hided for free user.
     * @param {Date} releaseDate - release date
     * @param {number} [delay] - number of milliseconds to wait after release
     * @return {number} number of remaining milliseconds to wait
     */
    function getFreeDelay(releaseDate, delay) {
      var now = getNowDate();
      delay = delay || BT_FREE_DELAY;
      var dlt = now.getTime() - releaseDate.getTime();

      if (dlt > delay) {
        return 0;
      } else {
        return delay - dlt + 10 * 1000;
      }
    }

    /**
     *
     * @param {*} date - ?
     * @param {*} n - ?
     * @return {number} timestamp in milliseconds
     */
    function addMinutes(date, n) {
      var t = new Date(date);
      return t.setMinutes(t.getMinutes() + n);
    }

    /**
     *
     * @param {*} format - ?
     * @return {*}
     */
    function getYesterday(format) {
      var yesterday = getNowDate();
      yesterday.setDate(yesterday.getDate() - 1);
      if (format) {
        return moment(yesterday).tz(gUserTZ).format(format);
      } else {
        return yesterday;
      }
    }

    /**
     *
     * @param {*} format - ?
     * @return {*}
     */
    function getTomorrow(format) {
      var tomorrow = getNowDate();
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (format) {
        return moment(tomorrow).tz(gUserTZ).format(format);
      } else {
        return tomorrow;
      }
    }

    /**
     *
     * @param {*} date - ?
     * @param {*} format - ?
     * @return {*}
     */
    function format(date, format) {
      return moment(date).tz(gUserTZ).format(format);
    }

    // function formatLocal(date, format) {
    //   return moment(date).format(format);
    // }

    /**
     *
     * @param {*} data - ?
     * @param {*} name - ?
     * @param {*} interval - ?
     * @param {*} callback - ?
     * @return {*}
     */
    function convertInterval(data, name, interval, callback) {
      var intervals = {
        M1: 60 * 1000,
        M5: 5 * 60 * 1000,
        M15: 15 * 60 * 1000,
        H1: 60 * 60 * 1000,
      };

      var newData = {};

      data.forEach(function (t) {
        var i = intervals[interval];
        var newTime = Math.floor(new Date(t[name]).getTime() / i) * i;
        if (newData[newTime] === undefined) {
          newData[newTime] = { items: [] };
        }

        newData[newTime].items.push(t);
      });

      var times = Object.keys(newData);
      data = times.map(function (t) {
        return callback(t, newData[t]);
      });

      return data;
    }

    /**
     * Get humanized difference between two dates
     *
     * @param {number} a - timestamp in milliseconds
     * @param {number} b - timestamp in milliseconds
     * @return {string}
     */
    function getHumanizedDifference(a, b) {
      var duration = moment.duration(moment(a).diff(moment(b)));
      if (duration.years() > 0) {
        return getPlural('year', 'years', duration.years()) + ' and ' + getPlural('month', 'months', duration.months());
      } else if (duration.asDays() > 99) {
        return getPlural('month', 'months', Math.round(duration.asMonths()));
      } else {
        return getPlural('day', 'days', Math.round(duration.asDays()));
      }
    }

    /**
     * Select correct word form
     *
     * @param {string} one - singular form
     * @param {string} many - plural form
     * @param {number} count - count (more than zero)
     * @return {string}
     */
    function getPlural(one, many, count) {
      if (count === 1) {
        return count + ' ' + one;
      } else {
        return count + ' ' + many;
      }
    }

    /**
     * Get humanized difference between two dates
     *
     * @param {number} a - timestamp in milliseconds
     * @param {number} b - timestamp in milliseconds
     * @return {number}
     */
    function getDifferenceInDays(a, b) {
      return moment(a).diff(moment(b), 'days');
    }

    /**
     * @typedef {object} btTimeZoneOption
     * @property {string} name - time zone name
     * @property {number} offset - offset from UTC in minutes
     * @property {string} hours - offset in hours
     * @property {string} label - time zone label
     * @property {string} [text] - original text of label without offset
     */

    /**
     * Read list of timezones from moment-timezones.
     * Creating list in scope for drop list element
     *
     * @alias btUtils.btDateService#getListOfTimeZones
     * @return {btTimeZoneOption[]}
     */
    function getMomentTimeZones() {
      if (getMomentTimeZones.length === 0) {
        gMomentTimeZones = moment.tz.names().map(function (value) {
          var now = moment().tz(value);
          return {
            name: value,
            offset: now.utcOffset(),
            hours: now.format('Z'),
            label: '(UTC' + now.format('Z') + ') ' + value,
          };
        });
      }

      return gMomentTimeZones;
    }

    /**
     * This function returns timezone offset
     * @param {string} timezone - name of timezone
     * @return {number}
     */
    function getTimezoneOffset(timezone) {
      return moment().tz(timezone).utcOffset();
    }

    /**
     * This function returns list of timezones supported by BetterTrader.
     * It is corresponded to TradingView time zones.
     *
     * @return {btTimeZoneOption[]}
     */
    function getSupportedTimeZones() {
      if (!gTimeZones.length) {
        var timezones = getMomentTimeZones();

        gTimeZones = gTradingViewTimeZoneTemplates.map(function (value, i) {
          return prepareTradingViewTimeZone(timezones, value, i);
        });
      }

      return gTimeZones;
    }

    /**
     * This function prepares trading view time zone option.
     *
     * @param {btTimeZoneOption[]} timezones - list of time zones supported by moment.js
     * @param {{name: string, text: string}} option - information about trading view time zone
     * @param {number} i - index of option
     * @return {btTimeZoneOption} - prepared time zone option
     */
    function prepareTradingViewTimeZone(timezones, option, i) {
      if (i < 1) {
        return { name: option.name, offset: 0, hours: '', label: '(UTC+00:00) ' + option.text };
      } else {
        var candidates = timezones.filter(function (timezone) {
          return timezone.name.toLocaleLowerCase().indexOf(option.name.toLocaleLowerCase()) !== -1;
        });

        if (candidates.length !== 1) {
          var msg = 'Timezone Issue - ' + candidates.length + ' timezones were found for ' + option.name + '.';
          console.error($btError.ClientError('TIMEZONE_ISSUE', msg));
        }

        if (candidates[0]) {
          return {
            name: option.name,
            offset: candidates[0].offset,
            hours: candidates[0].hours,
            label: '(UTC' + candidates[0].hours + ') ' + option.text,
          };
        } else {
          return { name: option.name, offset: 0, hours: '', label: option.text };
        }
      }
    }

    /**
     * This function suggests trading view time zone close to local time zone.
     *
     * @return {string}
     */
    function suggestTimeZone() {
      var offset = '(UTC' + moment().format('Z') + ')';
      var timezones = getSupportedTimeZones().filter(function (value) {
        return value.label.toLocaleLowerCase().indexOf(offset.toLocaleLowerCase()) === 0;
      });

      return timezones.length > 0 ? timezones[0].name : 'Etc/UTC';
    }

    /**
     *
     * @param {*} date - ?
     * @param {*} timezone - ?
     * @return {*}
     */
    function getDateStart(date, timezone) {
      if (timezone === 'utc') {
        return moment(date).utc().startOf('date');
      }

      if (timezone === 'local') {
        return moment(date).tz(gUserTZ).startOf('date');
      }

      return moment(date).tz(timezone).startOf('date');
    }

    /**
     *
     * @param {*} date - ?
     * @param {*} timezone - ?
     * @return {*}
     */
    function getDateEnd(date, timezone) {
      if (timezone === 'utc') {
        return moment(date).utc().endOf('date');
      }

      if (timezone === 'local') {
        return moment(date).local().endOf('date');
      }

      return moment(date).tz(timezone).endOf('date');
    }

    /**
     *
     * @return {*}
     */
    function getCurrentYear() {
      return moment(getNowDate()).tz(gUserTZ).year();
    }

    /**
     *
     * @return {*}
     */
    function getCurrentMonth() {
      return moment(getNowDate()).tz(gUserTZ).month();
    }

    /**
     *
     * @return {*}
     */
    // eslint-disable-next-line no-unused-vars
    function getCurrentDate() {
      return moment(getNowDate()).tz(gUserTZ).date();
    }

    /**
     *
     * @return {*}
     */
    function getCurrentDay() {
      return moment(getNowDate()).tz(gUserTZ).day();
    }

    /**
     *
     * @return {*}
     */
    function getCurrentHours() {
      return moment(getNowDate()).tz(gUserTZ).hours();
    }

    /**
     *
     * @param {*} date - ?
     * @return {*}
     */
    function getUserHours(date) {
      return moment(date).tz(gUserTZ).hours();
    }

    return {
      SECOND: 1000,
      MINUTE: 60000,
      HOUR: 3600000,
      DAY: 86400000,
      WEEK: 604800000,
      getNowDate: getNowDate,
      getYesterday: getYesterday,
      getTomorrow: getTomorrow,

      getDateFromRow: getDateFromRow,
      getDateFromRowMinusDay: getDateFromRowMinusDay,
      getDateFromSec: getDateFromSec,
      // getMonthYearFromRow: getMonthYearFromRow,
      getFormattedDateFromRow: getFormattedDateFromRow,
      // getFullFormattedDateFromRow: getFullFormattedDateFromRow,
      getFullFormattedDate: getFullFormattedDate,
      getMinutesFromNowDate: getMinutesFromNowDate,
      getMinutesSecondFromNow: getMinutesSecondFromNow,
      getDifferenceInMinutes: getDifferenceInMinutes,
      getHumanisedTimeFromNow: getHumanisedTimeFromNow,
      getClockText: getClockText,
      getDateText: getDateText,
      getTimerText: getTimerText,
      getDayRange: getDayRange,
      getDayName: getDayName,
      getFreeDelay: getFreeDelay,

      addMinutes: addMinutes,

      getUTCArrayFromRow: getUTCArrayFromRow,
      getUnixFromUTCArray: getUnixFromUTCArray,

      // isRowInFuture: isRowFuture,
      isRowInNextHot: isRowInNextHot,
      // isRowInNext: isRowInNext,
      isRowInPast: isRowInPast,
      isRowInJustReleased: isRowInJustReleased,
      isInFuture: isInFuture,

      daysBetween: daysBetween,
      deltaMinMS: deltaMinMS,
      // deltaDayS: deltaDayS,
      sameDay: sameDay,
      convertTextTime: convertTextTime,

      nYearsAgo: nYearsAgo,
      // nMonthsAgo: nMonthsAgo,
      nDaysAgo: nDaysAgo,
      format: format,
      // formatLocal: formatLocal,
      convertInterval: convertInterval,
      getHumanizedDifference: getHumanizedDifference,
      getDifferenceInDays: getDifferenceInDays,
      getSupportedTimeZones: getSupportedTimeZones,
      suggestTradingViewTimeZone: suggestTimeZone,
      getMomentTimeZones: getMomentTimeZones,
      getTimezoneOffset: getTimezoneOffset,
      getDateStart: getDateStart,
      getDateEnd: getDateEnd,

      setUserTimeZone: setUserTimeZone,
      getUserTimeZone: getUserTimeZone,
      getUserTimeZoneOffsetString: getUserTimeZoneOffsetString,
      getUserTimeZoneOffset: getUserTimeZoneOffset,
      getUserTimerZoneName: getUserTimerZoneName,
      getDefaultTimeZone: getDefaultTimeZone,

      getCurrentYear: getCurrentYear,
      getCurrentMonth: getCurrentMonth,
      getCurrentDay: getCurrentDay,
      getCurrentHours: getCurrentHours,
      getUserHours: getUserHours,
    };
  }
})();
