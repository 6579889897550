/**
 * Created by Team Dynomind on 13/03/2024.
 */

(function btNewsStreamControllerClosure() {
  'use strict';

  angular.module('ecapp').controller('btNewsStreamController', btNewsStreamController);

  btNewsStreamController.$inject = ['$scope', '$sce', 'btShareScopeService', 'btNewsStreamService', 'btPusherService'];

  /**
   * This is controller for News Streams.
   *
   * @ngdoc controller
   * @name btNewsStreamController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ISCEService} $sce
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.INewsStreamService} btNewsStreamService
   * @param {ecapp.IPusherService} btPusherService
   */
  function btNewsStreamController($scope, $sce, btShareScopeService, btNewsStreamService, btPusherService) {
    console.log('Running btNewsStreamController');
    $scope.isLoading = true;
    var pushBind = false;
    $scope.newsStreams = [];
    $scope.requestAccessNewsStream = btNewsStreamService.requestAccessNewsStream;

    activate();

    /**
     * Activate controller
     */
    function activate() {
      btShareScopeService
        .wait()
        .then(function () {
          $scope.hasFeedFeature = btShareScopeService.getAccountInfoField('newsSteamByLds');
        })
        .then(function () {
          if ($scope.hasFeedFeature) {
            subscribeToNewsStreamPusher();
            getAllNewsStreams();
          } else {
            $scope.isLoading = false;
          }
        });
    }

    /**
     * Convert news stream
     *
     * @param  {Object} newsStream - News stream to convert
     * @ngdoc method
     * @name convertMessage
     * @memberOf ecapp.btNewsStreamController
     * @return {Object} News stream
     */
    function convertNewsStream(newsStream) {
      newsStream.message = $sce.trustAsHtml(newsStream.message.replace(/\n/g, '<br>'));
      return newsStream;
    }


    /**
     * Get all news streams
     *
     * @ngdoc method
     * @name getAllNewsStreams
     * @memberOf ecapp.btNewsStreamController
     */
    function getAllNewsStreams() {
      btNewsStreamService
        .getNewsStreamList()
        .then(function (newsStreams) {
          $scope.newsStreams = newsStreams.map(convertNewsStream);
          $scope.isLoading = false;
        })
        .catch(function (reason) {
          console.error('btNewsStreamController: getAllNewsStreams error', reason);
          $scope.isLoading = false;
        });
    }

    /**
     * Subscribe to news stream pusher
     *
     * @ngdoc method
     * @name subscribeToNewsStreamPusher
     * @memberOf ecapp.btNewsStreamController
     */
    function subscribeToNewsStreamPusher() {
      if (!pushBind) {
        btPusherService.addEventHandler('newsStream', 'new-news-stream', function (data) {
          if (data && data.id) {
            const index = $scope.newsStreams.findIndex((message) => message.id === data.id);
            if (index === -1) {
              $scope.newsStreams.unshift(convertNewsStream(data));
            }
          }
        });
        btPusherService.addEventHandler('newsStream', 'remove-news-stream', function (data) {
          if (data && data.id) {
            const index = $scope.newsStreams.findIndex((message) => message.id === data.id);
            if (index !== -1) {
              $scope.newsStreams.splice(index, 1);
            }
          }
        });
        pushBind = true;
      }
    }
  }
})();
