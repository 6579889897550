/**
 * Created by Sergey Panpurin on 4/26/2017.
 */

// cspell:words FUTUREOPTION STOCKOPTION CURRENCYOPTION MUTUALFUND MONEYMARKETFUND INDEXOPTION suggestsymbols BUYTOCOVER SELLSHORT BUYTOOPEN BUYTOCLOSE SELLTOOPEN SELLTOCLOSE

// @ts-check
(function btTradeStationApiServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'TradeStationAPI Service:';

  angular.module('ecapp').factory('btTradeStationApiService', btTradeStationApiService);

  btTradeStationApiService.$inject = [
    'BT',
    'TS',
    '$q',
    '$interval',
    'btInstrumentsService',
    'btTradeStationAuthService',
    'btTemplateApiService',
  ];

  /**
   * This factory works with TradeStation API. This factory contains oauth authorization and TradeStation API functions.
   *
   * Example:
   * // call getDataCallback with externalCallback
   * getDataCallback(Brokerage.getAccountsByUserID.bind(Brokerage), ["token", "user", "callback"],
   *   function externalCallback(error, data) {
   *        accounts = data;
   *        locks['init'] = false;
   *      }
   * );
   *
   * Function getDataCallback use errorHandler inside, so function getAccountsByUserID will be called with
   * callback errorHandler. In good case errorHandler just call externalCallback. In bad case errorHandler refresh
   * token and after run externalCallback
   *
   * In good case
   * getAccounts -> promise
   * getDataCallback -> null
   * _callFunc -> null
   * Brokerage.getAccountsByUserID -> request
   * --> errorHandler -> null
   *     externalCallback -> null | true | false
   *
   * In bad case
   * getAccounts -> promise
   * getDataCallback -> null
   * _callFunc -> null
   * Brokerage.getAccountsByUserID -> request
   * --> errorHandler -> null
   *     _refreshToken -> promise
   *     --> _callFunc -> null
   *         Brokerage.getAccountsByUserID -> request
   *         --> errorHandler -> null
   *             externalCallback -> null | true | false
   *
   *  In case of streaming we need to return reference to request to have ability to terminate streaming.
   *
   * @ngdoc service
   * @name btTradeStationApiService
   * @memberOf ecapp
   * @param {ecapp.IBTConstants} BT
   * @param {ecapp.ITSConstants} TS
   * @param {angular.IQService} $q
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITradeStationAuthService} btTradeStationAuthService
   * @param {ecapp.ITemplateApiService} btTemplateApiService
   * @return {ecapp.ITradeStationApiService}
   */
  function btTradeStationApiService(
    BT,
    TS,
    $q,
    $interval,
    btInstrumentsService,
    btTradeStationAuthService,
    btTemplateApiService
  ) {
    console.log('Running btTradeStationApiService');

    // Connect TradeStation API generated from OpenAPI specification

    var TOKEN_STORAGE_KEY = 'TS_ACCESS_TOKEN';
    var API_URL = 'https://api.tradestation.com';
    var SIM_URL = 'https://sim-api.tradestation.com';
    var API_PROXY_URL = '/tradestation/api';
    var SIM_PROXY_URL = '/tradestation/sim-api';

    var NO_ACCOUNT = 'No Account';
    var TOO_MANY_ACCOUNTS = 'Too many accounts';
    var TOO_MANY_CRYPTO_ACCOUNTS = 'Too many crypto accounts';

    var REGULAR_ACCOUNTS = ['Cash', 'Margin', 'Futures', 'DVP'];
    var CRYPTO_ACCOUNTS = ['Crypto'];

    var TradingMode = {
      REAL: 'real',
      DEMO: 'demo',
    };

    var CryptoSymbols = {
      USD: 'USD',
      BCH: 'BCHUSD',
      BTC: 'BTCUSD',
      ETH: 'ETHUSD',
      LTC: 'LTCUSD',
      XRP: 'XRPUSD',
      USDC: 'USDCUSD',
      SHIB: 'SHIBUSDC',
      MKR: 'MKRUSDC',
      MATIC: 'MATICUSDC',
      LINK: 'LINKUSDC',
      AAVE: 'AAVEUSDC',
      COMP: 'COMPUSDC',
    };

    var gMode = TradingMode.DEMO;

    /** @type {ext.ts} */
    var gSdk = window.TradestationApi;
    var gClient = gSdk.OpenAPI;

    // @ts-ignore
    gClient.BASE = function (options) {
      // Due to CORS issue redirect v2 api to proxy.
      if (options.url.includes('v2')) {
        return getProxyBaseUrl(gMode);
      }

      return getApiBaseUrl(gMode);
    };

    // @ts-ignore
    gClient.HEADERS = function (options) {
      if (options.url.includes('stream')) {
        return { Accept: 'application/vnd.tradestation.streams.v2+json' };
      }

      return { Accept: 'application/json' };
    };

    gClient.TOKEN = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (gDebug) console.log(gPrefix, 'trading mode -', gClient.BASE);

    // User's accounts object
    /** @type {ecapp.ITradingAccount[] | null} */
    var gAccounts = null;
    /** @type {string | null} */
    var gSelectedAccountId = null;

    // Locks object
    var gLocks = { init: false };

    var gAuth = btTradeStationAuthService.auth;

    // Modify BrokerageService
    [
      'getAccounts',
      'getBalances',
      'getBalancesBod',
      'getHistoricalOrders',
      'getOrders',
      'getPositions',
      'getWallets',
      'streamWallets',
    ].forEach(function (key) {
      gSdk.BrokerageService[key] = gAuth(gSdk.BrokerageService[key]);
    });

    // Modify MarketDataService
    [
      'getBars',
      'streamBars',
      'getQuoteChangeStream',
      'getCryptoSymbolNames',
      'getSymbolDetails',
      'getInterestRates',
      'getOptionExpirations',
      'getOptionRiskReward',
      'getOptionSpreadTypes',
      'getOptionStrikes',
      'getOptionChain',
      'getOptionQuotes',
    ].forEach(function (key) {
      gSdk.MarketDataService[key] = gAuth(gSdk.MarketDataService[key]);
    });

    // Modify MarketDataV2Service
    ['suggestsymbols', 'searchSymbols', 'streamTickBars'].forEach(function (key) {
      gSdk.MarketDataV2Service[key] = gAuth(gSdk.MarketDataV2Service[key]);
    });

    // Modify OrderExecutionService
    [
      'confirmOrder',
      'confirmGroupOrder',
      'placeGroupOrder',
      'placeOrder',
      'replaceOrder',
      'cancelOrder',
      'getActivationTriggers',
      'routes',
    ].forEach(function (key) {
      gSdk.OrderExecutionService[key] = gAuth(gSdk.OrderExecutionService[key]);
    });

    var MINUTE = 'Minute';
    var DAILY = 'Daily';
    var WEEKLY = 'Weekly';
    var MONTHLY = 'Monthly';

    // prettier-ignore
    var GRANULARITY_OPTIONS = {
      M1:  { value:   '1', unit: MINUTE,  milliseconds:            1 * 60 * 1000 },
      M5:  { value:   '5', unit: MINUTE,  milliseconds:            5 * 60 * 1000 },
      M15: { value:  '15', unit: MINUTE,  milliseconds:           15 * 60 * 1000 },
      M30: { value:  '30', unit: MINUTE,  milliseconds:           30 * 60 * 1000 },
      H1:  { value:  '60', unit: MINUTE,  milliseconds:           60 * 60 * 1000 },
      H4:  { value: '240', unit: MINUTE,  milliseconds:          240 * 60 * 1000 },
      H8:  { value: '480', unit: MINUTE,  milliseconds:          480 * 60 * 1000 },
      D:   { value:   '1', unit: DAILY,   milliseconds:      24 * 60 * 60 * 1000 },
      W:   { value:   '1', unit: WEEKLY,  milliseconds:  7 * 24 * 60 * 60 * 1000 },
      M:   { value:   '1', unit: MONTHLY, milliseconds: 30 * 24 * 60 * 60 * 1000 },
    };

    /** @type {Record<string, ecapp.IQuoteObject>} */
    var gQuotes = {};
    var gQuoteStream = null;

    var QUOTES_UPDATE_INTERVAL = 2000;

    $interval(updateQuotesStream, QUOTES_UPDATE_INTERVAL);

    var WAIT_BAR_ATTEMPTS = 10;
    var WAIT_BAR_INTERVAL = 1000;

    /** @type {Record<string, angular.IPromise<ecapp.ITradingInstrument>>} */
    var gSymbolCache = {};

    var gPreloadCryptoPromise;

    return {
      initialize: initialize,
      connect: connect,
      disconnect: disconnect,

      login: login,
      fastLogin: fastLogin,
      logout: logout,
      signUp: signUp,
      checkUser: checkUser,
      getUsername: getUsername,
      isLoggedIn: isLoggedIn,

      getTradingMode: getTradingMode,
      setTradingMode: setTradingMode,

      getAccounts: getAccounts,
      getBalances: getBalances,
      getPositions: getPositions,
      getOrders: getOrders,

      getSymbolInfo: getSymbolInfo,
      searchSymbol: searchSymbol,
      suggestSymbols: suggestSymbols,
      getOptionExpirations: getOptionExpirations,
      getOptionStrikes: getOptionStrikes,

      getQuotes: getQuotes,
      streamQuote: streamQuote,
      getSnapshots: getSnapshots,
      streamSnapshot: streamSnapshot,

      confirmOrder: confirmOrder,
      submitOrder: submitOrder,
      updateOrder: updateOrder,
      cancelOrder: cancelOrder,

      getCandles: getCandles,
      getLastCandlesData: getLastCandlesData,
      getLiveCandleData: getLiveCandleData,
      getLiveCandlesData: getLiveCandlesData,
      getEntryPrice: getEntryPrice,

      getDataCallback: getData,

      selectAccount: selectAccount,
      isAccountSelected: isAccountSelected,
      getSelectedAccountId: getSelectedAccountId,
      getSelectedAccount: getSelectedAccount,

      getAccessData: getAccessData,
    };

    /**
     *
     * @param {string} mode - trading mode
     * @return {string}
     */
    function getApiBaseUrl(mode) {
      return mode === TradingMode.REAL ? API_URL : SIM_URL;
    }

    /**
     *
     * @param {string} mode - trading mode
     * @return {string}
     */
    function getProxyBaseUrl(mode) {
      return mode === TradingMode.REAL ? API_PROXY_URL : SIM_PROXY_URL;
    }

    /**
     *
     */
    function updateQuotesStream() {
      if (gDebug) console.log(gPrefix, 'Quote Stream - Checking for update...');

      var newSymbols = [];
      var oldSymbols = [];
      var symbols = [];
      Object.keys(gQuotes).forEach(function (symbol) {
        if (!gQuotes[symbol].streaming) {
          newSymbols.push(symbol);
          gQuotes[symbol].streaming = true;
        }

        if (isOldQuote(gQuotes[symbol].requiredAt)) {
          oldSymbols.push(symbol);
        }

        symbols.push(symbol);
      });

      if (newSymbols.length) {
        if (gDebug) console.log(gPrefix, 'Quote Stream - New symbols', newSymbols);
      }

      if (oldSymbols.length) {
        if (gDebug) console.log(gPrefix, 'Quote Stream - Old symbols', oldSymbols);
      }

      var isModified = newSymbols.length > 0;

      if (isModified || (!gQuoteStream && symbols.length)) {
        stopQuotesStream();
        startQuotesStream(symbols);
      } else {
        if (gDebug) console.log(gPrefix, 'Quote Stream - No updates...');
      }
    }

    /**
     * Checks whether quote is old.
     *
     * @param {Date} requiredAt - require at time
     * @return {boolean}
     */
    function isOldQuote(requiredAt) {
      return Date.now() - requiredAt.getTime() >= 10 * 1000;
    }

    /**
     * Prepares quote object.
     *
     * @param {string} symbol - symbol
     * @return {ecapp.IQuoteObject}
     */
    function prepareQuote(symbol) {
      if (!gQuotes[symbol]) {
        gQuotes[symbol] = {
          requiredAt: new Date(),
          streaming: false,
          updatedAt: new Date(),
          previousClose: '0',
          open: '0',
          high: '0',
          low: '0',
          close: '0',
          ask: '0',
          bid: '0',
          last: '0',
        };
      } else {
        gQuotes[symbol].requiredAt = new Date();
      }

      return gQuotes[symbol];
    }

    /**
     * Prepare 24 hours
     *
     * @param {*} symbol
     * @param {*} bars
     */
    function prepare24Hours(symbol, bars) {
      if (gQuotes[symbol] && bars) {
        const lowest = {
          value: parseFloat(bars[0].Low),
          text: bars[0].Low,
        };

        const highest = {
          value: parseFloat(bars[0].High),
          text: bars[0].High,
        };

        bars.forEach(function (bar) {
          const low = parseFloat(bar.Low);
          const high = parseFloat(bar.High);

          if (low < lowest.value) {
            lowest.value = low;
            lowest.text = bar.Low;
          }

          if (high > highest.value) {
            highest.value = high;
            highest.text = bar.High;
          }
        });

        const open = bars[0].Open;
        gQuotes[symbol].high = highest.text;
        gQuotes[symbol].low = lowest.text;
        gQuotes[symbol].open = open;
        gQuotes[symbol].previousClose = open;
      }
    }

    /**
     *
     * @param {string} symbol
     * @return {ecapp.ITradingLiveCandle}
     */
    function getCachedCandle(symbol) {
      return {
        symbol: symbol,
        time: Date.now(), // quote.TradeTime,
        yesterday: {
          close: parseFloat(gQuotes[symbol].previousClose),
          closeText: gQuotes[symbol].previousClose,
        },
        today: {
          low: parseFloat(gQuotes[symbol].low),
          lowText: gQuotes[symbol].low,
          high: parseFloat(gQuotes[symbol].high),
          highText: gQuotes[symbol].high,
          open: parseFloat(gQuotes[symbol].open),
          openText: gQuotes[symbol].open,
        },
        now: {
          bid: parseFloat(gQuotes[symbol].bid),
          bidText: gQuotes[symbol].bid,
          ask: parseFloat(gQuotes[symbol].ask),
          askText: gQuotes[symbol].ask,
          last: parseFloat(gQuotes[symbol].last),
          lastText: gQuotes[symbol].last,
        },
      };
    }

    /**
     *
     */
    function stopQuotesStream() {
      if (gDebug) console.log(gPrefix, 'Quote Stream - Stopping');
      if (gQuoteStream) {
        gQuoteStream.stop();
        gQuoteStream = null;
      }
    }

    /**
     *
     * @param {string[]} symbols
     * @return {void}
     */
    function startQuotesStream(symbols) {
      if (gQuoteStream) {
        if (gDebug) console.log(gPrefix, 'Quote Stream - Stream was not stopped');
        return;
      }

      var promise = gSdk.MarketDataService.getQuoteChangeStream(symbols.join(','));
      if (gDebug) console.log(gPrefix, 'Quote Stream Promise -', promise);

      promise
        .then(function (stream) {
          gQuoteStream = stream;

          if (gDebug) console.log(gPrefix, 'Quote Stream - stream');
          if (gDebug) console.log(gPrefix, 'Quote Stream - Started');

          stream.onMessage = function (err, data) {
            if (err) {
              console.error(err);
              if (gDebug) console.log(gPrefix, 'Quote Stream - Error', err, data);
            } else {
              if ('Symbol' in data) {
                if (data.Low && gDebug) console.log(`>>>>> Quote Stream - ${_debugQuote(data)}`);
                gQuotes[data.Symbol].updatedAt = new Date();

                updatePriceValue(gQuotes[data.Symbol], 'last', data.Last);
                updatePriceValue(gQuotes[data.Symbol], 'ask', data.Ask);
                updatePriceValue(gQuotes[data.Symbol], 'bid', data.Bid);
                updatePriceValue(gQuotes[data.Symbol], 'close', data.Last);
                updatePriceValue(gQuotes[data.Symbol], 'close', data.Close);

                updatePriceValue(gQuotes[data.Symbol], 'previousClose', data.PreviousClose);
                updatePriceValue(gQuotes[data.Symbol], 'open', data.Open);
                updatePriceValue(gQuotes[data.Symbol], 'low', data.Low);
                updatePriceValue(gQuotes[data.Symbol], 'high', data.High);
              }
            }
          };

          stream.onDone = function () {
            if (gDebug) console.log(gPrefix, 'Quote Stream - Done');
            gQuoteStream = null;
          };
        })
        .catch(function (error) {
          gQuoteStream = null;
          console.error(error);
          if (gDebug) console.log(gPrefix, 'Quote Stream - Error', error);
        });
    }

    /**
     * Prepares debug info for quote.
     *
     * @param {*} data - quote data
     * @return {string} debug info
     */
    function _debugQuote(data) {
      return `S:${data.Symbol}, A: ${data.Ask}, B: ${data.Bid}, L: ${data.Last},  P: ${data.PreviousClose}, O: ${data.Open}, L: ${data.Low}, H: ${data.High}, C: ${data.Close}`;
    }

    /**
     *
     * @param {ecapp.IQuoteObject} obj - quote object
     * @param {string} field - field
     * @param {string | null} value - value
     */
    function updatePriceValue(obj, field, value) {
      if (value && value !== '0') obj[field] = value;
    }

    /**
     * Returns BetterTrader ticker.
     *
     * @param {string} symbol - TradeStation symbol
     * @return {string} ticker
     */
    function _ticker(symbol) {
      return 'TRADESTATION:' + symbol;
    }

    /**
     * Preloads symbols for items.
     *
     * @template T
     * @param {T[]} items - items
     * @param {(item: T) => string} getSymbol - function to get symbol from item
     * @return {angular.IPromise<T[]>} items
     */
    function _preloadSymbols(items, getSymbol) {
      var promises = items.map(function (item) {
        var symbol = getSymbol(item);
        return _preloadSymbol(symbol);
      });

      return $q.all(promises).then(function () {
        return items;
      });
    }

    /**
     * Preloads symbol.
     *
     * @param {string} symbol
     * @return {angular.IPromise<any>}
     */
    function _preloadSymbol(symbol) {
      if (!symbol) return $q.resolve();

      var ticker = _ticker(symbol);
      var instrument = btInstrumentsService.getInstrumentByTicker(ticker);
      if (!instrument) return getSymbolInfo(ticker);

      return $q.resolve();
    }

    /* --- Public functions --- */

    /**
     * Connects to the TradeStation.
     *
     * @return {angular.IPromise<{ userId: string, accessToken: string }>}
     */
    function connect() {
      return btTradeStationAuthService.connect().then(_firstSelectAccount);
    }

    /**
     * Disconnects from the TradeStation.
     *
     * @return {angular.IPromise<{}>}
     */
    function disconnect() {
      gAccounts = null;
      gSelectedAccountId = null;

      return btTradeStationAuthService.logout();
    }

    /**
     * Checks if user is logged in to the TradeStation.
     *
     * @return {boolean}
     */
    function isLoggedIn() {
      return btTradeStationAuthService.isLoggedIn();
    }

    /**
     * Init Tradestation API service. Now just receive information about accounts.
     *
     * @param {{ userId: string, accessToken: string }} response
     * @return {angular.IPromise<{ userId: string, accessToken: string }>}
     */
    function _firstSelectAccount(response) {
      var deferred = $q.defer();

      if (gAccounts === null && gLocks['init'] === false) {
        gLocks['init'] = true;

        gSdk.BrokerageService.getAccounts()
          .then(function (data) {
            gAccounts = data.Accounts.map(_convertAccount);
            if (gSelectedAccountId === null) {
              var selectedAccount = gAccounts[0];
              gSelectedAccountId = selectedAccount ? selectedAccount.key : null;
            }

            gLocks['init'] = false;
            deferred.resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            deferred.reject(error);
          });
      } else {
        deferred.resolve(response);
      }

      return deferred.promise;
    }

    /**
     * Logs in to the TradeStation.
     *
     * @param {string} mode - trading mode: real ot demo
     * @param {boolean} isForceLogin - force login or not
     * @param {ecapp.ICallbackWithData<ecapp.ITradeStationAuthData>} saveDataFunction
     * @return {angular.IPromise<ecapp.ITradeStationAuthData>}
     */
    function login(mode, isForceLogin, saveDataFunction) {
      setTradingMode(mode);

      return btTradeStationAuthService.login(mode, isForceLogin, function (data) {
        if (gDebug) console.log(gPrefix, 'set token', data.accessTokenData.token);
        gSdk.OpenAPI.TOKEN = data.accessTokenData.token;
        return saveDataFunction(data);
      });
    }

    /**
     * (Not supported) Executes fast login.
     *
     * @param {string} mode - trading mode: real ot demo
     * @param {object} data - access data
     * @param {ecapp.ICallbackWithData} callback - function to save access data
     * @return {angular.IPromise<*>} - access data
     */
    function fastLogin(mode, data, callback) {
      void mode;
      void data;
      void callback;
      return $q.reject(new Error('Not supported'));
    }

    /**
     * Logs out from the TradeStation.
     *
     * @return {angular.IPromise<{}>}
     */
    function logout() {
      gAccounts = [];
      gSelectedAccountId = null;

      return btTradeStationAuthService.logout();
    }

    /**
     * (Not supported) Registers a new user.
     *
     * @param {ecapp.INewUserRequest} userData
     * @return {angular.IPromise<any>}
     */
    function signUp(userData) {
      var deferred = $q.defer();
      console.log(gPrefix, 'signUp', userData);
      deferred.reject(new Error('Error'));
      return deferred.promise;
    }

    /**
     * (Not supported) Checks user account.
     *
     * @param {ecapp.INewUserRequest} userData - user data
     * @return {angular.IPromise<any>}
     */
    function checkUser(userData) {
      var deferred = $q.defer();
      console.log(gPrefix, 'checkUser', userData);
      deferred.reject(new Error('Error'));
      return deferred.promise;
    }

    /**
     * (Not supported) Generates a username.
     *
     * @param {string} email - user email
     * @return {angular.IPromise<any>}
     */
    function getUsername(email) {
      var deferred = $q.defer();
      console.log(gPrefix, 'getUsername', email);
      deferred.reject(new Error('Error'));
      return deferred.promise;
    }

    /**
     * TradeStation API wrapper. Process error, restart if token was expired.
     *
     * @param {ecapp.ICallback} func - function to call
     * @param {Array} args - function's arguments string "token", "user", and "callback" will be replaced
     * @param {ecapp.IRequestCallback|{type:String,func:ecapp.IRequestCallback}} callback - callback
     * @param {Boolean} [forceLogin] -
     * @return {{request: *}}
     */
    function getData(func, args, callback, forceLogin) {
      return btTradeStationAuthService.getDataCallback(func, args, callback, forceLogin);
    }

    /* --- User data --- */

    /**
     * Gets list of user accounts.
     *
     * @return {angular.IPromise<ecapp.ITradingAccount[]>} - list of accounts
     */
    function getAccounts() {
      var deferred = $q.defer();
      gSdk.BrokerageService.getAccounts()
        .then(function (data) {
          gAccounts = data.Accounts.map(_convertAccount);

          // reset selected account
          gSelectedAccountId = btTemplateApiService.resetSelectedAccount(gAccounts, gSelectedAccountId);

          deferred.resolve(gAccounts);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Converts account object.
     *
     * @param {ext.ts.Account} account - Tradestation account object
     * @return {ecapp.ITradingAccount} - account object
     * @private
     */
    function _convertAccount(account) {
      return {
        acc: account.AccountID,
        key: account.AccountID,
        name: account.AccountID,
        type: account.AccountType,
        rawData: account,
      };
    }

    /**
     * Gest balance for accounts.
     *
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingBalance[]>}
     */
    function getBalances(accountIds) {
      if (!accountIds.length) return $q.reject(NO_ACCOUNT);

      var regularAccountIds = _filterAccountsIds(accountIds, REGULAR_ACCOUNTS);
      var cryptoAccountIds = _filterAccountsIds(accountIds, CRYPTO_ACCOUNTS);

      if (regularAccountIds.length > 25) return $q.reject(TOO_MANY_ACCOUNTS);
      if (cryptoAccountIds.length > 1) console.error(TOO_MANY_CRYPTO_ACCOUNTS);

      var deferred1 = $q.defer();
      var deferred2 = $q.defer();

      if (!regularAccountIds.length) {
        deferred1.resolve([]);
      } else {
        gSdk.BrokerageService.getBalances(regularAccountIds.join(','))
          .then(function (data) {
            if (data.Errors && data.Errors.length) console.error(data.Errors);
            const results = data.Balances ? data.Balances.map(_convertBalance) : [];
            deferred1.resolve(results);
          })
          .catch(function (error) {
            console.error(error);
            deferred1.resolve([]);
          });
      }

      if (!cryptoAccountIds.length) {
        deferred2.resolve([]);
      } else {
        gSdk.BrokerageService.getWallets(cryptoAccountIds[0])
          .then(function (data) {
            if (data.Errors && data.Errors.length) console.error(data.Errors);
            var wallets = data.Wallets ? data.Wallets : [];
            var balance = _convertCryptoBalance(cryptoAccountIds[0], wallets, data);
            deferred2.resolve([balance]);
          })
          .catch(function (error) {
            console.error(error);
            deferred2.resolve([]);
          });
      }

      return $q.all([deferred1.promise, deferred2.promise]).then(function (results) {
        var store = {};
        _groupByAccount(results[0], store);
        _groupByAccount(results[1], store);

        var balances = _sortByAccount(store, accountIds);
        return balances;
      });
    }

    /**
     * Converts balance object.
     *
     * @param {ext.ts.Balance} balance - TradeStation balance object
     * @return {ecapp.ITradingBalance} - balance object
     * @private
     */
    function _convertBalance(balance) {
      return {
        acc: balance.AccountID,
        key: balance.AccountID,
        name: balance.AccountID,
        type: balance.AccountType,
        NAV: parseFloat(balance.Equity),
        UPL: parseFloat(balance.BalanceDetail.UnrealizedProfitLoss),
        Balance: parseFloat(balance.CashBalance),
        RPL: parseFloat(balance.BalanceDetail.RealizedProfitLoss),
        MarginUsed: parseFloat(balance.BalanceDetail.RequiredMargin),
        MarginAvailable: parseFloat(balance.BuyingPower),
        rawData: balance,
      };
    }

    /**
     * Converts crypto wallet to balance.
     *
     * @param {string} cryptoAccountId - crypto account id
     * @param {ext.TradestationApi.Wallet[]} wallets - wallets
     * @param {*} rawData - raw data
     * @return {ecapp.ITradingBalance}
     */
    function _convertCryptoBalance(cryptoAccountId, wallets, rawData) {
      let unrealizedProfitLoss = 0;
      let balanceUSD = 0;

      wallets.forEach(function (wallet) {
        unrealizedProfitLoss += parseFloat(wallet.UnrealizedProfitLossAccountCurrency) || 0;
        if (wallet.Currency === 'USD') balanceUSD = parseFloat(wallet.Balance) || 0;
      });

      return {
        acc: cryptoAccountId,
        key: cryptoAccountId,
        name: cryptoAccountId,
        type: 'Crypto',
        NAV: balanceUSD,
        UPL: unrealizedProfitLoss,
        Balance: balanceUSD,
        RPL: 0,
        MarginUsed: 0,
        MarginAvailable: 0,
        wallets,
        rawData,
      };
    }

    /**
     * Gets all positions for accounts.
     *
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingPosition[]>}
     */
    function getPositions(accountIds) {
      if (!accountIds.length) return $q.reject(NO_ACCOUNT);

      const regularAccountIds = _filterAccountsIds(accountIds, REGULAR_ACCOUNTS);
      const cryptoAccountIds = _filterAccountsIds(accountIds, CRYPTO_ACCOUNTS);

      if (regularAccountIds.length > 25) return $q.reject(TOO_MANY_ACCOUNTS);
      if (cryptoAccountIds.length > 1) console.error(TOO_MANY_CRYPTO_ACCOUNTS);

      const deferred1 = $q.defer();
      const deferred2 = $q.defer();

      if (!regularAccountIds.length) {
        deferred1.resolve([]);
      } else {
        gSdk.BrokerageService.getPositions(regularAccountIds.join(','))
          .then(function (data) {
            return _preloadSymbols(data.Positions, function (item) {
              return item.Symbol;
            });
          })
          .then(function (positions) {
            deferred1.resolve(positions.map(_convertPosition));
          })
          .catch(function (error) {
            deferred1.reject(error);
          });
      }

      if (!cryptoAccountIds.length) {
        deferred2.resolve([]);
      } else {
        gSdk.BrokerageService.getWallets(cryptoAccountIds[0])
          .then(function (data) {
            if (data.Errors && data.Errors.length) console.error(data.Errors);
            const wallets = data.Wallets ? data.Wallets : [];
            const positions = _convertCryptoPositions(cryptoAccountIds[0], wallets);
            deferred2.resolve(positions);
          })
          .catch(function (error) {
            console.error(error);
            deferred2.resolve([]);
          });
      }

      return $q.all([deferred1.promise, deferred2.promise]).then(function (results) {
        const store = {};
        _groupByAccount(results[0], store);
        _groupByAccount(results[1], store);

        const positions = _sortByAccount(store, accountIds);
        return positions;
      });
    }

    /**
     * Filters accounts by types.
     *
     * @param {string[] | null} ids - list of account ids
     * @param {string[] | null} types - list of types
     * @return {string[]} - list of filtered accounts
     */
    function _filterAccountsIds(ids, types) {
      if (!gAccounts) return [];

      var filteredIds = gAccounts
        .filter(function (account) {
          return ids ? ids.includes(account.acc) : true;
        })
        .filter(function (account) {
          return types ? types.includes(account.type) : true;
        })
        .map(function (account) {
          return account.acc;
        });

      return filteredIds;
    }

    /**
     * Groups results by account id.
     *
     * @param {any[]} results - results
     * @param {Record<string, any>} store - result store
     */
    function _groupByAccount(results, store) {
      results.forEach(function (result) {
        if (!store[result.acc]) store[result.acc] = [];
        store[result.acc].push(result);
      });
    }

    /**
     * Sort results by account ids.
     *
     * @param {Record<string, any>} store - result store
     * @param {string[]} accounts - account identifiers
     * @return {any[]} sorted results
     */
    function _sortByAccount(store, accounts) {
      var results = [];
      accounts.forEach(function (account) {
        if (store[account]) results = results.concat(store[account]);
      });
      return results;
    }

    /**
     * Converts position object.
     *
     * @param {ext.ts.Position} position - TradeStation position object
     * @return {ecapp.ITradingPosition}
     * @private
     */
    function _convertPosition(position) {
      var ticker = _ticker(position.Symbol);
      var instrument = btInstrumentsService.getInstrumentByTicker(ticker);

      return {
        displayName: instrument ? instrument.displayName : position.Symbol,
        description: instrument ? instrument.description : position.Symbol,
        key: position.PositionID,
        acc: position.AccountID,
        symbol: position.Symbol,
        position: position.LongShort,
        quantity: parseFloat(position.Quantity),
        OPL: parseFloat(position.UnrealizedProfitLoss),
        acct: parseFloat(position.UnrealizedProfitLossPercent),
        total: parseFloat(position.TotalCost),
        margin: parseFloat(position.InitialRequirement),
        avg: parseFloat(parseFloat(position.AveragePrice).toFixed(2)),
        mrkValue: parseFloat(position.MarketValue),
        lastPrice: parseFloat(position.Last),
        rawData: position,
      };
    }

    /**
     * Converts crypto wallet to position.
     *
     * @param {string} cryptoAccountId - crypto account id
     * @param {ext.TradestationApi.Wallet[]} wallets - wallets
     * @return {ecapp.ITradingPosition[]}
     */
    function _convertCryptoPositions(cryptoAccountId, wallets) {
      var positions = wallets
        .map(function (wallet) {
          var symbol = CryptoSymbols[wallet.Currency];

          return {
            key: cryptoAccountId + '-' + wallet.Currency,
            acc: cryptoAccountId,
            symbol: symbol || wallet.Currency,
            displayName: wallet.Currency,
            description: wallet.Currency,
            position: 'Long',
            quantity: parseFloat(wallet.Balance),
            OPL: parseFloat(wallet.UnrealizedProfitLossAccountCurrency),
            acct: 0,
            total: 0,
            margin: 0,
            avg: 0,
            mrkValue: 0,
            lastPrice: 0,
            rawData: wallet,

            count: 0,
            currency: wallet.Currency === 'USD',
          };
        })
        .filter(function (position) {
          return position.quantity !== 0;
        });

      return positions;
    }

    /**
     * Gets all orders for accounts.
     *
     * @param {string[]} accountIds - list of account ids
     * @return {angular.IPromise<ecapp.ITradingOrder[]>}
     */
    function getOrders(accountIds) {
      if (!accountIds.length) return $q.reject(NO_ACCOUNT);

      var deferred = $q.defer();
      gSdk.BrokerageService.getOrders(accountIds.join(','))
        .then(function (data) {
          return _preloadSymbols(data.Orders, function (item) {
            return item.Legs[0].Symbol;
          });
        })
        .then(function (orders) {
          deferred.resolve(orders.map(_convertOrder));
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Converts order object.
     *
     * @param {ext.ts.Order} order - Tradestation order object
     * @return {ecapp.ITradingOrder}
     * @private
     */
    function _convertOrder(order) {
      var leg = (order.Legs || [])[0] || {};
      var ticker = _ticker(leg.Symbol);
      var instrument = btInstrumentsService.getInstrumentByTicker(ticker);

      return {
        key: order.OrderID.toString(),
        acc: order.AccountID,
        symbol: leg.Symbol,
        displayName: instrument ? instrument.displayName : leg.Symbol,
        quantity: leg.QuantityOrdered ? parseFloat(leg.QuantityOrdered) : 0,
        side: leg.BuyOrSell ? (leg.BuyOrSell === 'Buy' ? 'Long' : 'Short') : 'N/A',
        action: leg.BuyOrSell ? (leg.BuyOrSell === 'Buy' ? 'buy' : 'sell') : 'N/A',
        limit: order.LimitPrice ? parseFloat(order.LimitPrice) : undefined,
        stop: order.StopPrice ? parseFloat(order.StopPrice) : undefined,
        price: parseFloat(!!order.StopPrice ? order.StopPrice : order.LimitPrice),
        status: order.StatusDescription,
        reason: order.RejectReason,
        fillPrice: parseFloat(order.FilledPrice),
        filled: parseFloat(leg.ExecQuantity),
        placeTime: order.OpenedDateTime,
        executeTime: order.ClosedDateTime,
        rawData: order,
      };
    }

    /* --- Market Data --- */
    /**
     * Gets information about selected symbol.
     *
     * @param {string} ticker - ticker
     * @return {angular.IPromise<ecapp.ITradingInstrument>} instrument
     */
    function getSymbolInfo(ticker) {
      var symbol = parseSymbol(ticker);
      if (!symbol) return $q.reject(new Error('No Symbol'));

      if (gSymbolCache[symbol]) return gSymbolCache[symbol];

      var deferred = $q.defer();
      gSymbolCache[symbol] = deferred.promise;

      gSdk.MarketDataService.getSymbolDetails(symbol)
        .then(function (data) {
          if (data) {
            var raw = data.Symbols[0];
            var a = 'tradestation';
            var instrument = btInstrumentsService.createBrokerInstrument(a, raw.Symbol, a, raw.Description, false, raw);

            if (raw.PriceFormat.IncrementStyle === 'Simple') {
              instrument.pip = raw.PriceFormat.Increment;
              instrument.tick = parseInt(raw.PriceFormat.Increment);
            }

            if (raw.PriceFormat.Format === 'Decimal') {
              instrument.precision = parseInt(raw.PriceFormat.Decimals) || 4;
            }

            deferred.resolve(instrument);
          } else {
            deferred.resolve(undefined);
          }
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @return {angular.IPromise<any[]>} - list of crypto symbols
     */
    function _preloadCrypto() {
      if (gPreloadCryptoPromise) return gPreloadCryptoPromise;

      var deferred = $q.defer();
      gPreloadCryptoPromise = deferred.promise;

      gSdk.MarketDataService.getCryptoSymbolNames()
        .then(function (result) {
          return result.SymbolNames.map(function (item) {
            return {
              Category: 'Crypto',
              Name: item,
              Description: item,
            };
          });
        })
        // .then(function (result) {
        //   return gSdk.MarketDataService.getSymbolDetails(result.SymbolNames.join(','));
        // })
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          console.error(error);
          deferred.resolve([]);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {string} text
     * @return {angular.IPromise<any[]>} - list of crypto symbols
     */
    function _searchCrypto(text) {
      text = text.toLowerCase();
      return _preloadCrypto().then(function (items) {
        return items.filter(function (item) {
          return item.Name.toLowerCase().includes(text) || item.Description.toLowerCase().includes(text);
        });
      });
    }

    /**
     * Searches for symbol.
     *
     * @param {string} text - symbol name
     * @param {any} params - options
     * @return {angular.IPromise<ecapp.ITradingSymbolSearch[]>} - list of symbol names
     */
    function searchSymbol(text, params) {
      var deferred = $q.defer();
      var query = 'N=' + text;
      params = params || {};
      if (params.options) {
        query = 'R=' + text + '&C=SO';
      }

      $q.all([_searchCrypto(text), gSdk.MarketDataV2Service.searchSymbols(query)])
        .then(function (results) {
          var symbols = [].concat(results[0], results[1]);
          deferred.resolve(symbols.map(_convertSymbolSearch));
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Converts symbol search.
     *
     * @param {ecapp.ArrayElement<ext.ts.SymbolSearchDefinition>} symbol - TradeStation symbol details
     * @return {ecapp.ITradingSymbolSearch} - symbol object
     * @private
     */
    function _convertSymbolSearch(symbol) {
      return {
        ticker: _ticker(symbol.Name),
        type: symbol.Category,
        name: symbol.Name,
        desc: symbol.Description,
        rawData: symbol,
      };
    }

    /**
     * Suggests symbol.
     *
     * @param {string} text - search text
     * @param {number} limit - limit number of results
     * @param {object} params - additional parameters
     * @return {angular.IPromise<ecapp.ITradingSymbolSuggestion[]>} - list of symbol names
     */
    function suggestSymbols(text, limit, params) {
      void params;
      var deferred = $q.defer();
      var top = limit || 10;
      var filter = undefined;

      $q.all([_searchCrypto(text), gSdk.MarketDataV2Service.suggestsymbols(text, top, filter)])

        .then(function (results) {
          var symbols = [].concat(results[0], results[1]);
          deferred.resolve(
            symbols.map(function (result) {
              return _convertSymbolSuggestion(result);
            })
          );
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Converts symbol suggestion
     *
     * @param {ecapp.ArrayElement<ext.ts.SymbolSuggestDefinition>} symbol - TradeStation symbol details
     * @return {ecapp.ITradingSymbolSuggestion} - symbol object
     * @private
     */
    function _convertSymbolSuggestion(symbol) {
      return {
        ticker: _ticker(symbol.Name),
        type: symbol.Category,
        name: symbol.Name,
        desc: symbol.Description,
        rawData: symbol,
      };
    }

    /**
     *
     * @param {*} underlying
     * @return {any}
     */
    function getOptionExpirations(underlying) {
      var deferred = $q.defer();

      var symbol = parseSymbol(underlying);
      if (!symbol) return $q.reject('No Symbol');

      gSdk.MarketDataService.getOptionExpirations(symbol, undefined)
        .then(function (result) {
          deferred.resolve(
            result.Expirations.map(function (result) {
              return _convertOptionExpiration(symbol, result);
            })
          );
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {string} underlying
     * @param {ext.ts.Expiration1} obj
     * @return {*}
     */
    function _convertOptionExpiration(underlying, obj) {
      var date = obj.Date.slice(2, 10).replace(/-/g, '');
      return {
        id: underlying + ' ' + date,
        underlying: underlying,
        name: underlying + ' ' + date,
        date: obj.Date,
        type: obj.Type,
      };
    }

    /**
     *
     * @param {*} underlying
     * @param {*} expiration
     * @return {any}
     */
    function getOptionStrikes(underlying, expiration) {
      var deferred = $q.defer();

      var symbol = parseSymbol(underlying);
      if (!symbol) return $q.reject('No Symbol');

      gSdk.MarketDataService.getOptionStrikes(symbol, undefined, undefined, expiration, undefined)
        .then(function (result) {
          var strikes = [];
          result.Strikes.forEach(function (item) {
            var newStrikes = _convertOptionStrike(symbol, expiration, result.SpreadType, item);
            strikes = strikes.concat(newStrikes);
          });
          deferred.resolve(strikes);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     *
     * @param {*} underlying
     * @param {*} expiration
     * @param {*} type
     * @param {*} strikes
     * @return {any}
     */
    function _convertOptionStrike(underlying, expiration, type, strikes) {
      var date = expiration.slice(2, 10).replace(/-/g, '');

      return [
        {
          id: underlying + ' ' + date + 'C' + strikes[0],
          underlying: underlying,
          name: underlying + ' ' + date + 'C' + strikes[0],
          date: expiration,
          price: strikes[0],
          side: 'Call',
          type: type,
        },
        {
          id: underlying + ' ' + date + 'P' + strikes[0],
          underlying: underlying,
          name: underlying + ' ' + date + 'P' + strikes[0],
          date: expiration,
          price: strikes[0],
          side: 'Put',
          type: type,
        },
      ];
    }

    /**
     * Get quotes for list of symbols
     *
     * @param {string[]} symbols
     * @return {angular.IPromise<ecapp.ITradingQuoteResponse>}
     */
    function getQuotes(symbols) {
      if (gDebug) console.log(gPrefix, 'Get Quotes', symbols);

      var prices = symbols.map(function (symbol) {
        prepareQuote(symbol);

        return {
          instrument: symbol,
          time: Math.floor(gQuotes[symbol].updatedAt.getTime() / 1000),
          tradeable: true,
          last: gQuotes[symbol].last,
          bids: [{ price: gQuotes[symbol].bid, liquidity: 1 }],
          asks: [{ price: gQuotes[symbol].ask, liquidity: 1 }],
        };
      });

      var response = {
        time: Math.floor(Date.now() / 1000),
        prices: prices,
      };

      return $q.resolve(response);
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {String} symbol - symbol name
     * @param {Function} onProgress - function to call on progress
     * @return {angular.IPromise<*>} - return object to terminate streaming
     */
    function streamQuote(symbol, onProgress) {
      void symbol;
      void onProgress;
      return $q.reject('Not implemented');
    }

    /**
     * Get snapshots for list of symbols
     *
     * @param {String[]} symbols
     * @param {Object} range
     * @param {Number} interval -
     * @param {String} unit -
     * @return {angular.IPromise<Array>}
     */
    function getSnapshots(symbols, range, interval, unit) {
      void symbols;
      void range;
      void interval;
      void unit;
      return $q.reject('Not implemented');
    }

    /**
     * Stream snapshot for selected symbol
     *
     * @param {string} ticker - ticker
     * @param {string} granularity - granularity
     * @param {number} back - candles back
     * @param {(bar: ecapp.ITradingCandle) => void} onUpdate - function to call on progress
     * @return {angular.IPromise<{stop: () => void}>} - return object to terminate streaming
     */
    function streamSnapshot(ticker, granularity, back, onUpdate) {
      var symbol = parseSymbol(ticker);
      if (!symbol) return $q.reject('No Symbol');

      var interval = convertGranularity(granularity);
      if (!interval) return $q.reject(new Error('Unknown interval'));

      var promise = gSdk.MarketDataService.streamBars(symbol, interval.value, interval.unit, back.toString());

      promise
        .then(function (stream) {
          if (gDebug) console.log(gPrefix, 'Bar Stream - Started');

          stream.onMessage = function (err, data) {
            if (err) {
              console.error(err);
              if (gDebug) console.log(gPrefix, 'Bar Stream - Error', err, data);
            } else {
              if (gDebug) console.log(gPrefix, 'Bar Stream - Data', data);
              if ('Epoch' in data) {
                var msg = {
                  complete: false,
                  mid: {
                    o: parseFloat(data.Open),
                    h: parseFloat(data.High),
                    l: parseFloat(data.Low),
                    c: parseFloat(data.Close),
                  },
                  time: data.Epoch,
                  volume: parseInt(data.TotalVolume),
                };
                onUpdate(msg);
              }
            }
          };

          stream.onDone = function () {
            if (gDebug) console.log(gPrefix, 'Bar Stream - Done');
          };

          return stream;
        })
        .catch(function (error) {
          console.error(error);
          if (gDebug) console.log(gPrefix, 'Bar Stream - Error', error);
        });

      return promise;
    }

    /* --- Order execution --- */

    /**
     * Prepare buy or sell order
     *
     * @param {ecapp.ITradingOrderRequest} order - BetterTrader order request object
     * @return {ext.TradestationApi.OrderRequest} - TradeStation order request object
     * @private
     */
    function _prepareOrder(order) {
      var ticker = _ticker(order.symbol);
      var instrument = btInstrumentsService.getInstrumentByTicker(ticker);

      var tradeAction = order.action;
      if (instrument.type === TS.ASSET_TYPE.STOCK_OPTION) {
        if (order.action === BT.TRADE_ACTION.BUY) {
          tradeAction = TS.TRADE_ACTION.BUYTOOPEN;
        }
        if (order.action === BT.TRADE_ACTION.SELL) {
          tradeAction = TS.TRADE_ACTION.SELLTOCLOSE;
        }
      }

      /** @type {ext.TradestationApi.OrderType} */
      // @ts-ignore
      var orderType = order.type === BT.ORDER_TYPE.STOP ? TS.ORDER_TYPE.STOP_MARKET : order.type;

      /** @type {ext.TradestationApi.OrderRequest} */
      var requestBody = {
        AccountID: getSelectedAccountId(),
        AdvancedOptions: undefined,
        BuyingPowerWarning: undefined,
        // Legs: [],
        LimitPrice: undefined,
        // OSOs: [],
        OrderConfirmID: undefined,
        OrderType: orderType,
        Quantity: order.quantity.toString(),
        Route: order.symbol === 'USDCUSD' ? 'USDC' : undefined,
        StopPrice: undefined,
        Symbol: order.symbol,
        TimeInForce: {
          Duration: order.duration || TS.DURATION.GTC,
          Expiration: order.expiration ? order.expiration : undefined,
        },
        TradeAction: tradeAction,
      };

      if (order.limitPrice) {
        requestBody.LimitPrice = order.limitPrice.toString();
      }

      if (order.stopLoss) {
        requestBody.StopPrice =
          typeof order.stopLoss === 'number' ? order.stopLoss.toString() : order.stopLoss.price.toString();
      }

      return requestBody;
    }

    /**
     *
     * @param {string} orderId
     * @param {ecapp.ITradingOrderUpdate} orderUpdate
     * @return {ext.TradestationApi.OrderReplaceRequest}
     */
    function _prepareOrderUpdate(orderId, orderUpdate) {
      void orderId;
      void orderUpdate;
      return {};
    }

    /**
     *
     * @param {Array<ext.TradestationApi.OrderConfirmResponses>} res
     * @return {ecapp.ITradingOrderConfirmation}
     */
    function _convertOrderConfirmation(res) {
      void res;
      return {};
    }

    /**
     *
     * @param {ext.TradestationApi.OrderResponses} res
     * @return {ecapp.ITradingOrderResponse}
     */
    function _convertOrderResponses(res) {
      var a = res.Orders[0];
      return {
        // @ts-ignore
        status: a.Error,
        key: a.OrderID,
        msg: a.Message,
      };
    }

    /**
     *
     * @param {ext.TradestationApi.OrderResponse} res
     * @return {ecapp.ITradingOrderResponse}
     */
    function _convertOrderResponse(res) {
      return {
        key: res.OrderID,
        msg: res.Message,
      };
    }

    /**
     * Confirms order.
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderConfirmation>}
     */
    function confirmOrder(order) {
      var requestBody = _prepareOrder(order);

      return $q(function (resolve, reject) {
        gSdk.OrderExecutionService.confirmOrder(requestBody)
          .then(function (res) {
            console.log(res);
            var data = _convertOrderConfirmation(res);
            resolve(data);
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    }

    /**
     * Submits order.
     *
     * @param {ecapp.ITradingOrderRequest} order - order request data
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function submitOrder(order) {
      var requestBody = _prepareOrder(order);

      return $q(function (resolve, reject) {
        gSdk.OrderExecutionService.placeOrder(requestBody)
          .then(function (res) {
            console.log(res);
            // @ts-ignore
            var data = _convertOrderResponses(res);
            if (data.status === 'FAILED') {
              reject(new Error(data.msg));
            } else {
              resolve(data);
            }
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    }

    /**
     * Updates order.
     *
     * @param {string} orderId - order id
     * @param {ecapp.ITradingOrderUpdate} orderUpdate - changes in order
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function updateOrder(orderId, orderUpdate) {
      var requestBody = _prepareOrderUpdate(orderId, orderUpdate);

      return $q(function (resolve, reject) {
        gSdk.OrderExecutionService.replaceOrder(orderId, requestBody)
          .then(function (res) {
            console.log(res);
            var data = _convertOrderResponse(res);
            resolve(data);
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    }

    /**
     * Cancels order.
     *
     * @param {string} orderId - order id
     * @return {angular.IPromise<ecapp.ITradingOrderResponse>}
     */
    function cancelOrder(orderId) {
      return $q(function (resolve, reject) {
        gSdk.OrderExecutionService.cancelOrder(orderId)
          .then(function (res) {
            console.log(res);
            var data = _convertOrderResponse(res);
            resolve(data);
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    }

    /**
     *
     * @param {*} ticker
     * @return {any}
     */
    function parseSymbol(ticker) {
      var parts = ticker.split(':');
      if (parts.length === 2) {
        return parts[1];
      } else {
        return ticker;
      }
    }

    /**
     * Converts granularity to interval.
     *
     * @param {string} granularity
     * @return {{value: string, unit: string, milliseconds: number}}
     */
    function convertGranularity(granularity) {
      var interval = GRANULARITY_OPTIONS[granularity];
      return interval;
    }

    /**
     *
     * @param {ext.TradestationApi.Bar[]} bars
     * @return {ecapp.ITradingCandle[]}
     */
    function convertBarsData(bars) {
      /** @type {ecapp.ITradingCandle[]} */
      var candles = [];
      bars.forEach(function (item) {
        candles.push({
          complete: true,
          mid: {
            c: parseFloat(item.Close),
            h: parseFloat(item.High),
            l: parseFloat(item.Low),
            o: parseFloat(item.Open),
          },
          // @ts-ignore
          time: parseInt(new Date(item.TimeStamp).getTime()) / 1000,
          volume: parseFloat(item.TotalVolume),
        });
      });
      return candles;
    }

    /**
     * Gets one instrument 30 Candles with frequency defined by granularity parameter. Send request to OANDA.
     *
     * @param {string} ticker - ticker
     * @param {ecapp.CandleGranularity} granularity - set the candle time representation
     * @param {{from: number, to: number, back: number}} period - number of candles
     * @return {angular.IPromise<{ candles: ecapp.ITradingLastCandle[] }>}
     */
    function getCandles(ticker, granularity, period) {
      // console.log('>>>!!! getCandles', ticker, granularity, period);
      var symbol = parseSymbol(ticker);
      if (!symbol) return $q.reject('No Symbol');

      var interval = convertGranularity(granularity);
      if (!interval) return $q.reject(new Error('Unknown interval'));

      var defer = $q.defer();

      var startDate = new Date(period.to).toISOString();
      var barsBack = '' + period.back;

      if (gDebug) console.log(gPrefix, 'getBars', symbol, interval.value, interval.unit, barsBack, startDate);
      gSdk.MarketDataService.getBars(symbol, interval.value, interval.unit, barsBack, startDate)
        .then(function (data) {
          var bars = data.Bars.filter(function (a) {
            return a.Epoch !== undefined;
          }).sort(function (a, b) {
            return a.Epoch - b.Epoch;
          });

          var n = bars.length ? bars.length - 1 : 0;
          if (gDebug) console.log(gPrefix, bars[0]);
          if (gDebug) console.log(gPrefix, bars[n]);
          if (gDebug) console.log(gPrefix, bars);

          var candles = convertBarsData(bars);
          if (gDebug) console.log(gPrefix, candles[0]);
          if (gDebug) console.log(gPrefix, candles[n]);
          defer.resolve({ candles: candles });
        })
        .catch(function (error) {
          console.log(error);
          defer.reject(error);
        });

      return defer.promise;
    }

    /**
     * Gets one instrument 30 Candles with frequency defined by granularity parameter. Send request to OANDA.
     *
     * @param {string} ticker - ticker
     * @param {ecapp.CandleGranularity} granularity - set the candle time representation
     * @param {number} [count] - number of candles
     * @return {angular.IPromise<{ candles: ecapp.ITradingLastCandle[] }>}
     */
    function getLastCandlesData(ticker, granularity, count) {
      // console.log('>>>!!! getLastCandlesData', ticker, granularity, count);
      var symbol = parseSymbol(ticker);
      if (!symbol) return $q.reject('No Symbol');

      var interval = convertGranularity(granularity);
      if (!interval) return $q.reject(new Error('Unknown interval'));

      var defer = $q.defer();

      gSdk.MarketDataService.getBars(symbol, interval.value, interval.unit, count.toString())
        .then(function (data) {
          if (gDebug) console.log(gPrefix, data);
          var candles = convertBarsData(data.Bars);
          if (gDebug) console.log(gPrefix, candles);
          defer.resolve({ candles: candles });
        })
        .catch(function (error) {
          console.log(error);
          defer.reject(error);
        });

      return defer.promise;
    }

    /**
     * Returns live candle data.
     *
     * @param {string} symbol - symbol
     * @return {angular.IPromise<ecapp.ITradingLiveCandle>}
     */
    function getLiveCandleData(symbol) {
      // console.log('>>>!!! getLiveCandleData', symbol);
      if (gDebug) console.log(gPrefix, 'getLiveCandleData', symbol);

      gSdk.MarketDataService.getBars(symbol, '60', 'minute', '24')
        .then(function (result) {
          prepare24Hours(symbol, result.Bars);
        })
        .catch(function (error) {
          console.error(error);
        });

      const quote = prepareQuote(symbol);
      if (quote.streaming) {
        return $q.resolve(getCachedCandle(symbol));
      } else {
        return _waitForBar(symbol).then(getCachedCandle);
      }
    }

    /**
     * Waits for bar.
     *
     * @param {string} symbol - symbol
     * @return {angular.IPromise<string>} symbol
     */
    function _waitForBar(symbol) {
      var deferred = $q.defer();
      var i = 0;
      var quote = prepareQuote(symbol);
      var interval = $interval(function () {
        i++;
        if (i > WAIT_BAR_ATTEMPTS || quote.updatedAt > quote.requiredAt) {
          $interval.cancel(interval);
          deferred.resolve(symbol);
        }
      }, WAIT_BAR_INTERVAL);

      return deferred.promise;
    }

    /**
     *
     * @param {string[]} symbols
     * @return {angular.IPromise<ecapp.ITradingLiveCandle[]>}
     */
    function getLiveCandlesData(symbols) {
      // console.log('>>>!!! getLiveCandlesData', symbols);
      if (gDebug) console.log(gPrefix, 'getLiveCandlesData', symbols);

      var defer = $q.defer();

      symbols.forEach(function (symbol) {
        if (!isCryptoSymbol(symbol)) return;
        gSdk.MarketDataService.getBars(symbol, '60', 'minute', '24')
          .then(function (result) {
            prepare24Hours(symbol, result.Bars);
          })
          .catch(function (error) {
            console.error(error);
          });
      });

      defer.resolve(
        symbols.map(function (symbol) {
          prepareQuote(symbol);
          return getCachedCandle(symbol);
        })
      );

      return defer.promise;
    }

    function isCryptoSymbol(symbol) {
      return symbol.includes('USD') || symbol.includes('BTC') || symbol.includes('USD');
    }

    /**
     * Gets entry price.
     *
     * @param {string} symbol - symbol
     * @param {number} time - time
     * @param {number} after - number of minutes after
     * @return {angular.IPromise<ecapp.IEntryPrice>}
     */
    function getEntryPrice(symbol, time, after) {
      void symbol;
      void time;
      void after;
      return $q.reject(new Error('Not supported'));
    }

    /* --- Private functions --- */

    /**
     * Initializes TradeStation service
     *
     * @param {Object} data - access data
     * @param {ecapp.ICallbackWithData} saveDataFunction - btTradingService function to save access data
     * @return {angular.IPromise<Object>}
     */
    function initialize(data, saveDataFunction) {
      if (gDebug) console.log(gPrefix, 'initialize', data);

      if (data && data.accessTokenData) {
        gSelectedAccountId = data.defaultAccount ? data.defaultAccount : null;
        if (gDebug) console.log(gPrefix, 'set token', data.accessTokenData.token);
        gSdk.OpenAPI.TOKEN = data.accessTokenData.token;
        setTradingMode(data.mode);
      }

      return btTradeStationAuthService
        .initialize(data, function (data) {
          if (gDebug) console.log(gPrefix, 'set token', data.accessTokenData.token);
          gSdk.OpenAPI.TOKEN = data.accessTokenData.token;
          return saveDataFunction(data);
        })
        .then(_firstSelectAccount);
    }

    /**
     * Sets trading mode.
     *
     * @param {string} mode - trading mode (real or demo)
     * @return {boolean}
     */
    function setTradingMode(mode) {
      btTradeStationAuthService.setTradingMode(mode);
      gMode = mode;
      return true;
    }

    /**
     * Returns trading mode (real or demo).
     *
     * @return {string} trading mode
     */
    function getTradingMode() {
      return gMode;
    }

    /**
     * Selects account by id.
     *
     * @param {string} id - account id
     * @return {?string} id of selected account or null
     */
    function selectAccount(id) {
      var res = gAccounts.filter(function (item) {
        return item.key === id;
      });

      gSelectedAccountId = res ? res[0].key : null;

      return gSelectedAccountId;
    }

    /**
     * Returns id of selected account.
     *
     * @return {?string} id of selected account or null
     */
    function getSelectedAccountId() {
      return gSelectedAccountId;
    }

    /**
     *
     * @return {ecapp.ITradingAccount | null}
     */
    function getSelectedAccount() {
      var res = gAccounts.filter(function (item) {
        return item.key === gSelectedAccountId;
      });

      return res ? res[0] : null;
    }

    /**
     * Check if an account is selected
     * @param {String} id
     * @return {Boolean}
     */
    function isAccountSelected(id) {
      return gSelectedAccountId ? gSelectedAccountId === id : false;
    }

    /**
     * This promise return broker access data.
     * Service btTradingService use this function to receive access data and save it.
     * @return {angular.IPromise<Object>}
     */
    function getAccessData() {
      return btTradeStationAuthService.getAccessData();
    }
  }
})();
