/**
 * Created by Team Dynomind on 13/03/2024.
 */

(function btNewsStreamServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btNewsStreamService', service);

  service.$inject = ['$q', 'NewsStream', 'btToastrService', '$ionicPopup', '$ionicLoading'];

  /**
   * @ngdoc service
   * @name ecapp.btNewsStreamService
   * @param {angular.IQService} $q
   * @param {ecapp.IGeneralLoopbackService} NewsStream
   * @param {ecapp.IToastrService} btToastrService
   * @param {ionic.IPopupService} $ionicPopup
   * @param {ionic.ILoadingService} $ionicLoading
   * @return {ecapp.INewsStreamService}
   */
  function service($q, NewsStream, btToastrService, $ionicPopup, $ionicLoading) {

    /**
     * Requests feed access.
     * @ngdoc method
     * @name requestAccessNewsStream
     * @memberOf ecapp.btNewsStreamService
     */
    function requestAccessNewsStream() {
      const confirmPopup = $ionicPopup.confirm({
        title: 'Request Access',
        template: 'Do you like to request access for Feed?',
      });
      confirmPopup.then(function (res) {
        if (res) {
          $ionicLoading.show();
          NewsStream.requestAccess({})
            .$promise.then(function () {
              btToastrService.info('Access Requested', 'News Stream', { type: 'market' });
              $ionicLoading.hide();
            })
            .catch(function (reason) {
              console.error(reason);
              btToastrService.error('Can not request access', 'News Stream', { type: 'market' });
              $ionicLoading.hide();
            });
        }
      });
    }

    /**
     * Returns list of today's news
     *
     * @ngdoc method
     * @name getNewsStreamList
     * @memberOf ecapp.btNewsStreamService
     * @return {angular.IPromise<{Array}>}
     */
    function getNewsStreamList() {
      const deferred = $q.defer();
      NewsStream.list()
        .$promise.then(function (newsStreams) {
          deferred.resolve(newsStreams.data);
        })
        .catch(function (reason) {
          console.error(reason);
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    return {
      requestAccessNewsStream: requestAccessNewsStream,
      getNewsStreamList: getNewsStreamList,
    };
  }
})();
