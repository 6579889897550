/**
 * Created by Sergey Panpurin on 5/18/21.
 */

(function btTransactionFeedClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btTransactionFeed:';

  angular
    .module('ecapp')
    /**
     * ???
     *
     * @ngdoc component
     * @name btTransactionFeed
     * @memberOf ecapp
     * @scope
     * @todo
     *  [ ] Day change if app is open.
     *  [ ] Detection of update issues.
     */
    .component('btTransactionFeed', {
      templateUrl: 'directives/barometer/bt-transaction-feed-widget.html',
      controller: btTransactionFeedController,
      controllerAs: 'vm',
    });

  btTransactionFeedController.$inject = ['$timeout', 'btLinkDataService', 'btSettingsService'];

  /**
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ILinkDataService} btLinkDataService
   * @param {ecapp.ISettingsService} btSettingsService
   */
  function btTransactionFeedController($timeout, btLinkDataService, btSettingsService) {
    if (gDebug) console.log(gPrefix, 'Controller');

    var gSettings = btSettingsService.getLocalSettings();

    /*jshint validthis: true*/
    var vm = this;

    /** @type {ecapp.ITransactionStorage} */
    vm.storage = null;
    vm.isLoading = true;
    vm.error = '';
    vm.subscription = null;
    vm.filteredTransactions = [];

    vm.search = {
      typing: false,
      text: '',
    };

    vm.datepicker = {
      value: '2021/05/28',
      min: '2000/01/01',
      max: '2022/01/01',
    };

    vm.$onInit = function () {
      if (gDebug) console.log(gPrefix, 'Initializing...');

      vm.datepicker.value = btLinkDataService.getToday();
      vm.datepicker.max = btLinkDataService.getToday();

      loadSettings();

      getTransactions();
    };

    /**
     *
     */
    function getTransactions() {
      vm.isLoading = true;
      vm.error = '';

      btLinkDataService
        .getTransactions(vm.datepicker.value)
        .then(function (result) {
          vm.storage = result;

          prepareTransactions();

          vm.subscription = vm.storage.subscribe(function () {
            if (gDebug) console.log(gPrefix, 'Updated');
            prepareTransactions();
          });
          vm.isLoading = false;
        })
        .catch(function (err) {
          console.error(err);
          vm.storage = null;
          vm.subscription = null;
          vm.error = err.message || 'Unable to load transaction due to unknown problem';
          vm.isLoading = false;
        });
    }

    vm.$onDestroy = function () {
      if (gDebug) console.log(gPrefix, 'Destroying...');
      if (vm.storage && vm.subscription) {
        vm.storage.unsubscribe(vm.subscription);
        vm.subscription = null;
        vm.storage = null;
      }
    };

    vm.isNewTransaction = function (transaction) {
      return new Date() - transaction.modifiedOnUtc < 300000;
    };

    vm.resetSearch = function () {
      vm.search.text = '';
      prepareTransactions();
    };

    vm.onSearchChange = function () {
      vm.search.typing = true;
      vm.onSearchComplete();
    };

    vm.onSearchComplete = ionic.debounce(
      function () {
        vm.search.typing = false;
        prepareTransactions();
      },
      400,
      false
    );

    vm.onDateChanged = function () {
      if (vm.storage && vm.subscription) {
        vm.storage.unsubscribe(vm.subscription);
        vm.subscription = null;
        vm.storage = null;
      }

      getTransactions();
    };

    vm.sortColumn = function (column, order) {
      if (order !== undefined) {
        vm.orderByReverse = order === -1;
        vm.reverse = vm.orderByReverse ? -1 : 1;
      } else {
        vm.orderByReverse = vm.orderByColumn === column ? !vm.orderByReverse : false;
        vm.reverse = vm.orderByReverse ? -1 : 1;
      }

      vm.orderByColumn = column;

      prepareTransactions();

      saveSettings();
    };

    /**
     *
     */
    function prepareTransactions() {
      if (vm.storage) {
        vm.filteredTransactions = vm.storage.items.slice().filter(filterTransactions).sort(sortTransactions);
      }
    }

    /**
     *
     * @param {ecapp.ITransaction} a - transaction
     * @return {boolean}
     */
    function filterTransactions(a) {
      if (vm.search.text) {
        return a.search(vm.search.text);
      } else {
        return true;
      }
    }

    /**
     *
     * @param {ecapp.ITransaction} a - first transaction
     * @param {ecapp.ITransaction} b - second transaction
     * @return {number}
     */
    function sortTransactions(a, b) {
      switch (vm.orderByColumn) {
        case 'modified':
          return vm.reverse * (b.modifiedOnUtc - a.modifiedOnUtc);
        case 'month':
          return vm.reverse * (b.deliveryStartDate - a.deliveryStartDate);

        case 'difference':
          return vm.reverse * (b.priceDifferential - a.priceDifferential);
        case 'volume':
          return vm.reverse * (b.totalVolume - a.totalVolume);

        case 'product-symbol':
          return vm.reverse * b.productSymbol.localeCompare(a.productSymbol);
        case 'product-name':
          return vm.reverse * b.productName.localeCompare(a.productName);
        case 'location':
          return vm.reverse * b.location.localeCompare(a.location);

        default:
          return vm.reverse * (b.modifiedOnUtc - a.modifiedOnUtc);
      }
    }

    /**
     * This function saves user settings.
     */
    function loadSettings() {
      vm.orderByReverse = gSettings.get('barometer.transaction-feed.order');
      vm.orderByReverse = vm.orderByReverse !== undefined ? vm.orderByReverse : false;
      vm.reverse = vm.orderByReverse ? -1 : 1;
      vm.orderByColumn = gSettings.get('barometer.transaction-feed.column') || 'modified';
    }

    /**
     * This function loads user settings.
     */
    function saveSettings() {
      gSettings.set('barometer.transaction-feed.order', vm.orderByReverse);
      gSettings.set('barometer.transaction-feed.column', vm.orderByColumn);
    }
  }
})();
