/**
 * Created by Sergey Panpurin on 5/31/2018.
 */

//@ts-check
(function btVoiceAssistantHelperServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btVoiceAssistantHelperService';

  angular.module('ecapp').factory('btVoiceAssistantHelperService', service);

  service.$inject = [
    '$q',
    '$timeout',
    '$rootScope',
    '$state',
    'btVoiceAssistantService',
    'btRestrictionService',
    'btShareScopeService',
    'btPusherService',
    'btEventEmitterService',
    'btHistoryService',
    'btToastrService',
    'btTwitterScannerService',
    'btInstrumentsService',
    'btLinkService',
    'btTimeSupervisionService',
    'btCodes',
    'btSocketService',
    'btSettingsService',
    'btNewsAlertsService',
  ];

  /**
   *
   * @param {angular.IQService} $q
   * @param {angular.ITimeoutService} $timeout
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.IVoiceAssistantService} btVoiceAssistantService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IPusherService} btPusherService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IHistoryService} btHistoryService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ITwitterScannerService} btTwitterScannerService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ILinkService} btLinkService
   * @param {ecapp.ITimeSupervisionService} btTimeSupervisionService
   * @param {ecapp.ICodes} btCodes
   * @param {ecapp.ISocketService} btSocketService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.INewsAlertsService} btNewsAlertsService
   * @return {ecapp.IVoiceAssistantHelperService}
   */
  function service(
    $q,
    $timeout,
    $rootScope,
    $state,
    btVoiceAssistantService,
    btRestrictionService,
    btShareScopeService,
    btPusherService,
    btEventEmitterService,
    btHistoryService,
    btToastrService,
    btTwitterScannerService,
    btInstrumentsService,
    btLinkService,
    btTimeSupervisionService,
    btCodes,
    btSocketService,
    btSettingsService,
    btNewsAlertsService
  ) {
    if (gDebug) console.log('Running btVoiceAssistantHelperService');

    /**
     * @ngdoc service
     * @name btVoiceAssistantHelperService
     * @memberOf ecapp
     * @description
     *  This service implements voice assistance interface.
     *
     *  Voice Assistant saves settings to local storage. So one user don't need to customize it every time. If another
     *  user will use this computer he will have same settings. If user don't have voice assistant it will be disabled.
     */

    /**
     * @typedef {Object} btVoiceAssistantStatus
     * @property {Boolean} isEnable - is enabled
     * @property {Boolean} hasVoice - has voice
     */

    /**
     * @typedef {Object} btNewCrawlerPusherMessage
     * @property {string} n - source name
     * @property {string} t - article title
     * @property {string} l - article link
     * @property {string} d - article description
     */

    /**
     * @typedef {Object} btVoicePusherMessage
     * @property {String} [domain] - specific domain
     * @property {String} text - text to read
     * @property {String} lang - ISO 639-1 language code
     * @property {String} category - message category: twitter, ...
     * @property {btVoicePusherParams} params - message parameters connected to category
     * @property {Number} created  - created timestamp in milliseconds
     */

    /**
     * @typedef {btVoiceTwitterParams|btVoiceTextParams|btVoiceExchangeParams} btVoicePusherParams
     */

    /**
     * @typedef {Object} btVoiceTwitterParams
     * @property {Object} account - ?
     * @property {Number} account.id - ?
     * @property {String} account.name - ?
     * @property {String} link - ?
     * @property {String[]} tags - ?
     * @property {String[]} [markets] - ?
     * @property {Number[]} [events] - ?
     */

    /**
     * @typedef {Object} btVoiceTextParams
     * @property {string} [link] - ?
     * @property {string} [state] - ?
     * @property {object} [params] - ?
     */

    /**
     * @typedef {Object} btVoiceExchangeParams
     * @property {Object} exchange - ?
     * @property {String} exchange.id - ?
     */

    /**
     * @typedef {Object} btSettingsTwitterAccount
     * @property {Object} id - twitter account id
     * @property {Number} name - twitter account name
     * @property {String} displayName - name to display in application
     * @property {String} pronunciation - pronunciation of account name
     * @property {String} description - account description
     * @property {Boolean} testing - is it testing account
     * @property {Object} params - crawling parameters
     * @property {Object} [params.retweets] - handle retweets of another account
     * @property {Object} [params.parser] - parser
     */

    /**
     * @typedef {btVoiceInternalProperty[]} btVoiceInternalSettings
     */

    /**
     * @typedef {Object} btVoiceUserSettings
     * @property {btVoiceUserCategory} [twitter] - ?
     * @property {btVoiceUserCategory} [calendar] - ?
     * @property {btVoiceUserCategory} [exchanges] - ?
     * @property {btVoiceUserCategory} [ideas] - ?
     * @property {btVoiceUserCategory} [movements] - ?
     * @property {btVoiceUserCategory} [custom] - ?
     */

    /**
     * @typedef {Object} btVoiceUserCategory
     * @property {Boolean} enable - is enable
     * @property {String[]} items - set of options
     */

    /**
     * @typedef {Object} btVoiceInternalProperty
     * @property {String} name - property name
     * @property {String} displayName - property display name
     * @property {String} [example] - property example or description
     * @property {Boolean} enable - whether property is enabled
     * @property {Boolean} [testing] - whether property is just for testing
     * @property {btVoiceInternalProperty[]} [items] - list of inner properties
     */

    var gIsActivated = false;

    var gFakeSpeechSynthesis = {
      paused: false,
      pending: false,
      speaking: false,
    };

    var gPusherBind = false;

    var gIsTweetReadable = false;
    var gHasNewTweetPushNotification = false;
    var gIsTweetNotifiable = false;

    var gIsNewsReadable = false;
    var gHasNewsPushNotification = false;
    var gIsNewsNotifiable = false;

    /** @type {number} - index of twitter settings */
    var gTwitterIndex = 0;

    var gCustomIndex = 1;

    /** @type {number} - index of exchanges settings */
    var gExchangesIndex = 4;

    /** @type {number} - index of news crawlers settings */
    var gNewsCrawlerIndex = 5;

    /** @type {btVoiceInternalSettings} - voice assistant settings */
    var gSettings = [
      {
        name: 'twitter',
        displayName: 'Twitter channels ',
        enable: true,
        testing: false,
        items: [],
      },
      {
        name: 'calendar',
        displayName: 'Economic events',
        enable: true,
        testing: false,
        items: [
          {
            name: 'upcoming',
            displayName: 'Release in 5 minutes',
            enable: true,
            testing: false,
          },
          {
            name: 'expected',
            displayName: 'Release in 1 minutes',
            enable: true,
            testing: false,
          },
          {
            name: 'release',
            displayName: 'Event just released',
            enable: true,
            testing: false,
          },
          {
            name: 'follow',
            displayName: 'Follow event',
            enable: false,
            testing: true,
          },
          {
            name: 'insight',
            displayName: 'Highest perspective insight',
            enable: true,
            testing: false,
          },
        ],
      },
      {
        name: 'ideas',
        displayName: 'Trade ideas',
        enable: !btSettingsService.isLinkDataService(),
        items: [
          {
            name: 'potential',
            displayName: 'Potential news-driven',
            enable: true,
            testing: false,
          },
          {
            name: 'news',
            displayName: 'News-driven',
            enable: true,
            testing: false,
          },
          {
            name: 'price',
            displayName: 'Price-driven',
            enable: true,
            testing: false,
          },
        ],
      },
      {
        name: 'movements',
        displayName: 'Market movements',
        enable: true,
        testing: false,
        items: [
          {
            name: 'yesterday',
            displayName: "Moves from yesterday's close",
            example: "Example: S&P500 moves up 2% from yesterday's close",
            enable: true,
            testing: false,
          },
          {
            name: 'lowHigh',
            displayName: 'Daily low to high movements',
            example: 'Example: S&P500 moves down 0.75%, daily high to low',
            enable: true,
            testing: false,
          },
          {
            name: 'crossing',
            displayName: 'Crossing yesterday high/low',
            example: 'Example: S&P500 crosses yesterday high',
            enable: true,
            testing: false,
          },
          {
            name: 'interval',
            displayName: 'Fast movements',
            example: 'Example: S&P500 moves down 1% in the last 5 min',
            enable: true,
            testing: false,
          },
          {
            name: 'follow',
            displayName: 'Follow market',
            example: 'Example: Follow S&P500',
            enable: true,
            testing: true,
          },
          {
            name: 'alerts',
            displayName: 'Market Alert',
            example: 'Example: S&P500 crossed support level.',
            enable: true,
            testing: true,
          },
        ],
      },
      {
        name: 'exchanges',
        displayName: 'Exchange opening/closing',
        enable: true,
        testing: false,
        items: [
          {
            name: 'NYSE',
            displayName: 'New York Stock Exchange (NYSE)',
            enable: true,
            testing: false,
          },
          // {
          //   name: 'NASDAQ',
          //   displayName: 'NASDAQ (NASDAQ)',
          //   enable: true,
          //   testing: false
          // },
          {
            name: 'LSE',
            displayName: 'London Stock Exchange (LSE)',
            enable: true,
            testing: false,
          },
          {
            name: 'JPX',
            displayName: 'Japan Exchange Group (JPX)',
            enable: true,
            testing: false,
          },
          {
            name: 'SSE',
            displayName: 'Shanghai Stock Exchange (SSE)',
            enable: true,
            testing: false,
          },
          {
            name: 'TEST1',
            displayName: 'Test Israel Exchange (TEST1)',
            enable: false,
            testing: true,
          },
          {
            name: 'TEST2',
            displayName: 'Test Copenhagen Exchange (TEST2)',
            enable: false,
            testing: true,
          },
        ],
      },
      {
        name: 'news-crawler',
        displayName: 'News Alerts ',
        enable: true,
        testing: false,
        items: [],
      },
    ];

    // Hide trade ideas
    if (btSettingsService.isLinkDataService()) {
      gSettings.splice(2, 1);
      gExchangesIndex--;
      gNewsCrawlerIndex--;
    }

    // var gCategories = ['text', 'levels', 'twitter', 'calendar', 'ideas', 'movements', 'exchanges'];

    var gVolumeLevelNames = {
      max: 3,
      mid: 2,
      min: 1,
    };

    var gVolumeLevelIds = {
      3: 'max',
      2: 'mid',
      1: 'min',
    };

    var gUserLevelsSettings = null;

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    activate();

    return {
      initialize: initialize,
      enable: enable,
      disable: disable,
      ask: ask,
      selectVoice: selectVoice,

      pronounce: pronounce,
      readMessage: readMessage,
      readTestMessage: readTestMessage,
      // showMessage: showMessage,
      test: test,

      getStatus: getStatus,
      getSpeechSynthesis: getSpeechSynthesis,
      getHistory: getHistory,

      getTweetReadable: getTweetReadable,
      setTweetReadable: setTweetReadable,

      getTweetNotifiable: getTweetNotifiable,
      setTweetNotifiable: setTweetNotifiable,
      getTweetPushNotification: getTweetPushNotification,
      setTweetPushNotification: setTweetPushNotification,

      getNewsReadable: getNewsReadable,
      setNewsReadable: setNewsReadable,

      getNewsNotifiable: getNewsNotifiable,
      setNewsNotifiable: setNewsNotifiable,
      getNewsPushNotification: getNewsPushNotification,
      setNewsPushNotification: setNewsPushNotification,

      pushNewsNotifications: onNewsCrawlerHandler,

      getUserSettings: getUserSettings,
      saveUserSettings: saveUserSettings,
      getApplicationSettings: getInternalSettings,

      getVolumeLevel: getVolumeLevel,
      setVolumeLevel: setVolumeLevel,
      volumeNameToId: volumeNameToIndex,
      volumeIdToName: volumeIndexToName,

      updateSettings: updateSettings,
      changeConfiguration: changeConfiguration,
      findSettings: findSettings,
    };

    /**
     * This function waits for service activation
     *
     * @alias ecapp.btVoiceAssistantHelperService#initialize
     * @return {angular.IPromise<*>}
     */
    function initialize() {
      var deferred = $q.defer();
      isActivated();

      /**
       *
       */
      function isActivated() {
        if (gIsActivated) deferred.resolve();
        else setTimeout(isActivated, 100);
      }

      return deferred.promise;
    }

    /**
     * This function updates internal settings using user settings as a source
     *
     * @alias ecapp.btVoiceAssistantHelperService#updateSettings
     * @param {btVoiceInternalSettings} settings - new settings
     */
    function updateSettings(settings) {
      var userSettings = getUserSettings();
      settings.forEach(function (setting) {
        if (gDebug) console.log(gPrefix, setting.name);
        var userSetting = userSettings[setting.name];
        if (userSetting) {
          setting.enable = userSetting.enable;

          if (setting.items) {
            setting.items.forEach(function (item) {
              if (gDebug) console.log(gPrefix, item, userSetting.items.indexOf(item.name));
              item.enable = userSetting.items && userSetting.items.indexOf(item.name) !== -1;
            });
          }
        }
      });
    }

    /**
     * This function changes internal settings.
     *
     * It returns true if configuration was changed.
     *
     * @alias ecapp.btVoiceAssistantHelperService#changeConfiguration
     * @param {String} name - property name
     * @param {Boolean} position - toggle position
     * @return {angular.IPromise<boolean>}
     */
    function changeConfiguration(name, position) {
      var result = findSettings(name);
      if (result) {
        result.enable = position;
        var userSettings = getUserSettings();
        updateUserSettings(userSettings, filterTestSettings(gSettings));
        if (gDebug) console.log(gPrefix, userSettings);
        return saveUserSettings(userSettings).then(function () {
          return true;
        });
      }
      return $q.reject(new Error('Settings "' + name + '" not found!'));
    }

    /**
     * This function filter test settings in regular mode (they can be seen in dev mode).
     *
     * @param {btVoiceInternalSettings} settings - internal settings of Voice Assistant
     * @return {btVoiceInternalSettings}
     */
    function filterTestSettings(settings) {
      settings.forEach(function (element) {
        element.items.forEach(function (item) {
          item.testing = $rootScope.isDevMode ? false : item.testing;
        });

        element.testing = $rootScope.isDevMode ? false : element.testing;
      });

      return settings;
    }

    /**
     * This function finds property of internal settings by full name (for example: calendar.release)
     *
     * @alias ecapp.btVoiceAssistantHelperService#findSettings
     * @param {String} name - full name of property
     * @return {?btVoiceInternalProperty}
     */
    function findSettings(name) {
      var elements = name.split('.');
      var items = filterTestSettings(gSettings);
      var result = null;

      elements.forEach(function (element) {
        var results = items.filter(function (item) {
          return item.name === element;
        });

        if (results.length === 1) {
          result = results[0];
          items = result.items;
        } else {
          result = null;
          items = [];
        }
      });

      return result;
    }

    /**
     * This function updates user settings using internal settings as a source
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoiceInternalSettings} settings - internal settings
     */
    function updateUserSettings(userSettings, settings) {
      if (gDebug) console.log(gPrefix, 'updateUserSettings', userSettings, settings);
      settings.forEach(function (setting) {
        if (userSettings[setting.name] === undefined) {
          userSettings[setting.name] = {};
        }

        var userSetting = userSettings[setting.name];

        userSetting.enable = setting.enable;

        if (setting.items) {
          userSetting.items = [];
          setting.items.forEach(function (item) {
            if (item.enable) {
              userSetting.items.push(item.name);
            }
          });
        }
      });
    }

    /**
     * This function gets current volume level.
     * @see {@link ecapp.btVoiceAssistantService~gVolumeLevels}
     * @alias ecapp.btVoiceAssistantHelperService#getVolumeLevel
     * @return {String} - current volume level
     */
    function getVolumeLevel() {
      return btVoiceAssistantService.getVolumeLevel();
    }

    /**
     * This function sets volume level.
     * @see {@link ecapp.btVoiceAssistantService~gVolumeLevels}
     * @alias ecapp.btVoiceAssistantHelperService#setVolumeLevel
     * @param {String} volume - new volume level
     */
    function setVolumeLevel(volume) {
      btVoiceAssistantService.setVolumeLevel(volume);
    }

    /**
     * This function converts volume level name to id
     *
     * @alias ecapp.btVoiceAssistantHelperService#volumeNameToId
     * @param {String} text - name of volume level
     * @return {Number} - index of volume level
     */
    function volumeNameToIndex(text) {
      return gVolumeLevelNames[text] || 3;
    }

    /**
     * This function converts index of volume level to name.
     *
     * @alias ecapp.btVoiceAssistantHelperService#volumeIdToName
     * @param {Number} number - volume level id
     * @return {String} - volume level name
     */
    function volumeIndexToName(number) {
      return gVolumeLevelIds[number] || 'max';
    }

    /**
     * This function activates Voice Assistant.
     * Due to Voice Assistant works in background it should be activated on early stage.
     * @private
     */
    function activate() {
      gIsTweetReadable = (localStorage.getItem('btVoiceAssistantTweetReadable') || 'false') === 'true';

      gIsNewsReadable = (localStorage.getItem('btVoiceAssistantNewsReadable') || 'false') === 'true';
      if (!btRestrictionService.hasFeature('news-reading')) gIsTweetReadable = false;

      gIsTweetNotifiable = (localStorage.getItem('btVoiceAssistantTweetNotifiable') || 'false') === 'true';

      if (!btRestrictionService.hasFeature('voice-assistant')) {
        btVoiceAssistantService.disable();
      }

      bindPusher();

      btShareScopeService.wait().then(function () {
        gUserLevelsSettings = btShareScopeService.getUserSettings('levels', {});
        gHasNewTweetPushNotification = btShareScopeService.hasNotification(
          btCodes.Notification.TwitterScanner.NewTweet
        );
        gHasNewsPushNotification = btShareScopeService.hasNotification(btCodes.Notification.NewsAlerts.NewArticle);
        setInterval(function () {
          // !!! > It should be rewritten
          gUserLevelsSettings = btShareScopeService.getUserSettings('levels', {});
        }, 2000);
      });

      getInternalSettings().then(function () {
        updateSettings(filterTestSettings(gSettings));
        gIsActivated = true;
      });
    }

    /**
     * This function binds Voice Assistant to pusher channels
     * @private
     */
    function bindPusher() {
      if (gPusherBind) return;

      if (!btPusherService.isConnected) return;

      btPusherService.addEventHandler('personal', 'market-wakeup', onMarketWakeup);
      btPusherService.addEventHandler('personal', 'market-sense', onMarketSense);
      btPusherService.addEventHandler('personal', 'new-session', onNewSession);
      btPusherService.addEventHandler('personal', 'session-termination', onSessionTermination);
      btPusherService.addEventHandler('voiceAssistant', 'new-message', handlePusher);
      btPusherService.addEventHandler('newsCrawler', 'new-articles', onNewsCrawlerHandler);
      // btPusherService.addEventHandler('marketAlerts', 'new-alert', onNewMarketAlert);
      btPusherService.addEventHandler('marketAlerts', 'levels-updated', onLevelsUpdated);

      gPusherBind = true;

      btSocketService
        .connect('/market-alerts')
        .then(function (socket) {
          socket.on('new-alert', onNewMarketAlert);
        })
        .catch(console.error);
    }

    /**
     * This function unbinds Voice Assistant from pusher channels.
     * It should be run during logout to prevent toastr notifications after logout.
     * @private
     */
    function unbindPusher() {
      if (gPusherBind) {
        // Pusher unbind all handlers of personal channel during logout

        btPusherService.removeEventHandler('voiceAssistant', 'new-message', handlePusher);
        // btPusherService.removeEventHandler('marketAlerts', 'new-alert', onNewMarketAlert);
        btPusherService.removeEventHandler('marketAlerts', 'levels-updated', onLevelsUpdated);
        btPusherService.removeEventHandler('newsCrawler', 'new-articles', onNewsCrawlerHandler);

        gPusherBind = false;
      }
    }

    /**
     * This function will be called on success login.
     * @private
     */
    function onLoginSuccess() {
      try {
        if (!btRestrictionService.hasFeature('voice-assistant')) {
          btVoiceAssistantService.disable();
        }

        btShareScopeService.wait().then(function () {
          gUserLevelsSettings = btShareScopeService.getUserSettings('levels', {});
        });

        bindPusher();
        getInternalSettings();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function will be called on logout.
     * @private
     */
    function onLogoutSuccess() {
      try {
        unbindPusher();
        gUserLevelsSettings = null;
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * @typedef {object} btPersonalNewSession
     * @property {string} message - ?
     */

    /**
     * @typedef {object} btPersonalSessionTermination
     * @property {string} secret - ?
     */

    /**
     * @typedef {object} btWakeupMessage
     * @property {string} broker - ?
     * @property {string} symbol - ?
     * @property {string} displayName - ?
     * @property {string} emotion - volatility, highest, lowest
     * @property {number} price - ?
     */

    /**
     * This function will be called on market wakeup event.
     * @param {btWakeupMessage} data
     *
     * {
     *      "symbol": notification.instrument,
     *      "broker": notification.broker,
     *      "displayName": notification.display_name,
     *      "price": notification.last_price
     *  }
     */
    function onMarketWakeup(data) {
      var link = {
        state: 'ecapp.app.main.instrument-page',
        params: {
          broker: data.broker,
          symbol: data.symbol,
        },
      };

      speechMessage(
        getMarketWakeupMessage(data),
        'MarketWakeup',
        getMarketWakeupSpeech(data),
        'text',
        getMarketWakeupType(data),
        link
      );
    }

    /**
     * This function handles 'new-session' message.
     * @param {btPersonalNewSession} data - message data
     */
    function onNewSession(data) {
      var text = data.message || 'You are signed in on a new device.';
      btToastrService.info(text, 'Authorization', getToastrParams(null, 'system'));
    }

    /**
     * This function handles 'session-termination' message.
     * @param {btPersonalSessionTermination} data - message data
     */
    function onSessionTermination(data) {
      if (data.secret === '352db952-6a7a-4d90-bc72-3e383a3e54fc') {
        $state.go('ecapp.user.logout');
      }
    }

    /**
     *
     * @param {btWakeupMessage} data
     * @return {string}
     */
    function getMarketWakeupType(data) {
      if (data.emotion === 'volatility') return 'market-wakeup-volatility';
      if (data.emotion === 'highest') return 'market-wakeup-high';
      if (data.emotion === 'lowest') return 'market-wakeup-low';
      return 'market-wakeup';
    }

    /**
     *
     * @param {btWakeupMessage} data
     * @return {string}
     */
    function getMarketWakeupMessage(data) {
      return data.displayName + ' ' + getMarketWakeupReason(data) + '.';
    }

    /**
     *
     * @param {btWakeupMessage} data
     * @return {string}
     */
    function getMarketWakeupSpeech(data) {
      return data.displayName + ' ' + getMarketWakeupReason(data) + '.';
    }

    /**
     *
     * @param {btWakeupMessage} data
     * @return {string}
     */
    function getMarketWakeupReason(data) {
      if (data.emotion === 'volatility') return 'volatility increased recently';
      if (data.emotion === 'highest') return 'reached new 6-hours high';
      if (data.emotion === 'lowest') return 'reached new 6-hours low';
      return 'has something strange';
    }

    /**
     * This function will be called on market sense event.
     * @param {*} data
     */
    function onMarketSense(data) {
      var link = {
        state: 'ecapp.app.main.instrument-page',
        params: {
          broker: data.broker,
          symbol: data.symbol,
        },
      };

      speechMessage(
        getMarketSenseMessage(data),
        'MarketSense',
        getMarketSenseSpeech(data),
        'text',
        'market-sense',
        link
      );
    }

    /**
     *
     * @param {*} data
     * @return {any}
     */
    function getMarketSenseMessage(data) {
      return (
        data.displayName +
        ' crosses ' +
        getMovement(data) +
        ' - ' +
        getLevels(data) +
        ' ' +
        getEmotion(data) +
        ' your favor direction. ' +
        'Last price: ' +
        data.price +
        ' (change ' +
        data.change +
        ')'
      );
    }

    /**
     *
     * @param {*} data
     * @return {any}
     */
    function getMarketSenseSpeech(data) {
      return (
        data.displayName +
        ' crosses ' +
        getMovement(data) +
        ' - ' +
        getLevels(data) +
        ' ' +
        getEmotion(data) +
        ' your favor direction.'
      );
    }

    /**
     *
     * @param {*} data
     * @return {string}
     */
    function getLevels(data) {
      return data.levels === 1 ? '1 level' : data.levels + ' levels';
    }

    /**
     *
     * @param {*} data
     * @return {string}
     */
    function getMovement(data) {
      if (data.movement === 'UP') return 'upwards';
      if (data.movement === 'DOWN') return 'downwards';
      return '-';
    }

    /**
     *
     * @param {*} data
     * @return {string}
     */
    function getEmotion(data) {
      if (data.emotion === 'happy') return 'in';
      if (data.emotion === 'sad') return 'out';
      return '-';
    }

    /**
     * This function handles incoming pusher message.
     *
     * @param {{message: btVoicePusherMessage}} data - pusher object
     * @private
     */
    function handlePusher(data) {
      var settings = getUserSettings();
      if (isValidMessage(data.message) && isUserSubscribedToCategory(settings, data.message)) {
        getHandler(data.message)(settings, data.message);
      }
    }

    /**
     * This function validates pusher message.
     *
     * @param {btVoicePusherMessage} message - pusher message
     * @return {Boolean}
     * @private
     */
    function isValidMessage(message) {
      return typeof message.text === 'string' && typeof message.category === 'string';
    }

    /**
     * This function check whether user subscribed to message like this.
     *
     * @param {Object} settings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @return {Boolean}
     * @private
     */
    function isUserSubscribedToCategory(settings, message) {
      var category = getCategory(message);
      return category === 'text' || (settings[category] && settings[category].enable);
    }

    /**
     * This function selects message handler.
     *
     * @param {btVoicePusherMessage} message - pusher message
     * @return {Function} - handler
     * @private
     */
    function getHandler(message) {
      var category = getCategory(message);

      if (category === 'twitter' && !btSettingsService.isLinkDataService()) {
        return twitterHandler;
      }

      if (category === 'text') {
        return textHandler;
      }

      if (category === 'exchanges') {
        return exchangeHandler;
      }

      return emptyHandler;
    }

    /**
     * This function parses category from pusher message.
     *
     * @param {btVoicePusherMessage} message - pusher message
     * @return {String} - return category in lower case
     * @private
     */
    function getCategory(message) {
      return message.category.toLowerCase();
    }

    /**
     * This function is a default message handler
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @private
     */
    function emptyHandler(userSettings, message) {
      void userSettings;
      void message;
      console.warn(gPrefix, 'unsupported category', message);
    }

    /**
     * THis function handles text messages.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @private
     */
    function textHandler(userSettings, message) {
      void userSettings;
      var title;

      // Show message just for correct domain
      if (!message.domain || message.domain === btSettingsService.getDomain()) {
        // Customize title
        if (btSettingsService.getDomain() === 'bigbrainbank') {
          title = 'TheBrain Operator';
        } else {
          title = 'BetterTrader Operator';
        }

        btHistoryService.add('bt-voice-messages', {
          html: '<span class="bt-author">' + title + ':</span> ' + message.text,
          link: getLink(message),
        });

        btToastrService.info(message.text, title, getToastrParams(message, 'operator'));
        btVoiceAssistantService.read(message.text);
      }
    }

    /**
     * This function handles exchange open&close messages.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @private
     */
    function exchangeHandler(userSettings, message) {
      if (isUserFollowExchange(userSettings, message)) {
        // get exchanges settings
        var exchangesSettings = gSettings[gExchangesIndex].items.filter(function (item) {
          return 'exchange' in message.params && item.name === message.params.exchange.id;
        });

        if (exchangesSettings && exchangesSettings.length === 1) {
          var exchangeParameters = exchangesSettings[0];

          if (btRestrictionService.hasFeature('voice-assistant')) {
            btHistoryService.add('bt-voice-messages', {
              html: '<span class="bt-author">' + exchangeParameters.displayName + ':</span> ' + message.text,
              link: getLink(message),
            });

            btToastrService.info(message.text, exchangeParameters.displayName, getToastrParams(message, 'exchange'));
            btVoiceAssistantService.read(message.text);
          } else {
            simulateVoiceAssistant();
          }
        }
      }
    }

    /**
     * This function checks whether user follows exchange specified in pusher message.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @return {Boolean}
     * @private
     */
    function isUserFollowExchange(userSettings, message) {
      return (
        userSettings.exchanges &&
        userSettings.exchanges.items &&
        message.params &&
        'exchange' in message.params &&
        message.params.exchange.id &&
        userSettings.exchanges.items.indexOf(message.params.exchange.id) !== -1
      );
    }

    /**
     * This function checks whether user follows exchange specified in pusher message.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btNewCrawlerPusherMessage} crawler - source crawler
     * @return {Boolean}
     * @private
     */
    function isUserFollowAlerts(userSettings, crawler) {
      return (
        userSettings['news-crawler'] &&
        userSettings['news-crawler'].items &&
        crawler &&
        crawler.n &&
        crawler.t &&
        userSettings['news-crawler'].items.indexOf(crawler.n) !== -1
      );
    }

    /**
     * This function handles twitter messages.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @private
     */
    function twitterHandler(userSettings, message) {
      if (isUserFollowTwitterAccount(userSettings, message)) {
        // get twitter settings
        var settings = gSettings[gTwitterIndex].items.concat(gSettings[gCustomIndex].items);

        if (!('account' in message.params)) return;

        var twitterAccount =
          btTwitterScannerService.findById(message.params.account.id.toString()) ||
          btTwitterScannerService.findByName(message.params.account.name);

        if (!twitterAccount) {
          var twitterSettings = settings.filter(function (item) {
            return 'account' in message.params && item.name === message.params.account.name;
          });

          if (twitterSettings && twitterSettings.length === 1) {
            twitterAccount = twitterSettings[0];
          }
        }

        if (twitterAccount) {
          if (btRestrictionService.hasFeature('voice-assistant')) {
            if (twitterAccount.name === 'QuakesToday' && !$rootScope.isDevMode && getMagnitude(message.text) < 5) {
              return;
            }

            btHistoryService.add('bt-voice-messages', {
              html: '<span class="bt-author">New tweet of ' + twitterAccount.displayName + ':</span> ' + message.text,
              link: getLink(message),
            });

            btToastrService.info(
              message.text,
              'New tweet of ' + twitterAccount.displayName,
              getToastrParams(message, 'tweet', 'toast-twitter-icon')
            );

            if (gIsTweetReadable) {
              btVoiceAssistantService.read(
                'New tweet of ' + twitterAccount.pronunciation + ': ' + getTweetSpeech(message)
              );
            } else {
              btVoiceAssistantService.read('New tweet of ' + twitterAccount.pronunciation);
            }
          } else {
            simulateVoiceAssistant();
          }
        }
      }
    }

    /**
     * This function prepares the text for a speech from tweet.
     *
     * @param {{text: string}} message - pusher message
     * @return {String}
     */
    function getTweetSpeech(message) {
      var speech = $('<textarea/>').html(message.text).text();
      speech = removeUrl(speech);
      speech = removeMultipleHashes(speech);
      speech = removeHashes(speech);

      return speech;
    }

    /**
     * This function checks whether user follows twitter account specified in pusher message.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btVoicePusherMessage} message - pusher message
     * @return {Boolean}
     * @private
     */
    function isUserFollowTwitterAccount(userSettings, message) {
      if (message.params && 'account' in message.params && message.params.account.name) {
        var names = [];
        if (userSettings.twitter && userSettings.twitter.items) names = names.concat(userSettings.twitter.items);
        if (userSettings.custom && userSettings.custom.items) names = names.concat(userSettings.custom.items);

        return names.indexOf(message.params.account.name) !== -1;
      } else {
        return false;
      }
    }

    /**
     * This function handles crawler message.
     *
     * @param {btVoiceUserSettings} userSettings - user settings
     * @param {btNewCrawlerPusherMessage} crawler - crawler message
     * @private
     */
    function newsAlertHandler(userSettings, crawler) {
      if (isUserFollowAlerts(userSettings, crawler)) {
        const { n: sourceName, t: articleTitle, l: articleLink, d: articleDescription, silently, created } = crawler;
        // get news settings
        var settings = gSettings[gNewsCrawlerIndex].items.concat(gSettings[gCustomIndex].items);

        var newsCrawler = btNewsAlertsService.findByName(sourceName);

        if (!newsCrawler) {
          var newsSettings = settings.filter(function (item) {
            return item.name === sourceName;
          });

          if (newsSettings && newsSettings.length === 1) {
            newsCrawler = newsSettings[0];
          }
        }

        if (newsCrawler) {
          if (btRestrictionService.hasFeature('voice-assistant')) {
            const text = articleTitle + ' ' + articleDescription;
            btHistoryService.add('bt-voice-messages', {
              html: '<span class="bt-author">' + sourceName + ' news:</span> ' + text,
              link: articleLink,
            });
            if (!silently) {
              btToastrService.info(
                text,
                `News alerts - ${sourceName} news`,
                getToastrParams(null, 'news-crawler', '', articleLink)
              );

              if (gIsNewsReadable) {
                btVoiceAssistantService.read(sourceName + ' news: ' + getTweetSpeech({ text }));
              } else {
                btVoiceAssistantService.read(sourceName + ' news');
              }
            } else {
              let params = getToastrParams(null, 'news-crawler', '', articleLink);
              params['date'] = created ? created : new Date();
              btToastrService.insert('ion-information-circled', `News alerts - ${sourceName} news`, text, params);
            }
          } else {
            simulateVoiceAssistant();
          }
        }
      }
    }

    /**
     *
     * @param {btNewCrawlerPusherMessage[]} messages - messages
     */
    function onNewsCrawlerHandler(messages) {
      var settings = getUserSettings();
      messages.forEach((message) => newsAlertHandler(settings, message));
    }

    /**
     * This function removes link from text.
     *
     * @param {String} text - some text
     * @return {String} - return text w/o links
     * @private
     */
    function removeUrl(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, '');
    }

    /**
     * This function removes multiple hashes from text.
     *
     * @param {String} text - some text
     * @return {String} - return text w/o multiple hashes
     * @private
     */
    function removeMultipleHashes(text) {
      var urlRegex = /(#+)/g;
      return text.replace(urlRegex, '#');
    }

    // /**
    //  * Remove multiple hashes from text
    //  * @param {String} text - some text
    //  * @return {String} - return text w/o ampersand
    //  * @private
    //  */
    // function removeAmpersands(text) {
    //   var urlRegex = /(&+)/g;
    //   return text.replace(urlRegex, ' and ');
    // }

    /**
     * Remove multiple hashes from text
     * @param {String} text - some text
     * @return {String} - return text w/o hashes
     * @private
     */
    function removeHashes(text) {
      var urlRegex = /(#+)/g;
      return text.replace(urlRegex, '');
    }

    /**
     * Get magnitude of earthquake
     * @param {String} text - some text
     * @return {Number} - return earthquake magnitude
     * @private
     */
    function getMagnitude(text) {
      var urlRegex = /(.*) magnitude .*/;
      var match = text.match(urlRegex);
      if (match) {
        return parseFloat(match[1]);
      } else {
        return 0;
      }
    }

    /**
     * Get parameters of btToastrService notification
     * @param {btVoicePusherMessage|null} message - pusher message
     * @param {*} type - ?
     * @param {String} [cssClass] - custom css class
     * @param {string} [link] - custom link
     * @return {Object} - btToastrService parameters
     * @private
     */
    function getToastrParams(message, type, cssClass, link) {
      link = link ? link : message ? getLink(message) : '';
      var params = {
        type: type,
        timeOut: 6000,
        extendedTimeOut: 1000,
        closeButton: true,
        onTap: link ? btLinkService.openSmart.bind(null, link) : null,
      };

      if (cssClass) {
        params.iconClass = cssClass;
      }

      if (type === 'tweet') {
        params.timeOut = 10000;
        params.allowHtml = true;
      }

      return params;
    }

    /**
     * Get link from pusher message
     * @param {btVoicePusherMessage} message - pusher message
     * @return {String|null} - return link or null
     * @private
     */
    function getLink(message) {
      if (message.params && 'link' in message.params) {
        return message.params.link;
      } else {
        return null;
      }
    }

    /**
     * Ask user about voice assistant
     * @alias ecapp.btVoiceAssistantHelperService#ask
     */
    function ask() {
      if (btRestrictionService.hasFeature('voice-assistant')) {
        btVoiceAssistantService.ask();
      }
    }

    /**
     * Select voice
     *
     * @alias ecapp.btVoiceAssistantHelperService#selectVoice
     */
    function selectVoice() {
      if (btRestrictionService.hasFeature('voice-assistant')) {
        btVoiceAssistantService.selectVoice();
      } else {
        btRestrictionService.showUpgradePopup('voice-assistant');
      }
    }

    /**
     * This function just pronounce some text without side effect.
     * It can be used for testing of some pronunciation.
     *
     * @alias ecapp.btVoiceAssistantHelperService#pronounce
     * @param {String} text - text to pronounce
     */
    function pronounce(text) {
      btVoiceAssistantService.pronounce(text);
    }

    /**
     * This function reads text.
     * User should be subscribed to category and subcategory. Voice Assistant should be available.
     *
     * @alias ecapp.btVoiceAssistantHelperService#readMessage
     * @param {string} text - text to read
     * @param {string} category - message category
     * @param {string} subcategory - message subcategory
     */
    function readMessage(text, category, subcategory) {
      if (isSubscribed(category, subcategory)) {
        if (btRestrictionService.hasFeature('voice-assistant')) {
          btHistoryService.add('bt-voice-messages', { html: text });
          btVoiceAssistantService.read(text);
        } else {
          simulateVoiceAssistant();
        }
      }
    }

    /**
     * This function shows toastr message and reads it if it is possible.
     *
     * @param {String} msg - toastr text
     * @param {String} title - toastr title
     * @param {String} text - text to read
     * @param {String} category - message category
     * @param {String} subcategory - message subcategory
     * @param {String|{state:String,params:Object}} [link] - link
     */
    function showMessage(msg, title, text, category, subcategory, link) {
      processMessage(false, msg, title, text, category, subcategory, link);
    }

    /**
     * This function reads it if it is possible and duplicates it as a toastr message.
     *
     * @param {String} msg - toastr text
     * @param {String} title - toastr title
     * @param {String} text - text to read
     * @param {String} category - message category
     * @param {String} subcategory - message subcategory
     * @param {String|{state:String,params:Object}} [link] - link
     */
    function speechMessage(msg, title, text, category, subcategory, link) {
      processMessage(true, msg, title, text, category, subcategory, link);
    }

    /**
     * This function reads text and shows toastr.
     *
     * @param {*} visible - ?
     * @param {String} msg - toastr text
     * @param {String} title - toastr title
     * @param {String} text - text to read
     * @param {String} category - message category
     * @param {String} subcategory - message subcategory
     * @param {String|{state:String,params:Object}} [link] - link
     */
    function processMessage(visible, msg, title, text, category, subcategory, link) {
      if (isSubscribed(category, subcategory)) {
        if (btRestrictionService.hasFeature('voice-assistant') || visible) {
          var params = {
            timeOut: 6000,
            closeButton: true,
            type: getNotificationType(category, subcategory),
            onTap: link ? btLinkService.openSmart.bind(null, link) : null,
          };

          btHistoryService.add('bt-voice-messages', { html: text, link: link });

          btToastrService.info(msg, title, params);
          btVoiceAssistantService.read(text);
        } else {
          simulateVoiceAssistant();
        }
      }
    }

    /**
     * This function gets notification type. It is used to set correct icon.
     *
     * @param {String} category - notification category
     * @param {String} subcategory - notification subcategory
     * @return {String}
     */
    function getNotificationType(category, subcategory) {
      var categories = ['levels'];
      var subcategories = [
        'market-sense',
        'market-wakeup',
        'market-wakeup-volatility',
        'market-wakeup-high',
        'market-wakeup-low',
      ];

      if (categories.indexOf(category) !== -1) return category;
      if (subcategories.indexOf(subcategory) !== -1) return subcategory;

      if (category === 'movements' && subcategory === 'alerts') return 'market-alerts';

      return 'voice-assistant';
    }

    /**
     * This function simulates voice assistant working.
     */
    function simulateVoiceAssistant() {
      if (!gFakeSpeechSynthesis.speaking) {
        $timeout(function () {
          gFakeSpeechSynthesis.speaking = true;
          $timeout(function () {
            gFakeSpeechSynthesis.speaking = false;
          }, 3000);
        });
      }
    }

    /**
     * This functions test voice assistant.
     *
     * @alias ecapp.btVoiceAssistantHelperService#test
     */
    function test() {
      btVoiceAssistantService.test();
    }

    /**
     * This function reads test message.
     *
     * @alias ecapp.btVoiceAssistantHelperService#readTestMessage
     */
    function readTestMessage() {
      if (btRestrictionService.hasFeature('voice-assistant')) {
        var status = getStatus();
        var text;

        if (status.isEnable) {
          text = "It looks like we're all set.";
        } else {
          text = "It looks like we're all set. Voice is  mute.";
        }

        speechMessage(text, 'Voice Assistant', text, 'text', 'testing');
      } else {
        btVoiceAssistantService.disable();
        btRestrictionService.showUpgradePopup('voice-assistant');
      }
    }

    /**
     * This function enables voice assistant.
     *
     * It returns promise due to it first first time user will need to customize voice assistant.
     * Promise return boolean value: true - VA was enabled, false - VA wasn't enabled
     *
     * @alias ecapp.btVoiceAssistantHelperService#enable
     * @return {angular.IPromise<Boolean>}
     */
    function enable() {
      if (btRestrictionService.hasFeature('voice-assistant')) {
        return btVoiceAssistantService.enable().then(function (enabled) {
          if (enabled) {
            if (window.isDesktop) {
              // Desktop version
              btToastrService.info('Turned on.', 'Voice Assistant', { type: 'voice-assistant' });
              btHistoryService.add('bt-voice-messages', { html: 'Turned on.' });
            } else {
              // Mobile version
              if (window.isIOS) {
                btToastrService.warning('Turned on', 'Voice Assistant', { type: 'voice-assistant' });
              } else {
                var msg =
                  'Turned on. Open ' +
                  btSettingsService.getHostName() +
                  ' in your desktop or laptop browser to take all benefits of Voice Assistant.';
                btToastrService.warning(msg, 'Voice Assistant', { type: 'voice-assistant' });
              }
              btHistoryService.add('bt-voice-messages', { html: 'Turned on.' });
            }
          }
          return enabled;
        });
      } else {
        btVoiceAssistantService.disable();
        return btRestrictionService.showUpgradePopup('voice-assistant').then(function (status) {
          return !!status;
        });
      }
    }

    /**
     * This function disables voice assistant
     *
     * @alias ecapp.btVoiceAssistantHelperService#disable
     */
    function disable() {
      btVoiceAssistantService.disable();
      btToastrService.info('Turned off.', 'Voice Assistant', { type: 'voice-assistant' });
      btHistoryService.add('bt-voice-messages', { html: 'Turned off.' });
    }

    /**
     * This function returns voice assistant status object
     *
     * @alias ecapp.btVoiceAssistantHelperService#getStatus
     * @return {btVoiceAssistantStatus}
     */
    function getStatus() {
      return btVoiceAssistantService.getStatus();
    }

    /**
     * This function gets speech synthesis object (real or faked)
     *
     * @alias ecapp.btVoiceAssistantHelperService#getSpeechSynthesis
     * @return {{paused:Boolean, pending:Boolean, speaking:Boolean}}
     */
    function getSpeechSynthesis() {
      if (btRestrictionService.hasFeature('voice-assistant')) {
        return btVoiceAssistantService.getSpeechSynthesis();
      } else {
        return gFakeSpeechSynthesis;
      }
    }

    /**
     * This function sets tweet readable
     *
     * @alias ecapp.btVoiceAssistantHelperService#setTweetReadable
     * @param {Boolean} value - new value
     * @return {boolean}
     */
    function setTweetReadable(value) {
      if (btRestrictionService.hasFeature('tweet-reading')) {
        gIsTweetReadable = value;
        localStorage.setItem('btVoiceAssistantTweetReadable', gIsTweetReadable.toString());
        $rootScope.$broadcast('tweetReadableChanged', { position: value });
        return true;
      } else {
        btRestrictionService.showUpgradePopup('tweet-reading-enable');
        return false;
      }
    }

    /**
     * This function returns tweet readable
     *
     * @alias ecapp.btVoiceAssistantHelperService#getTweetReadable
     * @return {Boolean}
     */
    function getTweetReadable() {
      return gIsTweetReadable;
    }

    /**
     * This function sets tweet notifiable
     *
     * @alias ecapp.btVoiceAssistantHelperService#setTweetNotifiable
     * @param {Boolean} value - new value
     */
    function setTweetNotifiable(value) {
      gIsTweetNotifiable = value;
      localStorage.setItem('btVoiceAssistantTweetNotifiable', gIsTweetNotifiable.toString());
      $rootScope.$broadcast('tweetReadableChanged', { position: value });
    }

    /**
     *
     * @alias ecapp.btVoiceAssistantHelperService#getTweetPushNotification
     * @return {boolean}
     */
    function getTweetPushNotification() {
      return gHasNewTweetPushNotification;
    }

    /**
     *
     * @alias ecapp.btVoiceAssistantHelperService#setTweetPushNotification
     * @param {boolean} value - current value
     * @return {angular.IPromise<*>}
     */
    function setTweetPushNotification(value) {
      return btShareScopeService.updateNotificationSettings(btCodes.Notification.TwitterScanner.NewTweet, !!value);
    }

    /**
     * This function returns tweet notifiable.
     *
     * @alias ecapp.btVoiceAssistantHelperService#getTweetNotifiable
     * @return {Boolean}
     */
    function getTweetNotifiable() {
      return gIsTweetNotifiable;
    }

    /**
     * This function sets news readable
     *
     * @alias ecapp.btVoiceAssistantHelperService#setNewsReadable
     * @param {Boolean} value - new value
     */
    function setNewsReadable(value) {
      gIsNewsReadable = value;
      localStorage.setItem('btVoiceAssistantNewsReadable', gIsNewsReadable.toString());
      $rootScope.$broadcast('newsReadableChanged', { position: value });
    }

    /**
     * This function returns news readable
     *
     * @alias ecapp.btVoiceAssistantHelperService#getNewsReadable
     * @return {Boolean}
     */
    function getNewsReadable() {
      return gIsNewsReadable;
    }

    /**
     * This function sets news notifiable
     *
     * @alias ecapp.btVoiceAssistantHelperService#setNewsNotifiable
     * @param {Boolean} value - new value
     */
    function setNewsNotifiable(value) {
      gIsNewsNotifiable = value;
      localStorage.setItem('btVoiceAssistantNewsNotifiable', gIsNewsNotifiable.toString());
      $rootScope.$broadcast('newsNotifiableChanged', { position: value });
    }

    /**
     *
     * @alias ecapp.btVoiceAssistantHelperService#getNewsPushNotification
     * @return {boolean}
     */
    function getNewsPushNotification() {
      return gHasNewsPushNotification;
    }

    /**
     *
     * @alias ecapp.btVoiceAssistantHelperService#setNewsPushNotification
     * @param {boolean} value - current value
     * @return {angular.IPromise<*>}
     */
    function setNewsPushNotification(value) {
      return btShareScopeService.updateNotificationSettings(btCodes.Notification.NewsAlerts.NewArticle, !!value);
    }

    /**
     * This function returns news notifiable.
     *
     * @alias ecapp.btVoiceAssistantHelperService#getNewsNotifiable
     * @return {Boolean}
     */
    function getNewsNotifiable() {
      return gIsNewsNotifiable;
    }

    /**
     * This function gets user settings.
     *
     * @alias ecapp.btVoiceAssistantHelperService#getUserSettings
     * @return {btVoiceUserSettings|{}}
     */
    function getUserSettings() {
      return btShareScopeService.getUserSettings('voiceAssistant', {});
    }

    /**
     * This function checks whether user is subscribed from the category and subcategory.
     *
     * @param {String} category - message category
     * @param {String} subcategory - message subcategory
     * @return {Boolean}
     * @private
     */
    function isSubscribed(category, subcategory) {
      var userSettings = getUserSettings();
      return (
        category === 'text' ||
        category === 'levels' ||
        (userSettings[category] &&
          userSettings[category].enable &&
          userSettings[category].items &&
          userSettings[category].items.indexOf(subcategory) !== -1)
      );
    }

    /**
     * This function saves user settings.
     *
     * @alias ecapp.btVoiceAssistantHelperService#saveUserSettings
     * @param {btVoiceUserSettings} data - user settings
     * @return {angular.IPromise<*>}
     */
    function saveUserSettings(data) {
      if (gDebug) console.log(gPrefix, 'saveUserSettings', data);
      return btShareScopeService.saveUserSettings('voiceAssistant', data);
    }

    /**
     * This function gets internal settings.
     * Due to internal settings contain twitter settings it can't be load immediately in some cases.
     *
     * @alias ecapp.btVoiceAssistantHelperService#getApplicationSettings
     * @return {angular.IPromise<btVoiceInternalSettings>}
     */
    function getInternalSettings() {
      return Promise.all([btTwitterScannerService.initialize(), btNewsAlertsService.initialize()]).then(function () {
        gSettings[gTwitterIndex].items = btTwitterScannerService.list();
        gSettings[gNewsCrawlerIndex].items = btNewsAlertsService.list();
        return gSettings;
      });
    }

    // /**
    //  * This function filters testing twitter account.
    //  *
    //  * @param {btSettingsTwitterAccount} account - twitter account record
    //  * @private
    //  */
    // function filterAccounts(account) {
    //   if ($rootScope.isDevMode) {
    //     return true;
    //   } else {
    //     return !account.testing;
    //   }
    // }
    //
    //
    // /**
    //  * This function generates twitter link.
    //  *
    //  * @param {Object} account - twitter account
    //  * @return {Object} - twitter account with link
    //  * @private
    //  */
    // function generateLink(account) {
    //   account.link = account.link || 'https://twitter.com/' + account.name;
    //   return account;
    // }

    /**
     * @typedef {object} btMarketAlertMessage
     * @property {string} symbol - instrument symbol
     * @property {number} time - timestamp in seconds
     * @property {number} price - reference market price
     * @property {string} type - type of alert: "level"
     * @property {btMarketAlertPayload} payload - message payload
     */

    /**
     * @typedef {btMarketAlertLevelPayload} btMarketAlertPayload
     */

    /**
     * @typedef {object} btMarketAlertLevelPayload
     * @property {string} action - action: "close", 'touch", "cross"
     * @property {string} side - movement direction: "up" or "down"
     * @property {string} level - level tags
     * @property {string} data - level reference
     * @property {boolean} repeated - indicates whether level is repeated
     */

    /**
     * This function gets message history.
     *
     * @alias ecapp.btVoiceAssistantHelperService#getHistory
     * @return {Array} - return message history
     */
    function getHistory() {
      return btHistoryService.get('bt-voice-messages');
    }

    /**
     *
     * @param {string} message
     */
    function onLevelsUpdated(message) {
      if (gDebug) console.log(gPrefix, 'Levels were updated,', message);

      var text = 'Support & resistance levels have just been regenerated';

      var link = { state: 'ecapp.app.main.markets', params: {} };

      showMessage(text, 'Dynamic levels', text, 'levels', 'update', link);
    }

    /**
     *
     * @param {btMarketAlertMessage} message
     */
    function onNewMarketAlert(message) {
      if (gDebug) console.log(gPrefix, 'New market alert', message);
      var error = checkIsValidMarketAlert(message);

      if (error) {
        if (gDebug) console.log(gPrefix, 'Invalid format of market alert:', error);
      } else {
        if (message.type !== 'level') {
          if (gDebug) console.log(gPrefix, 'Unsupported type of market alert:', message.type);
          return;
        }

        if (message.payload.action === 'touch') {
          if (gDebug) console.log(gPrefix, 'Skip touch actions');
          return;
        }

        // var instrument = btInstrumentsService.getInstrumentByComplexSymbol(message.symbol + ':OANDA');
        var instrument = btInstrumentsService.getInstrumentSmart(message.symbol);

        if (!isSubscribedToMarketAlert(instrument, message.payload.action)) {
          if (gDebug) console.log('Skip');
          return;
        }

        var alert = generateMarketAlert(message, instrument);
        // var debugInfo = '<br>[Date: ' + (new Date(message.time * 1000)).toISOString() + ', ' +
        //   'Price: ' + message.price + ', Payload: ' + JSON.stringify(message.payload) + ']';
        var debugInfo = '';
        var link = null;
        if (instrument) {
          link = {
            state: 'ecapp.app.main.instrument-page',
            params: { broker: instrument.brokerName, symbol: instrument.brokerSymbol },
          };
        }

        if (message.payload.action === 'new') {
          btTimeSupervisionService(
            function () {
              speechMessage(alert.text + debugInfo, alert.title, alert.speech, 'movements', 'alerts', link);
            },
            null,
            'NEW-' + message.symbol + '-' + message.payload.level,
            15
          );
        } else {
          speechMessage(alert.text + debugInfo, alert.title, alert.speech, 'movements', 'alerts', link);
        }
      }
    }

    /**
     *
     * @param {*} instrument
     * @param {*} action
     * @return {boolean}
     */
    function isSubscribedToMarketAlert(instrument, action) {
      var value = gUserLevelsSettings[instrument.OandaSymbol];
      if (value) {
        if (value === 'all') return true;
        if (value === 'cross') return action === 'cross' || action === 'new';
        if (value === 'close') return action === 'cross' || action === 'new' || action === 'close';
        if (value === 'none') return false;
        return false;
      } else {
        return false;
      }
    }

    /**
     *
     * @param {btMarketAlertMessage} message
     * @return {*}
     */
    function checkIsValidMarketAlert(message) {
      if (typeof message !== 'object') return 'Market alert message should be a object.';
      if (!message.symbol) return 'Market alert message should have a symbol.';
      if (!message.time) return 'Market alert message should have a time.';
      if (!message.price) return 'Market alert message should have a price.';
      if (!message.type) return 'Market alert message should have a type.';
      if (!message.payload) return 'Market alert message should have a payload.';

      if (message.type === 'level') {
        if (!message.payload.action) return 'Market alert message payload should have an action.';
        if (['close', 'touch', 'cross', 'new'].indexOf(message.payload.action) === -1)
          return 'Unknown action in market alert message: ' + message.payload.action + '.';
        if (!message.payload.side) return 'Market alert message should have a side.';
        if (['up', 'down'].indexOf(message.payload.side) === -1)
          return 'Unknown action in market alert message: ' + message.payload.side + '.';
        if (!message.payload.level) return 'Market alert message should have a level.';
        return null;
      }

      return 'Unknown type of market alert message: ' + message.type + '.';
    }

    //  S&P 500 is close to resistance level.
    //  S&P 500 is touching resistance level.
    //  S&P 500 crossed resistance level.

    //  S&P 500 has new daily high.
    //  S&P 500 has new daily low.

    //  S&P 500 crossed yesterday high.
    //  S&P 500 is close to yesterday high.

    /**
     *
     * @param {btMarketAlertMessage} message -
     * @param {ecapp.ITradingInstrument} instrument -
     * @return {{title: string, text: string, speech: string}}
     */
    function generateMarketAlert(message, instrument) {
      var textAction = {
        close: 'Close to ',
        touch: 'Touching ',
        cross: 'Crossed ',
        new: 'New ',
      };

      var speechAction = {
        close: ' is close to ',
        touch: ' is touching ',
        cross: ' crossed ',
        new: ' - new ',
      };

      var symbol = message.symbol;
      if (instrument) symbol = instrument.displayName;

      var price = parseFloat(message.payload.data);

      return {
        title: symbol,
        text:
          textAction[message.payload.action] +
          getLevelName(message.payload.level, message.payload.side) +
          ' [' +
          price +
          ']',
        speech:
          symbol +
          speechAction[message.payload.action] +
          getLevelName(message.payload.level, message.payload.side) +
          '.',
      };
    }

    /**
     *
     * @param {*} tag
     * @param {*} side
     * @return {*}
     */
    function getLevelName(tag, side) {
      var levels = {
        TH: 'daily high',
        YH: 'yesterday high',
        TYH: 'this year high',
        D1H: 'past 24 hours high',
        W1H: 'previous week high',
        W52H: '52 weeks high',
        TL: 'daily low',
        YL: 'yesterday low',
        TYL: 'this year low',
        D1L: 'past 24 hours low',
        W1L: 'previous week low',
        W52L: '52 weeks low',
      };

      var types = {
        up: 'resistance level',
        down: 'support level',
      };

      if (levels[tag]) {
        return levels[tag];
      } else {
        return types[side];
      }
    }
  }
})();
